import { Typography, Grid } from "@mui/material"
import { Helmet } from 'react-helmet-async'

function Page3LowScore({ data, setData }) {

    //setData({ ...data, creditResult: 5 })

    function Result() {
        return (
            <div>
                <Helmet>
                <title>CapTec USA | Conclusion</title>
                <meta name="description" content="We regret to inform you that your credit score does not meet our requirements." />
                <link rel="canonical" href="https://thenewsouthbank.com/concl" />
            </Helmet>
                <br/>
                <br/>
                <Typography variant="h6" align="center">
                    We regret to inform you that your recent credit check did not meet our requirements.
                </Typography>
            </div>
        )
    }

    return (
        <div>
            <br />
            <Grid container
                direction="row"
                justifyContent="center"
            >
                <Grid item xs={10} sm={8} md={6} >
                    <Result />
                </Grid>
            </Grid>
        </div>
    )
}

export default Page3LowScore