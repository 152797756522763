import StepProgress from './StepProgress'
import SubmitReturn from './SubmitReturn'
import { CircularProgress } from "@mui/material";
import { Helmet } from 'react-helmet-async'


function Questionnaire({ data, setData,
    contactInfo, setContactInfo,
    driverslicense, setDriversLicense,
    year1bustax, setYear1BusTax,
    year2bustax, setYear2BusTax,
    personaltax, setPersonalTax,
    steps, setSteps,
    qstepdata, setQStepData,
    qualifyProfit, setQualifyProfit,
    qualifyProfitable, setQualifyProfitable,
    qualifyTaxes, setQualifyTaxes,
    qualifyDelq, setQualifyDelq,
    qualifyDefault, setQualifyDefault,
    qualifyBankrupt, setQualifyBankrupt,
    qualifyIncarc, setQualifyIncarc,
    qualifyCitizen, setQualifyCitizen,
    qualifyIndustry, setQualifyIndustry
}) {


    return (
        <>
            <Helmet>
                <title>CapTec USA |  Prequalification Questionnaire</title>
                <meta name="description" content="Complete this 15-minute questionnaire to finish your SBA prequalification." />
                <link rel="canonical" href="https://thenewsouthbank.com/questionnaire" />
            </Helmet>
            {qstepdata.submitted == 1 ?
                <SubmitReturn data={data} />
                :
                qstepdata.submitted == 0 ?
                    <div>
                        <StepProgress data={data} setData={setData}
                            contactInfo={contactInfo} setContactInfo={setContactInfo}
                            driverslicense={driverslicense} setDriversLicense={setDriversLicense}
                            year1bustax={year1bustax} setYear1BusTax={setYear1BusTax}
                            year2bustax={year2bustax} setYear2BusTax={setYear2BusTax}
                            personaltax={personaltax} setPersonalTax={setPersonalTax}
                            steps={steps} setSteps={setSteps}
                            qstepdata={qstepdata} setQStepData={setQStepData}
                            qualifyProfit={qualifyProfit} setQualifyProfit={setQualifyProfit}
                            qualifyProfitable={qualifyProfitable} setQualifyProfitable={setQualifyProfitable}
                            qualifyTaxes={qualifyTaxes} setQualifyTaxes={setQualifyTaxes}
                            qualifyDelq={qualifyDelq} setQualifyDelq={setQualifyDelq}
                            qualifyDefault={qualifyDefault} setQualifyDefault={setQualifyDefault}
                            qualifyBankrupt={qualifyBankrupt} setQualifyBankrupt={setQualifyBankrupt}
                            qualifyIncarc={qualifyIncarc} setQualifyIncarc={setQualifyIncarc}
                            qualifyCitizen={qualifyCitizen} setQualifyCitizen={setQualifyCitizen}
                            qualifyIndustry={qualifyIndustry} setQualifyIndustry={setQualifyIndustry}
                        />
                    </div >
                    :
                    <div style={{ position: 'absolute', left: '50%', top: '40%' }}>
                        <CircularProgress />
                    </div>
            }
        </>
    )
}

export default Questionnaire