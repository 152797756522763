import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography, Stack, Grid } from '@mui/material';
import SliderSelect from './SliderSelect'
import RealEstate from './RealEstate'
import PaymentSelect from './PaymentSelect'
import { useTheme } from '@mui/material/styles';


function Calculator({ data, setData }) {
    const theme = useTheme();
    
    return (
        <div style={{ marginTop: '40px', marginLeft: '50px' }} className="Referral">
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >
                <Box sx={{
                    width: '70vw',
                    height: '5rem',
                    borderBottom: 1,
                    textAlign: 'left',
                    m: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    marginLeft: '-30rem'
                }}>
                    <Typography fontSize={30} fontWeight={500} color={theme.palette.primary.main}    //blue
                    //style={{ paddingBottom: '1rem' }} {/* Adjust padding to control distance from bottom */}
                    >
                        Loan Calculator
                    </Typography>
                </Box>
                <br />
                <Typography sx={{
                    fontSize: { xs: 18, sm: 18, md: 20, lg: 22 },
                    paddingRight: { xs: '50px', sm: '80px', md: '100px', lg: '300px' }
                }}
                    fontWeight={500}
                    color={'secondary.light'}    //blue

                //style={{ paddingBottom: '1rem' }} {/* Adjust padding to control distance from bottom 
                >
                    Explore your financial options with our intuitive loan calculator, designed to provide quick and accurate estimates tailored to your needs.
                </Typography>
                <br />
                <br />

            </Stack>
            <Grid container
                width={'100vw'}
                marginLeft='-50px'
                direction="row"
                sx={{ backgroundColor: 'secondary.contrastText' }}
                justifyContent="center"
            >
                <Grid container
                    width={'80vw'}
                    direction="row"
                    sx={{ backgroundColor: 'secondary.contrastText' }}
                    justifyContent="center"
                >
                    <Grid item xs={10} sm={8} md={6} >
                        <SliderSelect data={data} setData={setData} />
                    </Grid>
                </Grid>

                {data.showrealestate == true ? (
                    <Grid container
                        width={'80vw'}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ backgroundColor: 'secondary.contrastText' }}
                    >
                        <Grid item xs={10} sm={8} md={6} >
                            <br />
                            <RealEstate data={data} setData={setData} />
                            <br />
                        </Grid>
                    </Grid>
                ) : (<Grid container
                    width={'80vw'}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ backgroundColor: 'secondary.contrastText' }}
                >
                    <Grid item xs={10} sm={8} md={6} >
                        <br />
                    </Grid>
                </Grid>)}

                <Grid container
                    width={'80vw'}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    marginRight={4}
                    sx={{ backgroundColor: 'secondary.contrastText' }}>
                    <Grid item width='40ch' >
                        <PaymentSelect data={data} setData={setData} />
                        <br />
                        <br />
                    </Grid>
                </Grid>
            </Grid>
            <br />
            <br />
        </div>
    );
}

export default Calculator;
