import { Button } from "@mui/material";


export default function Download({ file, buttonName, fileName }) {
    const onDownload = () => {
      const link = document.createElement("a");
      link.href = file;
      link.setAttribute("download", fileName)
      link.click();
    };
  
    return (
      <Button onClick={onDownload} variant="contained" sx={{color: 'white'}}>
        {buttonName}
      </Button>
    );
  }