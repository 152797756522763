import * as React from 'react';
import { Box, Stack, Grid, Card, CardMedia, CardContent, useMediaQuery, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LoanUsesNewDetail from './LoanUsesNewDetail';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PercentIcon from '@mui/icons-material/Percent';
import SavingsIcon from '@mui/icons-material/Savings';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import balloon from '../images/balloon2.png'
import GradingIcon from '@mui/icons-material/Grading';
import moneyHand from '../images/moneyHand.png'
import SouthIcon from '@mui/icons-material/South';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

export default function LoanBenefitsNew() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{
            position: 'relative', // Position relative for absolute positioning of child elements
            width: '100%', // Full width of the parent container
        }}>
            <Card style={{ color: 'white', boxShadow: 'none', padding: 30, marginTop: 0, height: 'auto' }} >
            <br/>
            <br/>
                <Typography sx={{
                    fontSize: { xs: 38, sm: 40, md: 45, lg: 50 },
                    marginX: { xs: 4, sm: 6, md: 10, lg: 15 }
                }}
                    fontWeight={600}
                    color={theme.palette.primary.dark}
                    align="center"
                >
                    {isSmallScreen ? <>Why Choose<br/>SBA 7(a)</> : 'Why Choose SBA 7(a)'}
                </Typography>
                <Typography sx={{
                    fontSize: { xs: 18, sm: 20, md: 20, lg: 25 },
                    marginX: { xs: 4, sm: 6, md: 10, lg: 15 }
                }}
                    fontWeight={600}
                    color={theme.palette.secondary.light}
                    align="center"
                >
                The SBA 7(a) program is popular among small business owners for a reason. 
                These benefits provide the flexibility, affordability, and ease that businesses need to grow and succeed.
                </Typography>
                <br/>
                <br/>
                <br/>
                <Grid container /* xs={12} sm={12} md={12} lg={12}  */
                        sx={{
                            marginLeft: { xs: 0, sm: 0, md: 3, lg: 5 },
                            '& .MuiGrid-item': {
                              marginBottom: '50px', // Adjust the bottom margin as needed
                            },
                            '& .MuiGrid-item:last-of-type': {
                              marginBottom: 0, // Remove margin from the last item to avoid extra space
                            },
                          }} >
                
                    <LoanUsesNewDetail 
                        icon={<AttachMoneyIcon style={{ color: theme.palette.primary.red, fontSize: 50 }} />}
                        descr={<span>Lower Monthly<br/>Payments</span>} />
                    <LoanUsesNewDetail 
                        icon={<CalendarMonthIcon style={{ color: theme.palette.primary.red, fontSize: 50 }} />}
                        descr={<span>Long<br/>Repayment Terms</span>} />
                    <LoanUsesNewDetail 
                        icon={<PercentIcon style={{ color: theme.palette.primary.red, fontSize: 50 }} />}
                        descr={<span>Competitive<br/>Rates</span>} />
                    <LoanUsesNewDetail 
                        icon={<SavingsIcon style={{ color: theme.palette.primary.red, fontSize: 50 }} />}
                        descr={<span>Flexible<br/>Down Payments</span>} />
                    <LoanUsesNewDetail 
                        icon={<DoNotDisturbAltIcon style={{ color: theme.palette.primary.red, fontSize: 50 }} />}
                        descr={<span>No/Low Prepayment<br/>Penalties</span>} />
                    <LoanUsesNewDetail 
                        icon={<img src={balloon} alt="Balloon Icon" style={{ width: '27px', height: '42px', marginTop: '8px' }}/>}
                        descr={<span>No Balloon<br/>Payments</span>} />
                    <LoanUsesNewDetail 
                        icon={<GradingIcon style={{ color: theme.palette.primary.red, fontSize: 50 }} />}
                        descr={<span>Flexible Collateral<br/>Requirements</span>} />
                    <LoanUsesNewDetail 
                        icon={<img src={moneyHand} alt="Hand Icon" style={{ width: '52px', height: '42px', marginTop: '8px' }}/>}
                        descr={<span>Potential for<br/>100% Financing</span>} />
                    <LoanUsesNewDetail 
                        icon={<SouthIcon style={{ color: theme.palette.primary.red, fontSize: 50 }} />}
                        descr={<span>Reduced<br/>Out-of-Pocket Costs</span>} />
                    <LoanUsesNewDetail 
                        icon={<ThumbUpIcon style={{ color: theme.palette.primary.red, fontSize: 50 }} />}
                        descr={<span>Minimal<br/>Loan Covenants</span>} />
                        
                </Grid>
                <br/>
                <br/>
                <br/>
            </Card>
        </Box>
    )
}