
import Navbar from "./components/Navbar2"
import Home from "./components/Home"
import About from "./components/About"
import Contact from "./components/Contact"
import WhySBA from "./components/WhySBA"
import Referral from "./components/Referral"
import Forms from "./components/Forms"
import SBAExpress from "./components/SBAExpress"
import SBA7a from "./components/SBA7a"
import Expect from "./components/Expect"
import Calculator from "./components/Calculator"
import PrequalifyExpress from "./components/PrequalifyExpress"
import PrequalifyStandard from './components/PrequalifyStandard'
import CreditPull from "./components/CreditPull"
import CreditEndCongrats from "./components/CreditEndCongrats"
import CreditEndConcl from "./components/CreditEndConcl"
import CreditEndFrozen from "./components/CreditEndFrozen"
import CreditEndNoScore from "./components/CreditEndNoScore"
import Questionnaire from "./components/Questionnaire";
import SubmitExit from "./components/SubmitExit";
import LoanUsesBusinessAcquisition from './components/LoanUsesBusinessAcquisition2'
import LoanUsesWorkingCapital from "./components/LoanUsesWorkingCapital2"
import ESOP from "./components/LoanUsesESOP"
import SBAExplained from './components/SBAExplained'
import IndustryPage from './components/IndustryPage'
import IndustryVeterinary from './components/IndustryVeterinary'
import SafariTest from './components/SafariTest'
import LoanTypeTable from "./components/LoanTypeTable"

import useCustomUrlQuery from "./hooks/useCustomUrlQuery"

import { Routes, Route } from 'react-router-dom';
import { Grid } from "@mui/material";
import ScrollToTop from "./components/ScrollToTop";
import React, { useEffect, useState } from 'react';
import Footer from "./components/Footer2"
import { useTheme } from '@mui/material/styles';
import { Helmet } from "react-helmet-async"

import ReactGA from 'react-ga4';



function App() {
  ReactGA.initialize('G-QW1F1GDL23');

  const theme = useTheme();
  const [pricingTable, setPricingTable] = useState([])
  const [navBarHeight, setNavBarHeight] = useState(0)
  const [navBarHeight2, setNavBarHeight2] = useState(0)
  const [footerHeight, setFooterHeight] = useState(0)
  const [showNavbar, setShowNavbar] = useState(true);
  const [express, setExpress] = useState(true)

  const [data, setData] = useState({
    amount: '',
    amtInteract: false,
    amountformatted: '',
    interest: '',
    prime: '',
    margin: '',
    term: '',
    payment: '',
    stepamount: 10000,
    minamount: '',
    maxamount: '',
    minage: 18,
    maxage: 110,
    webpagetitle: '',
    webpagecontent: '',
    apiinputpk: 0,
    privacypolicylink: 'https://captecusa.com/privacy-policy/',
    privacypolicytitle: '',
    datetimesession: new Date(),
    clientname: 'CapTec USA',
    calendlylink: null,
    sendingemail: '',
    colorstep: theme.palette.primary.dark,
    colorfont: theme.palette.primary.dark,
    colorbackground: theme.palette.secondary.contrastText,
    logospacing: 1,
    real_estate: false,
    showrealestate: true,
    qualifiedrate: true,
    customurl: '',
    reactKey: '0_0_0',
    minCredit: 0,
    startCredit: false,
    finishCredit: false,
  });

  const [creditResult, setCreditResult] = useState(0)
  const [open, setOpen] = React.useState(false);

  const [contactInfo, setContactInfo] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    businessname: "",
    ssn: "",
    dob: null,
    address: "",
    city: "",
    state: "",
    zip: "",
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    phoneError: false,
    businessError: false,
    contactconsent: false,
    privacyconsent: false,
    ssnError: false,
    dobError: false,
    addressError: false,
    cityError: false,
    stateError: false,
    zipError: false,
    datetimeadded: new Date(),
    ssnconsent: false,
    businesstwoyears: true,
    workingcapital: true
  });

  const [qstepdata, setQStepData] = useState({
    amountformatted: "",
    legalBusinessName: "",
    entityType: "",
    employees: "",
    TIN: "",
    dateBusinessEst: null,
    busStreet1: "",
    busStreet2: "",
    busCity: "",
    busState: "",
    busZip: "",
    ownernum: "",
    applicantID: "",
    website: "",

    owner1FirstName: "",
    owner1LastName: "",
    owner1Perc: "",
    owner1HomeAddress1: "",
    owner1HomeAddress2: "",
    owner1City: "",
    owner1State: "",
    owner1Zip: "",
    owner1Email: "",
    owner1Phone: "",
    owner1SSN: "",
    owner1SSNoriginal: "",

    owner2FirstName: "",
    owner2LastName: "",
    owner2Perc: "",
    owner2Email: "",
    owner2Phone: "",
    owner2SSN: "",

    owner3FirstName: "",
    owner3LastName: "",
    owner3Perc: "",
    owner3Email: "",
    owner3Phone: "",
    owner3SSN: "",

    owner4FirstName: "",
    owner4LastName: "",
    owner4Perc: "",
    owner4Email: "",
    owner4Phone: "",
    owner4SSN: "",

    owner5FirstName: "",
    owner5LastName: "",
    owner5Perc: "",
    owner5Email: "",
    owner5Phone: "",
    owner5SSN: "",

    legalBusinessNameError: false,
    entityTypeError: false,
    employeesError: false,
    TINError: false,
    dateBusinessEstError: false,
    busStreet1Error: false,
    busCityError: false,
    busStateError: false,
    busZipError: false,

    ownernumError: false,
    owner1FirstNameError: false,
    owner1LastNameError: false,
    owner1PercError: false,
    owner1HomeAddress1Error: false,
    owner1CityError: false,
    owner1StateError: false,
    owner1ZipError: false,
    owner1EmailError: false,
    owner1PhoneError: false,

    agreeTerms: false,
    submitted: 2,

    qDriversLicenseName: null,
    qBusTax1Name: null,
    qBusTax2Name: null,
    qPersonalTaxName: null

  });

  const [industry, setIndustry] = React.useState('0');
  const [industryError, setIndustryError] = React.useState(false);
  const [loanUse, setLoanUse] = React.useState('0');
  const [loanUseError, setLoanUseError] = React.useState(false);
  const [realEstate, setRealEstate] = React.useState('0');
  const [realEstateError, setRealEstateError] = React.useState(false);

  const [qualifyProfit, setQualifyProfit] = useState(null);
  const [qualifyProfitable, setQualifyProfitable] = useState(null);
  const [qualifyCitizen, setQualifyCitizen] = useState(null);
  const [qualifyTaxes, setQualifyTaxes] = useState(null);
  const [qualifyDelq, setQualifyDelq] = useState(null);
  const [qualifyDefault, setQualifyDefault] = useState(null);
  const [qualifyBankrupt, setQualifyBankrupt] = useState(null);
  const [qualifyIncarc, setQualifyIncarc] = useState(null);
  const [qualifyIndustry, setQualifyIndustry] = useState(null);


  const [driverslicense, setDriversLicense] = useState({ file: null, name: 'No file chosen', size: null, type: null, loading: false });
  const [year1bustax, setYear1BusTax] = useState({ file: null, name: 'No file chosen', size: null, type: null, loading: false });
  const [year2bustax, setYear2BusTax] = useState({ file: null, name: 'No file chosen', size: null, type: null, loading: false });
  const [personaltax, setPersonalTax] = useState({ file: null, name: 'No file chosen', size: null, type: null, loading: false });

  const [steps, setSteps] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false
  });

  const [isQuestionnaire, setIsQuestionnaire] = useState(false)


  useEffect(() => {
    let url = window.location.href
    setIsQuestionnaire(url.includes('questionnaire'))
  }, [window.location.href]);

  //console.log('isQuestionnaire : ', isQuestionnaire)
  //console.log(eligibility)

  function formatAmount(value) {
    if (!value) return value;
    //console.log(value)
    const amountValue = value.toString().replace(/[^\d]/g, '');
    let amountValueTrim = amountValue.length >= 8 ? amountValue.substring(0, 7) : amountValue;
    const amountValueTrimLength = amountValueTrim.length;
    if (amountValueTrimLength < 4) return `$${amountValueTrim}`;
    if (amountValueTrimLength < 7) return `$${amountValueTrim.substring(0, amountValueTrim.length - 3)},${amountValueTrim.substring(amountValueTrim.length - 3, amountValueTrim.length)}`;
    if (amountValueTrimLength < 8) return `$${amountValueTrim.substring(0, amountValueTrim.length - 6)},${amountValueTrim.substring(amountValueTrim.length - 6, amountValueTrim.length - 3)},${amountValueTrim.substring(amountValueTrim.length - 3, amountValueTrim.length)}`;
  }

  const roundToNearest = (number, roundingBase) => {
    return Math.round(number / roundingBase) * roundingBase;
  };

  const findItemByKey = (key) => {
    return pricingTable.find(item => item.ReactKey === key);
  };

  //console.log('pricing table: ', pricingTable)
  //console.log('data.amountformatted: ', data.amountformatted)
  //const { isLoading } = useQuery({ data, setData, qstepdata, setQStepData })
  const { isCustomUrlLoading } = useCustomUrlQuery({
    data, setData, contactInfo, setContactInfo
    , qstepdata, setQStepData
    , driverslicense, setDriversLicense
    , year1bustax, setYear1BusTax
    , year2bustax, setYear2BusTax
    , personaltax, setPersonalTax
    , qualifyProfit, setQualifyProfit
    , qualifyProfitable, setQualifyProfitable
    , qualifyTaxes, setQualifyTaxes
    , qualifyDelq, setQualifyDelq
    , qualifyDefault, setQualifyDefault
    , qualifyBankrupt, setQualifyBankrupt
    , qualifyIncarc, setQualifyIncarc
    , qualifyCitizen, setQualifyCitizen
    , qualifyIndustry, setQualifyIndustry
    , steps, setSteps
  })

  useEffect(() => {
    if (isQuestionnaire) {
      // Run some logic when `anotherState` is true
      // You can put any logic that depends on `isCustomUrlLoading` or other variables here
      //console.log('Custom URL is loading:', isCustomUrlLoading);
    }
  }, [isQuestionnaire, isCustomUrlLoading]);

  useEffect(() => {
    fetch('https://cwihm8emt4.execute-api.us-east-1.amazonaws.com/v1', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        // Include the request body if necessary
      }),
    })
      .then(function (serverPromise) {
        serverPromise.json()
          .then(function (j) {

            //console.log('useQuery props.data.logo: ',props.data.logo);
            //console.log('j: ', j);
            //console.log('jlogo: ',j.logo);
            //console.log(j.body);
            //console.log(JSON.parse(j.body));
            //console.log(JSON.parse(j.body)[0]);
            setPricingTable(JSON.parse(j.body))
            //console.log('pricingTable : ', pricingTable)
            //console.log('pricingTable[0] : ', pricingTable[0])
          })
      })

  }, []);

  useEffect(() => {
    if (pricingTable.length === 0) {
      return; // Exit early if pricingTable is empty
    }

    const foundItem = findItemByKey(data.reactKey);
    if (!foundItem) {
      console.warn('Item not found');
      return; // Exit early if no item is found
    }

    //console.log('foundItem:', foundItem);

    // Calculate values
    const intcalc = (foundItem.Prime + foundItem.Margin) / 12;
    const termcalc = foundItem.Term * 12;
    const calc1 = (1 + intcalc) ** termcalc;
    const amt = roundToNearest((foundItem.MinVal + foundItem.MaxVal) / 2, 100000);
    const pmtcalc = amt * (intcalc * calc1) / (calc1 - 1);

    // Set data
    setData(s => ({
      ...s,
      amount: amt,
      amountformatted: formatAmount(amt),
      interest: foundItem.Prime + foundItem.Margin,
      prime: foundItem.Prime,
      margin: foundItem.Margin,
      term: foundItem.Term,
      payment: pmtcalc,
      minamount: foundItem.MinVal,
      maxamount: foundItem.MaxVal,
      minCredit: foundItem.MinCredit,
    }));
  }, [pricingTable]);

  //console.log('new Date() : ', new Date())
  //console.log('data : ', data)

  return (
    <div className="App" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', width: '100vw', overflow: 'hidden' }} >
      <Helmet>
        <title>CapTec USA</title>
        <meta name="description" content="SBA Experts - Business Loans after Business Hours. Welcome to modern business lending." />
      </Helmet>
      {isQuestionnaire ? (
        <></>
      ) : (
        <Navbar creditResult={creditResult} data={data} 
            navBarHeight={navBarHeight} setNavBarHeight={setNavBarHeight}
          navBarHeight2={navBarHeight2} setNavBarHeight2={setNavBarHeight2}
          showNavbar={showNavbar} setShowNavbar={setShowNavbar} />
      )}

      <Grid container rowSpacing={0} justifyContent="center" >
        <Grid item xs={12} md={12} >
          <ScrollToTop />
          <Routes>
            <Route Route path="/" element={<Home />} />
            <Route Route path="/home" element={<Home />} />
            <Route Route path="/about" element={<About />} />
            <Route Route path="/contact" element={<Contact />} />
            <Route Route path="/sbaexplained" element={<SBAExplained />} />
            <Route Route path="/process" element={<Expect />} />
            <Route Route path="/forms" element={<Forms />} />
            <Route Route path="/referral" element={<Referral />} />
            <Route Route path="/sbaexpress" element={<SBAExpress />} />
            <Route Route path="/sba7a" element={<SBA7a />} />
            <Route Route path="/safari" element={<SafariTest />} />
            <Route Route path="/loanuses/acquisition" element={<LoanUsesBusinessAcquisition 
                                                                navBarHeight={navBarHeight} 
                                                                footerHeight={footerHeight} 
                                                                open={open} setOpen={setOpen}
                                                                showNavbar={showNavbar} />} />
            <Route Route path="/loanuses/workingcapital" element={<LoanUsesWorkingCapital 
                                                                navBarHeight={navBarHeight} 
                                                                footerHeight={footerHeight} 
                                                                open={open} setOpen={setOpen}
                                                                showNavbar={showNavbar} />} />
            <Route Route path="/loanuses/esop" element={<ESOP 
                                                                navBarHeight={navBarHeight} 
                                                                footerHeight={footerHeight} 
                                                                open={open} setOpen={setOpen}
                                                                showNavbar={showNavbar} />} />
            <Route Route path="/industry" element={<IndustryPage 
                                                                navBarHeight={navBarHeight} 
                                                                footerHeight={footerHeight} 
                                                                open={open} setOpen={setOpen}
                                                                showNavbar={showNavbar} />} /> 
            <Route Route path="/industry/veterinary" element={<IndustryVeterinary
                                                                navBarHeight={navBarHeight} 
                                                                footerHeight={footerHeight} 
                                                                open={open} setOpen={setOpen}
                                                                showNavbar={showNavbar} />} />                                                                
            <Route Route path="/calculator" element={<Calculator data={data} setData={setData} />} />
            <Route Route path='/prequalify' element={<LoanTypeTable data={data} setData={setData}
              industry={industry} setIndustry={setIndustry}
              industryError={industryError} setIndustryError={setIndustryError}
              loanUse={loanUse} setLoanUse={setLoanUse}
              loanUseError={loanUseError} setLoanUseError={setLoanUseError}
              realEstate={realEstate} setRealEstate={setRealEstate}
              realEstateError={realEstateError} setRealEstateError={setRealEstateError}
              pricingTable={pricingTable}
              contactInfo={contactInfo} setContactInfo={setContactInfo}
              qstepdata={qstepdata} setQStepData={setQStepData}
              express={express} setExpress={setExpress} />} />
            <Route Route path='/prequalify/express' element={<PrequalifyExpress data={data} setData={setData}
              industry={industry} setIndustry={setIndustry}
              industryError={industryError} setIndustryError={setIndustryError}
              loanUse={loanUse} setLoanUse={setLoanUse}
              loanUseError={loanUseError} setLoanUseError={setLoanUseError}
              realEstate={realEstate} setRealEstate={setRealEstate}
              realEstateError={realEstateError} setRealEstateError={setRealEstateError}
              pricingTable={pricingTable}
              contactInfo={contactInfo} setContactInfo={setContactInfo}
              qstepdata={qstepdata} setQStepData={setQStepData}
              express={express} setExpress={setExpress} />} />
            <Route Route path='/prequalify/standard' element={<PrequalifyStandard data={data} setData={setData}
              industry={industry} setIndustry={setIndustry}
              industryError={industryError} setIndustryError={setIndustryError}
              loanUse={loanUse} setLoanUse={setLoanUse}
              loanUseError={loanUseError} setLoanUseError={setLoanUseError}
              realEstate={realEstate} setRealEstate={setRealEstate}
              realEstateError={realEstateError} setRealEstateError={setRealEstateError}
              pricingTable={pricingTable}
              contactInfo={contactInfo} setContactInfo={setContactInfo}
              qstepdata={qstepdata} setQStepData={setQStepData}
              express={express} setExpress={setExpress} />} />
            <Route Route path='/credit' element={<CreditPull data={data} setData={setData}
              pricingTable={pricingTable}
              contactInfo={contactInfo}
              setContactInfo={setContactInfo}
              qstepdata={qstepdata}
              setQStepData={setQStepData} 
              creditResult={creditResult} setCreditResult={setCreditResult} />} />
            <Route Route path="/concl" element={<CreditEndConcl data={data} setCreditResult={setCreditResult} />} />
            <Route Route path="/noscore" element={<CreditEndNoScore data={data} setCreditResult={setCreditResult}
              contactInfo={contactInfo} setContactInfo={setContactInfo}
              driverslicense={driverslicense} setDriversLicense={setDriversLicense}
              year1bustax={year1bustax} setYear1BusTax={setYear1BusTax}
              year2bustax={year2bustax} setYear2BusTax={setYear2BusTax}
              personaltax={personaltax} setPersonalTax={setPersonalTax}
              steps={steps} setSteps={setSteps}
              qstepdata={qstepdata} setQStepData={setQStepData}
              qualifyProfit={qualifyProfit} setQualifyProfit={setQualifyProfit}
              qualifyProfitable={qualifyProfitable} setQualifyProfitable={setQualifyProfitable}
              qualifyTaxes={qualifyTaxes} setQualifyTaxes={setQualifyTaxes}
              qualifyDelq={qualifyDelq} setQualifyDelq={setQualifyDelq}
              qualifyDefault={qualifyDefault} setQualifyDefault={setQualifyDefault}
              qualifyBankrupt={qualifyBankrupt} setQualifyBankrupt={setQualifyBankrupt}
              qualifyIncarc={qualifyIncarc} setQualifyIncarc={setQualifyIncarc}
              qualifyCitizen={qualifyCitizen} setQualifyCitizen={setQualifyCitizen}
              qualifyIndustry={qualifyIndustry} setQualifyIndustry={setQualifyIndustry} />} />
            <Route Route path="/frozen" element={<CreditEndFrozen data={data} setCreditResult={setCreditResult} />} />
            <Route Route path="/congrats" element={<CreditEndCongrats data={data} setCreditResult={setCreditResult} />} />
            <Route Route path="/questionnaire/*" element={<Questionnaire data={data} setData={setData}
              contactInfo={contactInfo} setContactInfo={setContactInfo}
              driverslicense={driverslicense} setDriversLicense={setDriversLicense}
              year1bustax={year1bustax} setYear1BusTax={setYear1BusTax}
              year2bustax={year2bustax} setYear2BusTax={setYear2BusTax}
              personaltax={personaltax} setPersonalTax={setPersonalTax}
              steps={steps} setSteps={setSteps}
              qstepdata={qstepdata} setQStepData={setQStepData}
              qualifyProfit={qualifyProfit} setQualifyProfit={setQualifyProfit}
              qualifyProfitable={qualifyProfitable} setQualifyProfitable={setQualifyProfitable}
              qualifyTaxes={qualifyTaxes} setQualifyTaxes={setQualifyTaxes}
              qualifyDelq={qualifyDelq} setQualifyDelq={setQualifyDelq}
              qualifyDefault={qualifyDefault} setQualifyDefault={setQualifyDefault}
              qualifyBankrupt={qualifyBankrupt} setQualifyBankrupt={setQualifyBankrupt}
              qualifyIncarc={qualifyIncarc} setQualifyIncarc={setQualifyIncarc}
              qualifyCitizen={qualifyCitizen} setQualifyCitizen={setQualifyCitizen}
              qualifyIndustry={qualifyIndustry} setQualifyIndustry={setQualifyIndustry}
            />} />
            <Route path="/questionnaire/submit" element={<SubmitExit data={data} />} />
          </Routes>
        </Grid>
      </Grid>
      {isQuestionnaire ? (
        <></>
      ) : (
        <Footer footerHeight={footerHeight} setFooterHeight={setFooterHeight} />
      )}

    </div>
  );
}

export default App;
