import * as React from 'react';
import { Box, Stack } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';


import { useMediaQuery } from '@mui/material';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import StepperComp from './StepperComp'
import NextButtonSaveExit from './NextButtonSaveExit';
import axios from "axios";
import capteclogo from '../images/capteclogo3.png'



export default function HorizontalNonLinearStepper({ data, setData,
    contactInfo, setContactInfo,
    driverslicense, setDriversLicense,
    year1bustax, setYear1BusTax,
    year2bustax, setYear2BusTax,
    personaltax, setPersonalTax,
    steps, setSteps,
    qstepdata, setQStepData,
    qualifyProfit, setQualifyProfit,
    qualifyProfitable, setQualifyProfitable,
    qualifyTaxes, setQualifyTaxes,
    qualifyDelq, setQualifyDelq,
    qualifyDefault, setQualifyDefault,
    qualifyBankrupt, setQualifyBankrupt,
    qualifyIncarc, setQualifyIncarc,
    qualifyCitizen, setQualifyCitizen,
    qualifyIndustry, setQualifyIndustry
}) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});

    const navigate = useNavigate()

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    function formatDateTime(date) {

        // Get individual date components
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

        // Concatenate components into desired format
        const formattedDateTime = `${year}${month}${day}-${hours}.${minutes}.${seconds}.${milliseconds}`;

        return formattedDateTime;
    }

    const fileDate = formatDateTime(data.datetimesession)

    const [loading, setLoading] = React.useState(false);

    // API Gateway url to invoke function to generate presigned url
    const API_ENDPOINT = "https://b5fjiiu2wl.execute-api.us-east-1.amazonaws.com/v1";

    //const [selectedYr1BusTax, setSelectedYr1BusTax] = useState(null);
    //const [selectedYr2BusTax, setSelectedYr2BusTax] = useState(null);

    // Function to generate the presigned url
    const getPresignedUrl = async (filetype, filename, bucket) => {
        // GET request: presigned URL
        //console.log('starting getPresignedUrl')
        try {
            const response = await axios({
                method: 'POST',
                url: API_ENDPOINT,
                data: {
                    filetype1: filetype,
                    filename1: filename.replace(/\s/g, ""),
                    bucket1: bucket,
                }
            });
            //console.log('Response:', response.data);
            //console.log('Response:', response.data.body);
            //console.log('Response:', JSON.parse(response.data.body));
            //console.log('Response:', JSON.parse(response.data.body).presignedUrl);
            const presignedUrl = JSON.parse(response.data.body).presignedUrl
            //console.log('presignedUrl: ', presignedUrl);
            return presignedUrl;
        } catch (error) {
            console.error('Error:', error);
        }

    };



    // Function to upload the selected file using the generated presigned url
    const uploadToPresignedUrl = async (presignedUrl, file, filetype) => {
        // Upload file to pre-signed URL
        const uploadResponse = await axios.put(presignedUrl, file, {
            headers: {
                "Content-Type": filetype,
                //"Access-Control-Allow-Origin": "*",
            },
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                //setUploadProgress(percentCompleted);
                console.log(`Upload Progress: ${percentCompleted}%`);
            },
        });
        console.log(uploadResponse);
    };

    // Function to orchestrate the upload process
    const handleUpload = async (file, filetype, filename, bucket) => {
        try {
            // Ensure a file is selected
            if (!file) {
                console.error("No file selected.");
                return;
            }
            const presignedUrl = await getPresignedUrl(filetype, filename, bucket);
            uploadToPresignedUrl(presignedUrl, file, filetype);
        } catch (error) {
            // Handle error
            console.error("Error uploading file:", error);
        }
    };

    const handleClick = () => {

        setLoading(true)

        let DriversLicenseName = qstepdata.qDriversLicenseName
        let BusTax1Name = qstepdata.qBusTax1Name
        let BusTax2Name = qstepdata.qBusTax2Name
        let PersonalTaxName = qstepdata.qPersonalTaxName

        let DriversLicenseFile = 0
        let BusTax1File = 0
        let BusTax2File = 0
        let PersonalTaxFile = 0

        const ApplicantID_Prior = qstepdata.applicantID

        if (driverslicense.file != null) {
            DriversLicenseName = driverslicense.name.replace(/_/g, "");
            DriversLicenseFile = 1
        } else {
            console.log('no driverslicense');
        }

        if (year1bustax.file != null) {
            console.log(year1bustax.file, year1bustax.type, year1bustax.name);
            const CapTecFileName = contactInfo.firstname.replace(/_/g, "") + '_' + contactInfo.lastname.replace(/_/g, "") + '_' + fileDate + '_BusTax1_' + year1bustax.name.replace(/_/g, "");
            BusTax1Name = year1bustax.name.replace(/_/g, "")
            BusTax1File = 1
            handleUpload(year1bustax.file, year1bustax.type, CapTecFileName, 'presigned-url-bucket-bustaxyr1-holding');
        } else {
            console.log('no year1bustax');
        }

        if (year2bustax.file != null) {
            console.log(year2bustax.file, year2bustax.type, year2bustax.name)
            const CapTecFileName2 = contactInfo.firstname.replace(/_/g, "") + '_' + contactInfo.lastname.replace(/_/g, "") + '_' + fileDate + '_BusTax2_' + year2bustax.name.replace(/_/g, "")
            BusTax2Name = year2bustax.name.replace(/_/g, "")
            BusTax2File = 1
            handleUpload(year2bustax.file, year2bustax.type, CapTecFileName2, 'presigned-url-bucket-bustaxyr2-holding')
        } else {
            console.log('no year2bustax')
        }

        if (personaltax.file != null) {
            PersonalTaxName = personaltax.name.replace(/_/g, "");
            PersonalTaxFile = 1
        } else {
            console.log('no personaltax')
        }

        //console.log('step progress qstepdata : ',qstepdata)

        fetch('https://d8mbetqlo3.execute-api.us-east-1.amazonaws.com/v1', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                first_name: contactInfo.firstname.replace(/ +(?= )/g, '').trim(),
                last_name: contactInfo.lastname.replace(/ +(?= )/g, '').trim(),
                address: contactInfo.address.replace(/ +(?= )/g, '').trim(),
                city: contactInfo.city.replace(/ +(?= )/g, '').trim(),
                state: contactInfo.state,
                zip: contactInfo.zip,
                ssn: contactInfo.ssn.replaceAll("-", ""),
                date_of_birth: contactInfo.dob,
                dateTime: data.datetimesession,
                email: contactInfo.email,
                business_name: contactInfo.businessname.replace(/ +(?= )/g, '').trim(),
                amount: data.amount,
                interest: data.interest,
                margin: data.margin,
                term: data.term,
                phone: contactInfo.phone,
                real_estate: data.realestate,
                payment: data.payment,
                apiinputpk: data.apiinputpk,
                appdemo: 1,
                BusTax1: year1bustax.file ? 1 : 0,
                BusTax2: year2bustax.file ? 1 : 0,
                ApplicantID: contactInfo.firstname + '_' + contactInfo.lastname + '_' + fileDate,
                customurl: data.customurl,
                ApplicantID_Prior: ApplicantID_Prior,

                q_amountformatted: parseInt(qstepdata.amountformatted.replace(/\D/g, '')),
                q_legalBusinessName: qstepdata.legalBusinessName.replace(/ +(?= )/g, '').trim(),
                q_entityType: qstepdata.entityType,
                q_employees: qstepdata.employees,
                q_TIN: qstepdata.TIN,
                q_dateBusinessEst: qstepdata.dateBusinessEst,
                q_busStreet1: qstepdata.busStreet1,
                q_busStreet2: qstepdata.busStreet2,
                q_busCity: qstepdata.busCity,
                q_busState: qstepdata.busState,
                q_busZip: qstepdata.busZip,
                q_ownernum: qstepdata.ownernum,
                q_website: qstepdata.website,

                q_owner1FirstName: qstepdata.owner1FirstName.replace(/ +(?= )/g, '').trim(),
                q_owner1LastName: qstepdata.owner1LastName.replace(/ +(?= )/g, '').trim(),
                q_owner1Perc: qstepdata.owner1Perc,
                q_owner1HomeAddress1: qstepdata.owner1HomeAddress1.replace(/ +(?= )/g, '').trim(),
                q_owner1HomeAddress2: qstepdata.owner1HomeAddress2.replace(/ +(?= )/g, '').trim(),
                q_owner1City: qstepdata.owner1City.replace(/ +(?= )/g, '').trim(),
                q_owner1State: qstepdata.owner1State,
                q_owner1Zip: qstepdata.owner1Zip,
                q_owner1Email: qstepdata.owner1Email,
                q_owner1Phone: qstepdata.owner1Phone,
                q_owner1SSN: qstepdata.owner1SSN.includes('*') ? qstepdata.owner1SSNoriginal.replaceAll("-", "") : qstepdata.owner1SSN.replaceAll("-", ""),

                q_owner2FirstName: qstepdata.owner2FirstName.replace(/ +(?= )/g, '').trim(),
                q_owner2LastName: qstepdata.owner2LastName.replace(/ +(?= )/g, '').trim(),
                q_owner2Perc: qstepdata.owner2Perc,
                q_owner2Email: qstepdata.owner2Email,
                q_owner2Phone: qstepdata.owner2Phone,
                //q_owner2SSN: qstepdata.owner2SSN.replaceAll("-", ""),

                q_owner3FirstName: qstepdata.owner3FirstName.replace(/ +(?= )/g, '').trim(),
                q_owner3LastName: qstepdata.owner3LastName.replace(/ +(?= )/g, '').trim(),
                q_owner3Perc: qstepdata.owner3Perc,
                q_owner3Email: qstepdata.owner3Email,
                q_owner3Phone: qstepdata.owner3Phone,
                //q_owner3SSN: qstepdata.owner3SSN.replaceAll("-", ""),

                q_owner4FirstName: qstepdata.owner4FirstName.replace(/ +(?= )/g, '').trim(),
                q_owner4LastName: qstepdata.owner4LastName.replace(/ +(?= )/g, '').trim(),
                q_owner4Perc: qstepdata.owner4Perc,
                q_owner4Email: qstepdata.owner4Email,
                q_owner4Phone: qstepdata.owner4Phone,
                //q_owner4SSN: qstepdata.owner4SSN.replaceAll("-", ""),

                q_owner5FirstName: qstepdata.owner5FirstName.replace(/ +(?= )/g, '').trim(),
                q_owner5LastName: qstepdata.owner5LastName.replace(/ +(?= )/g, '').trim(),
                q_owner5Perc: qstepdata.owner5Perc,
                q_owner5Email: qstepdata.owner5Email,
                q_owner5Phone: qstepdata.owner5Phone,
                //q_owner5SSN: qstepdata.owner5SSN.replaceAll("-", ""),

                q_qualifyProfit: qualifyProfit,
                q_qualifyProfitable: qualifyProfitable,
                q_qualifyCitizen: qualifyCitizen,
                q_qualifyTaxes: qualifyTaxes,
                q_qualifyDelq: qualifyDelq,
                q_qualifyDefault: qualifyDefault,
                q_qualifyBankrupt: qualifyBankrupt,
                q_qualifyIncarc: qualifyIncarc,
                q_qualifyIndustry: qualifyIndustry,

                DriversLicenseName: DriversLicenseName,
                BusTax1Name: BusTax1Name,
                BusTax2Name: BusTax2Name,
                PersonalTaxName: PersonalTaxName,

                DriversLicenseFiie: DriversLicenseFile,
                BusTax1File: BusTax1File,
                BusTax2File: BusTax2File,
                PersonalTaxFile: PersonalTaxFile,

                q_DriversLicenseName: driverslicense.name,
                q_BusTax1Name: year1bustax.name,
                q_BusTax2Name: year2bustax.name,
                q_PersonalTaxName: personaltax.name,


            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                //console.log('response: ', response);
                return response.json();
            })
            .catch(error => {
                // Handle errors
                console.error('There was a problem with the request:', error);
            })
            .then(() => {
                //console.log('creditresult: ', creditresult)
                //navigate('/submit', { replace: true })
                navigate('/save_exit')
            }
            )
    }




    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };


    ///////////////////////////////////////////////////// responsive drawer

    const drawerWidth = 240;


    const stepdescr = ['Business Information'
        , 'Owner Information'
        , 'Qualification Questions'
        , 'Upload Documents'
        , 'Submit'];

    const drawer = (
        <div>
            <Stack gap={0} mt={4} alignItems={"center"} mb={0}>
                <img src={require('../images/capteclogo.png')} alt='CapTec USA' width="150"></img>
                <br />
                <br />
                <StepperComp data={data}
                    activeStep={activeStep}
                    stepdescr={stepdescr}
                    completed={completed}
                    handleStep={handleStep}
                    steps={steps}
                />
            </Stack>
            <br />
            <br />
            <Stack>
                <Typography fontSize={14} sx={{ fontWeight: 700 }} color='gray' align="center">
                    Need more time?
                </Typography>
                <NextButtonSaveExit data={data} handleClick={handleClick} />
            </Stack>
        </div>
    );


    const switchReturn = () => {
        switch (activeStep) {

            case 0: return <Step1 pixel='-240px' data={data} setData={setData}
                contactInfo={contactInfo} setContactInfo={setContactInfo}
                driverslicense={driverslicense} setDriversLicense={setDriversLicense}
                year1bustax={year1bustax} setYear1BusTax={setYear1BusTax}
                year2bustax={year2bustax} setYear2BusTax={setYear2BusTax}
                personaltax={personaltax} setPersonalTax={setPersonalTax}
                steps={steps} setSteps={setSteps}
                handleBack={handleBack} handleNext={handleNext}
                qstepdata={qstepdata} setQStepData={setQStepData}
                qualifyProfit={qualifyProfit} setQualifyProfit={setQualifyProfit}
                qualifyProfitable={qualifyProfitable} setQualifyProfitable={setQualifyProfitable}
                qualifyTaxes={qualifyTaxes} setQualifyTaxes={setQualifyTaxes}
                qualifyDelq={qualifyDelq} setQualifyDelq={setQualifyDelq}
                qualifyDefault={qualifyDefault} setQualifyDefault={setQualifyDefault}
                qualifyBankrupt={qualifyBankrupt} setQualifyBankrupt={setQualifyBankrupt}
                qualifyIncarc={qualifyIncarc} setQualifyIncarc={setQualifyIncarc}
                qualifyCitizen={qualifyCitizen} setQualifyCitizen={setQualifyCitizen}
                qualifyIndustry={qualifyIndustry} setQualifyIndustry={setQualifyIndustry}

            />
            case 1: return <Step2 pixel='-240px' data={data} setData={setData}
                contactInfo={contactInfo} setContactInfo={setContactInfo}
                driverslicense={driverslicense} setDriversLicense={setDriversLicense}
                year1bustax={year1bustax} setYear1BusTax={setYear1BusTax}
                year2bustax={year2bustax} setYear2BusTax={setYear2BusTax}
                personaltax={personaltax} setPersonalTax={setPersonalTax}
                steps={steps} setSteps={setSteps}
                handleBack={handleBack} handleNext={handleNext}
                qstepdata={qstepdata} setQStepData={setQStepData} />
            case 2: return <Step3 pixel='-240px' data={data} setData={setData}
                contactInfo={contactInfo} setContactInfo={setContactInfo}
                driverslicense={driverslicense} setDriversLicense={setDriversLicense}
                year1bustax={year1bustax} setYear1BusTax={setYear1BusTax}
                year2bustax={year2bustax} setYear2BusTax={setYear2BusTax}
                personaltax={personaltax} setPersonalTax={setPersonalTax}
                steps={steps} setSteps={setSteps}
                handleBack={handleBack} handleNext={handleNext}
                qstepdata={qstepdata} setQStepData={setQStepData}
                qualifyProfit={qualifyProfit} setQualifyProfit={setQualifyProfit}
                qualifyProfitable={qualifyProfitable} setQualifyProfitable={setQualifyProfitable}
                qualifyTaxes={qualifyTaxes} setQualifyTaxes={setQualifyTaxes}
                qualifyDelq={qualifyDelq} setQualifyDelq={setQualifyDelq}
                qualifyDefault={qualifyDefault} setQualifyDefault={setQualifyDefault}
                qualifyBankrupt={qualifyBankrupt} setQualifyBankrupt={setQualifyBankrupt}
                qualifyIncarc={qualifyIncarc} setQualifyIncarc={setQualifyIncarc}
                qualifyCitizen={qualifyCitizen} setQualifyCitizen={setQualifyCitizen}
                qualifyIndustry={qualifyIndustry} setQualifyIndustry={setQualifyIndustry} />
            case 3: return <Step4 pixel='-240px' data={data} setData={setData}
                contactInfo={contactInfo} setContactInfo={setContactInfo}
                driverslicense={driverslicense} setDriversLicense={setDriversLicense}
                year1bustax={year1bustax} setYear1BusTax={setYear1BusTax}
                year2bustax={year2bustax} setYear2BusTax={setYear2BusTax}
                personaltax={personaltax} setPersonalTax={setPersonalTax}
                steps={steps} setSteps={setSteps}
                handleBack={handleBack} handleNext={handleNext}
                qstepdata={qstepdata} setQStepData={setQStepData} />
            case 4: return <Step5 pixel='-240px' data={data} setData={setData}
                contactInfo={contactInfo} setContactInfo={setContactInfo}
                driverslicense={driverslicense} setDriversLicense={setDriversLicense}
                year1bustax={year1bustax} setYear1BusTax={setYear1BusTax}
                year2bustax={year2bustax} setYear2BusTax={setYear2BusTax}
                personaltax={personaltax} setPersonalTax={setPersonalTax}
                steps={steps} setSteps={setSteps}
                handleBack={handleBack} handleNext={handleNext}
                qstepdata={qstepdata} setQStepData={setQStepData}
                qualifyProfit={qualifyProfit} setQualifyProfit={setQualifyProfit}
                qualifyProfitable={qualifyProfitable}
                qualifyTaxes={qualifyTaxes} setQualifyTaxes={setQualifyTaxes}
                qualifyDelq={qualifyDelq} setQualifyDelq={setQualifyDelq}
                qualifyDefault={qualifyDefault} setQualifyDefault={setQualifyDefault}
                qualifyBankrupt={qualifyBankrupt} setQualifyBankrupt={setQualifyBankrupt}
                qualifyIncarc={qualifyIncarc} setQualifyIncarc={setQualifyIncarc}
                qualifyCitizen={qualifyCitizen} setQualifyCitizen={setQualifyCitizen}
                qualifyIndustry={qualifyIndustry} setQualifyIndustry={setQualifyIndustry} />
            default: return <h1>No project match</h1>
        }
    }


    const isMdAndSmaller = useMediaQuery((theme) => theme.breakpoints.down('md'));

    function HorizontalBar({ isMdAndSmaller }) {
        if (isMdAndSmaller) {
            console.log('isMdAndSmaller:', isMdAndSmaller)
            return <Stack gap={0} mt={0} alignItems={"center"} mb={0}>
                <img src={require('../images/capteclogo.png')} alt='CapTec USA' width="150"></img>
                <br />
                <Stepper nonLinear activeStep={activeStep} connector={<HorizontalRuleIcon />} >
                    {stepdescr.map((label, index) => (
                        <Step key={label} completed={completed[index]}
                            sx={{
                                '& .MuiStepLabel-root .Mui-completed': {
                                    color: data.colorfont, // circle color (COMPLETED)
                                    fontWeight: 700,
                                },
                                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel':
                                {
                                    color: 'grey.500', // Just text label (COMPLETED)
                                },
                                '& .MuiStepLabel-root .Mui-active': {
                                    color: data.colorfont, // circle color (ACTIVE)
                                    fontWeight: 700,
                                },
                                '& .MuiStepLabel-root .Mui-active.MuiStepLabel-text':
                                {
                                    color: data.colorfont, // Just text label (ACTIVE)
                                    fontWeight: 700,
                                },
                                '& .MuiStepLabel-label.Mui-active.MuiStepLabel':
                                {
                                    color: 'common.white', // Just text label (ACTIVE)
                                },
                                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                    fill: 'white', // circle's number (ACTIVE)
                                },
                            }}>
                            <StepButton color="inherit" onClick={handleStep(index)}>
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
                <br />
            </Stack>
        }
    }

    return (
        <div className="Expect">
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    sx={{
                        width: { md: `calc(100% - ${drawerWidth}px)` },
                        ml: { md: `${drawerWidth}px` },
                        //marginTop: '40px'
                    }}
                    style={{ background: 'transparent', boxShadow: 'none' }}
                >
                    {/*  <Toolbar >
                        <IconButton
                            color={data.colorfont}
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { md: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                */}
                </AppBar>
                <Box
                    component="nav"
                    sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
                >
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. 
                    <Drawer
                        variant="temporary"
                        open={mobileOpen}
                        onTransitionEnd={handleDrawerTransitionEnd}
                        onClose={handleDrawerClose}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawerH}
                    </Drawer>
                    */}
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', md: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                    >
                        {drawer}
                    </Drawer>
                </Box>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, width: { md: `calc(100% - ${drawerWidth}px)` } }}
                >
                    <HorizontalBar isMdAndSmaller={isMdAndSmaller} />
                    <br />
                    {switchReturn()}
                </Box>
            </Box>
        </div >
    )
}


/*
<Stepper nonLinear activeStep={activeStep} orientation="vertical">
                    {stepdescr.map((label, index) => (
                        <Step key={label} completed={completed[index]}
                            sx={{
                                '& .MuiStepLabel-root .Mui-completed': {
                                    color: data.colorfont, // circle color (COMPLETED)
                                },
                                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel':
                                {
                                    color: 'grey.500', // Just text label (COMPLETED)
                                },
                                '& .MuiStepLabel-root .Mui-active': {
                                    color: data.colorfont, // circle color (ACTIVE)
                                },
                                '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                {
                                    color: data.colorfont, // Just text label (ACTIVE)
                                },
                                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                    fill: 'white', // circle's number (ACTIVE)
                                },
                            }}>
                            <StepButton color="inherit" onClick={handleStep(index)}>
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
*/