import React, { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';


function DropDownIndustry({ industry, setIndustry, industryError, setIndustryError
        , pricingTable, realEstate, loanUse, data, setData, prequalToggleCheck }) {

  const theme = useTheme();

  function formatAmount(value) {
    if (!value) return value;
    //console.log(value)
    const amountValue = value.toString().replace(/[^\d]/g, '');
    let amountValueTrim = amountValue.length >= 8 ? amountValue.substring(0, 7) : amountValue;
    const amountValueTrimLength = amountValueTrim.length;
    if (amountValueTrimLength < 4) return `$${amountValueTrim}`;
    if (amountValueTrimLength < 7) return `$${amountValueTrim.substring(0, amountValueTrim.length - 3)},${amountValueTrim.substring(amountValueTrim.length - 3, amountValueTrim.length)}`;
    if (amountValueTrimLength < 8) return `$${amountValueTrim.substring(0, amountValueTrim.length - 6)},${amountValueTrim.substring(amountValueTrim.length - 6, amountValueTrim.length - 3)},${amountValueTrim.substring(amountValueTrim.length - 3, amountValueTrim.length)}`;
  }

  const roundToNearest = (number, roundingBase) => {
    return Math.round(number / roundingBase) * roundingBase;
  };

  const findItemByKey = (key) => {
    return pricingTable.find(item => item.ReactKey === key);
  };

  useEffect(() => {
    if (pricingTable.length === 0) {
      return; // Exit early if pricingTable is empty
    }

    const combinedKey = industry + '_' + loanUse + '_' + realEstate;
    //console.log('combinedKey : ', combinedKey)
    setData((s) => ({
      ...s,
      reactKey: combinedKey
    }))
    const foundItem = findItemByKey(combinedKey);
    if (!foundItem) {
      console.warn('Item not found');
      return; // Exit early if no item is found
    }


    //console.log('Found Item:', foundItem); // Debug output

    // Calculate values
    const intcalc = (foundItem.Prime + foundItem.Margin) / 12;
    const termcalc = foundItem.Term * 12;
    const calc1 = (1 + intcalc) ** termcalc;
    const amt = data.amtInteract ? 
                  data.amount < foundItem.MinVal ? 
                    foundItem.MinVal :
                    data.amount > foundItem.MaxVal ?
                      foundItem.MaxVal :
                      data.amount
                  : roundToNearest((foundItem.MinVal + foundItem.MaxVal) / 2, 100000);
    const pmtcalc = amt * (intcalc * calc1) / (calc1 - 1);

    
    setData((s) => ({
      ...s,
      amount: amt,
      amountformatted: formatAmount(amt),
      interest: foundItem.Prime + foundItem.Margin,
      prime: foundItem.Prime,
      margin: foundItem.Margin,
      term: foundItem.Term,
      payment: pmtcalc,
      minamount: foundItem.MinVal,
      maxamount: foundItem.MaxVal,
      minCredit: foundItem.MinCredit,
      
    }))
    
  }, [industry, loanUse, realEstate, pricingTable, prequalToggleCheck]);

  const handleIndustry = (event) => {
    setIndustry(event.target.value);
    event.target.value == 0 ? setIndustryError(true) : setIndustryError(false)
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }} >
      <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
        <Typography style={{ color: industryError? theme.palette.secondary.main : theme.palette.primary.dark, fontWeight: 600, marginLeft: 5 }} >Industry *</Typography>
        <Select
          value={industry}
          onChange={handleIndustry}
          onBlur={() => {
            industry == 0 ? setIndustryError(true) : setIndustryError(false)
            }}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          error={industryError}
          sx={{
            backgroundColor: 'white', // Change this to your desired background color
            width: 'auto', // Allow the dropdown to size based on content
            minWidth: 200,
            maxWidth: '100%',
            margin: '0 auto', // Center the dropdown
            //overflow: 'hidden', // Prevent overflow
            //textOverflow: 'ellipsis', // Show ellipses for overflow text
            '& .MuiSelect-select': {
              backgroundColor: 'white', // Ensure the selected item background color matches
              color: 'black', // Optional: Set text color
              boxShadow: 1,
              border: 'none'
            },
            '& .MuiMenuItem-root': {
              backgroundColor: 'white', // Optional: Set background color for menu items
              '&:hover': {
                backgroundColor: 'darkgray', // Optional: Set hover color for menu items
              },
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 340, // Adjust this value as needed
                overflow: 'auto',
                backgroundColor: 'white', // Optional: Ensure background color matches
                '& .MuiMenuItem-root': {
                  backgroundColor: 'white', // Optional: Set background color for menu items
                  '&.Mui-selected': {
                    backgroundColor: theme.palette.action.hover, // Highlight color for selected item
                  },
                  '&:hover': {
                    backgroundColor: 'darkgray', // Optional: Set hover color for menu items
                  },
                },
              },
            },
          }}
        >
          <MenuItem value={'0'}>&nbsp;</MenuItem>
          <MenuItem value={'1'}>Automotive</MenuItem>
          <MenuItem value={'2'}>Business Services</MenuItem>
          <MenuItem value={'3'}>Car Wash</MenuItem>
          <MenuItem value={'4'}>Child Care</MenuItem>
          <MenuItem value={'5'}>Chiropractors</MenuItem>
          <MenuItem value={'6'}>CPA's & Tax Preparation</MenuItem>
          <MenuItem value={'7'}>Dentist</MenuItem>
          <MenuItem value={'8'}>Elderly Care</MenuItem>
          <MenuItem value={'9'}>Engineering</MenuItem>
          <MenuItem value={'10'}>Fitness Centers</MenuItem>
          <MenuItem value={'11'}>Funeral Home</MenuItem>
          <MenuItem value={'12'}>Gas Stations</MenuItem>
          <MenuItem value={'13'}>Hotels & Motels</MenuItem>
          <MenuItem value={'14'}>Insurance</MenuItem>
          <MenuItem value={'15'}>Investment Advice</MenuItem>
          <MenuItem value={'16'}>Landscaping</MenuItem>
          <MenuItem value={'17'}>Lawyers</MenuItem>
          <MenuItem value={'18'}>Liquor Stores</MenuItem>
          <MenuItem value={'19'}>Medical Providers</MenuItem>
          <MenuItem value={'20'}>Personal Services</MenuItem>
          <MenuItem value={'21'}>Pet Care</MenuItem>
          <MenuItem value={'22'}>Pharmacies</MenuItem>
          <MenuItem value={'23'}>Restaurants (Full-Service)</MenuItem>
          <MenuItem value={'24'}>Restaurants (Limited-Service)</MenuItem>
          <MenuItem value={'25'}>Retail Stores</MenuItem>
          <MenuItem value={'26'}>Self Storage</MenuItem>
          <MenuItem value={'27'}>Specialty Trades (HVAC, Plumbing, Electrician)</MenuItem>
          <MenuItem value={'28'}>Trucking</MenuItem>
          <MenuItem value={'29'}>Veterinarian</MenuItem>
          <MenuItem value={'20'}>Other</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

export default DropDownIndustry;
