import Box from '@mui/material/Box';
import { Container, Typography } from '@mui/material/';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useEffect } from "react";

export default function InputDate(props) {


    
    return (
        <Container maxWidth={false}>

<Box
                style={{ width: '100%' }}
                //component="form"
                sx={{
                    width: '100%',
                    '& .MuiTextField-root': { m: 2, width: '100%', height: '08%' },
                    '& .MuiInputBase-input': {paddingTop: '0px', paddingBottom: '0px', height: '43px'} 
                    //MuiFilledInput-input MuiInputBase-inputAdornedEnd css-1gctnaj-MuiInputBase-input-MuiFilledInput-input" value="MM/DD/YYYY">
                }}
                noValidate
                //autoComplete="off"
                alignItems="center"
                display="flex"
            >
                <div style={{ width: '100%' }}> {/* Set width to 100% for the nested div */}
                <Typography fontSize={16} sx={{ fontWeight: 700, color: props.color, marginLeft: 2, marginTop: 1 }}>
                {props.headingText}
                <span style={props.headingStyle}>{props.headingSuffix}</span>
            </Typography>
            <Typography fontSize={12} color="gray" sx={{ fontWeight: 500, marginBottom: -2, marginLeft: 2 }}>
                {props.subheading}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}
                label="Responsive variant"
            >
                {/* label options are "Responsive variant"
                                                    , "Desktop variant"
                                                    , "Mobile variant"*/}
                <DatePicker
                    slotProps={{
                        openPickerButton: {},
                        textField: {
                            variant: "filled",
                            InputProps: {
                                disableUnderline: true,
                                style: {  paddingTop: '0px', paddingBottom: '0px', marginTop: '0px' },
                            },
                            required: true,
                            error: props.error,
                            onBlur: props.onBlur
                        },
                        
                    }}
                    value={props.value}
                    onChange={props.onChange}
                    sx={{
                        '& .MuiInputLabel-root': {
                            '&.Mui-focused': {
                                color: props.color, // Change label color when focused
                            },
                        },
                        '& .MuiFilledInput-root': {
                            backgroundColor: props.error ? '#F6DDDD' : '', // Change filled background color on error
                        },
                        '& .MuiFilledInput-underline:before': {
                            borderBottomColor: props.color, // Change underline color when focused
                        },
                        '& .MuiFilledInput-underline:after': {
                            borderBottomColor: props.error ? '#F6DDDD' : props.color, // Change underline color on error
                        },
                    }}
                />
            </LocalizationProvider>

        </div>
    </Box>
        </Container>
    )
}


/*



*/