import * as React from 'react';
import { Grid, Box, Card, Typography, Stack } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Grow from '@mui/material/Grow';
import LoanEligibilityGrow1 from './LoanEligibilityGrow1';
import LoanEligibilityGrow2 from './LoanEligibilityGrow2';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';



export default function LoanEligibility() {

    const theme = useTheme();
    const theme2 = createTheme();
    const isSm = useMediaQuery(theme2.breakpoints.down('md'));

    return (
        <div style={{ marginTop: '0rem' }}>

            <Card style={{ color: 'white', backgroundColor: theme.palette.secondary.contrastText, boxShadow: 'none', padding: 30, marginTop: 0, height: 'auto' }}>
                <br/>
                <Typography sx={{ fontSize: { xs: 24, sm: 28, md: 36, lg: 44 }, marginX: { xs: 4, sm: 6, md: 10, lg: 15 } }} fontWeight={600} color={theme.palette.primary.dark} align="center" >Am I Eligible?</Typography>
                <br />
                <Typography sx={{ fontSize: { xs: 16, sm: 16, md: 20, lg: 24 }, marginX: { xs: 4, sm: 6, md: 10, lg: 15 } }} fontWeight={600} color={theme.palette.secondary.light} align="center" marginLeft={20} marginRight={20}>
                These aren’t all the rules, but they cover the most common reasons a business might not qualify for SBA 7(a) financing with us.
                </Typography>
                <br/>
                <Grid container
                    justifyContent="center"  // Center the grid horizontally
                >
                    <Box
                        sx={{
                            boxShadow: 0,
                            width: '60rem',
                            //height: '20rem',
                            //bgcolor: 'white',
                            color: theme.palette.secondary.light,       //grey
                            p: 1,
                            m: 1,
                            //borderRadius: 2,
                            textAlign: 'center',
                            fontSize: '20px',
                            fontWeight: '700',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            //gridTemplateColumns: 'repeat(2, 1fr)',
                            gridColumnGap: '500px', // Adjust the gap between columns
                        }}
                    >
                        <Box >
                            <Stack
                                sx={{
                                    [theme.breakpoints.up('md')]: {
                                      flexDirection: 'column', // Stack vertically on medium screens and up
                                    },
                                    [theme.breakpoints.up('lg')]: {
                                      flexDirection: 'row', // Stack horizontally on large screens and up
                                    },
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                            >
                                {isSm ? <LoanEligibilityGrow1 /> 
                                    : <LoanEligibilityGrow2 />}
                            </Stack>
                        </Box>

                    </Box>
                </Grid>
            </Card>
        </div >
    );
}




{/*  <Grow in={showIcon} timeout={1000}>
                                    <div ref={iconRef}>
                                        <Item>
                                            <Box sx={{ display: 'flex', alignItems: 'center', margin: 2 }}>
                                                <TaskAltIcon sx={{ marginRight: '10px', color: theme.palette.primary.main }} /> {/* Adjust margin as needed 
                                                <Box sx={{
                                                    width: 300,
                                                    borderBottom: 1,
                                                    borderColor: alpha(theme.palette.secondary.light, 0.8)  //grey
                                                }}>
                                                    <Typography fontSize={16} fontWeight={550} color={theme.palette.secondary.light} align='left' >
                                                        No owner defaults on a federally-backed loan?
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', margin: 2 }}>
                                                <TaskAltIcon sx={{ marginRight: '10px', color: theme.palette.primary.main }} /> {/* Adjust margin as needed 
                                                <Box sx={{
                                                    width: 300,
                                                    borderBottom: 1,
                                                    borderColor: alpha(theme.palette.secondary.light, 0.8)  //grey
                                                }}>
                                                    <Typography fontSize={16} fontWeight={550} color={theme.palette.secondary.light} align='left' >
                                                        No owners filed bankruptcy in the last 7 years or presently going through bankruptcy?
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', margin: 2 }}>
                                                <TaskAltIcon sx={{ marginRight: '10px', color: theme.palette.primary.main }} /> {/* Adjust margin as needed 
                                                <Box sx={{
                                                    width: 300,
                                                    borderBottom: 1,
                                                    borderColor: alpha(theme.palette.secondary.light, 0.8)  //grey
                                                }}>
                                                    <Typography fontSize={16} fontWeight={550} color={theme.palette.secondary.light} align='left' >
                                                        No owners been incarcerated in the last 7 years or currently incarcerated?
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', margin: 2 }}>
                                                <TaskAltIcon sx={{ marginRight: '10px', color: theme.palette.primary.main }} /> {/* Adjust margin as needed 
                                                <Box sx={{
                                                    width: 300,
                                                    borderBottom: 1,
                                                    borderColor: alpha(theme.palette.secondary.light, 0.8)  //grey
                                                }}>
                                                    <Typography fontSize={16} fontWeight={550} color={theme.palette.secondary.light} align='left' >
                                                        The business is not involved in the cannabis, gambling, or adult entertainment industries?
                                                    </Typography>
                                                </Box>
                                            </Box>


                                        </Item>
                                    </div>
                                </Grow> */}