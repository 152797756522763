
import * as React from 'react';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Grow, Box, Stack } from '@mui/material';
import HoverBox from './HoverBox';
import PopUpBox from './PopUpBox';
import { alpha } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

function Item(props) {

    const { sx, ...other } = props;

    return (
        <Box
            sx={{
                bgcolor: 'white',
                //border: 2,
                //borderColor: theme.palette.primary.main,
                boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2)',
                height: 'auto',
                borderRadius: 2,
                p: 2,
                paddingBottom: 3,
                m: 1,
                //fontSize: '0.875rem',
                //fontWeight: '700',
                ...sx,
            }}
            {...other}
        />
    );
}

Item.propTypes = {
    /**
     * The system prop that allows defining system overrides as well as additional CSS styles.
     */
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
        ),
        PropTypes.func,
        PropTypes.object,
    ]),
};



export default function LoanEligibilityGrow1() {
    const theme = useTheme();
    const iconRef = React.useRef(null);
    const [showIcon, setShowIcon] = React.useState(false);

    React.useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // When the icon enters OR EXITS the viewport
                /* setShowIcon(entry.isIntersecting); */

                // When the icon enters the viewport
                if (entry.isIntersecting) {
                    setShowIcon(true);
                    observer.disconnect(); // Disconnect the observer once shown
                }
            },
            { threshold: 0 } // Intersection threshold
        );

        if (iconRef.current) {
            observer.observe(iconRef.current);
        }

        return () => observer.disconnect(); // Clean up on unmount
    }, []);

    return (
        <Box>
            <Stack
                sx={{
                    [theme.breakpoints.up('sm')]: {
                        flexDirection: 'column',
                    },
                    [theme.breakpoints.up('md')]: {
                        flexDirection: 'row',
                    },
                    justifyContent: 'center',
                    alignItems: 'center',
                    spacing: 2,
                }}
            >
                <Grow in={showIcon} timeout={1000}>
                    <div ref={iconRef}>
                        <Item>
                            <HoverBox
                                icon={<TaskAltIcon sx={{ color: theme.palette.primary.main }} />}
                                text="The business is a successful for-profit company that can easily make the loan payment being considered."
                                borderBottom={0}
                                descr='Content of the Popover longer description for testing'
                            />
                            <HoverBox
                                icon={<TaskAltIcon sx={{ color: theme.palette.primary.main }} />}
                                text="All owners have good personal credit."
                                borderBottom={0}
                                descr='Content of the Popover'
                            />
                            <HoverBox
                                icon={<TaskAltIcon sx={{ color: theme.palette.primary.main }} />}
                                text="The business has less than 500 employees."
                                borderBottom={0}
                                descr='Content of the Popover'
                            />
                            <HoverBox
                                icon={<TaskAltIcon sx={{ color: theme.palette.primary.main }} />}
                                text="All owners are U.S. citizens or permanent residents."
                                borderBottom={0}
                                descr='Content of the Popover'
                            />
                            <HoverBox
                                icon={<TaskAltIcon sx={{ color: theme.palette.primary.main }} />}
                                text="All taxes for the business and owners are filed and paid."
                                borderBottom={0}
                                descr='Content of the Popover'
                            />
                            <HoverBox
                                icon={<TaskAltIcon sx={{ color: theme.palette.primary.main }} />}
                                text="None of the owners or the business have recently missed a loan payment."
                                borderBottom={0}
                                descr='Content of the Popover'
                            />
                            <HoverBox
                                icon={<TaskAltIcon sx={{ color: theme.palette.primary.main }} />}
                                text="No owner has defaulted on a federally-backed loan."
                                borderBottom={0}
                                descr='Content of the Popover'
                            />
                            <HoverBox
                                icon={<TaskAltIcon sx={{ color: theme.palette.primary.main }} />}
                                text="No owner has filed for bankruptcy in the last 7 years or is currently in bankruptcy."
                                borderBottom={0}
                                descr='Content of the Popover'
                            />
                            <HoverBox
                                icon={<TaskAltIcon sx={{ color: theme.palette.primary.main }} />}
                                text="No owner has been incarcerated in the last 7 years or is currently in jail."
                                borderBottom={0}
                                descr='Content of the Popover'
                            />
                            <HoverBox
                                icon={<TaskAltIcon sx={{ color: theme.palette.primary.main }} />}
                                text="The business is not involved in cannabis, gambling, or adult entertainment."
                                borderBottom={0}
                                descr='Content of the Popover'
                            />
                        </Item>
                    </div>
                </Grow>
            </Stack>
        </Box>
    )
}