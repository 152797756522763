import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';

function StepperComp({ data, activeStep, stepdescr, completed, handleStep, steps, }) {


    //console.log('steps: ',steps)

    return (
        <Stepper nonLinear activeStep={activeStep} orientation="vertical">
            {stepdescr.map((label, index) => (
                <Step key={label} completed={steps[index]}
                    sx={{
                        '& .MuiStepLabel-root .Mui-completed': {
                            color: 'rgba(0, 0, 0, 0.38)', // circle color (COMPLETED)
                        },
                        '& .MuiStepLabel-labelContainer .Mui-completed':
                        {
                            color: '#757575', // Just text label (COMPLETED)
                        },
                        '& .MuiStepLabel-root .Mui-active': {
                            color: data.colorfont, // circle color (ACTIVE)
                        },
                        '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                        {
                            color: data.colorfont, // Just text label (ACTIVE)
                        },
                        '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                            fill: 'white', // circle's number (ACTIVE)
                        },
                        '& .MuiStepIcon-text': {
                            // Number inside the circle
                            fill: 'white', // White for both active and inactive
                        },
                    }}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                        {label}
                    </StepButton>
                </Step>
            ))}
        </Stepper>
    )
}

export default StepperComp