import * as React from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import CheckIcon from '@mui/icons-material/Check';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { Stack, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Button, Typography, Grid } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Helmet } from 'react-helmet-async'
import { useTheme } from '@mui/material/styles';
import { theme } from '../theme';


function createData(category, bolt, small, core) {
    return { category, bolt, small, core };
}

const rows1 = [
    createData(<b>Loans Up To</b>, <b>$150,000</b>, <b>$5,000,000</b>),
    /* createData('Credit Score', '700+', '650+'), */
    createData('Business Age', '2+ years', 'Any'),
    createData('Funding Time', 'Days', 'Weeks'),
];

const rows2 = [
    createData('Business Acquisitions', '', <CheckIcon style={{ color: theme.palette.primary.red }} />),
    createData('Commercial Real Estate', '', <CheckIcon style={{ color: theme.palette.primary.red }} />),
    /* createData('Construction', '', <CheckIcon style={{ color: theme.palette.primary.red }} />), */
    createData('Equipment Purchase', <CheckIcon style={{ color: theme.palette.primary.red }} />, <CheckIcon style={{ color: theme.palette.primary.red }} />),
    createData('Refinance Business Debt', <CheckIcon style={{ color: theme.palette.primary.red }} />, <CheckIcon style={{ color: theme.palette.primary.red }} />),
    createData('Start a New Business', '', <CheckIcon style={{ color: theme.palette.primary.red }} />),
    createData('Working Capital', <CheckIcon style={{ color: theme.palette.primary.red }} />, <CheckIcon style={{ color: theme.palette.primary.red }} />),
    createData('Other', '', <CheckIcon style={{ color: theme.palette.primary.red }} />),
];

export default function LoanTypeTable({ data, setData, industry, loanUse, express, setExpress, setRealEstate
    , pricingTable
}) {

    const theme = useTheme();
    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);

    function ExpressButton() {
        return (
            <Stack spacing={2}
                direction="row"
                alignItems='center'
                justifyContent='center'
                margin={1}>
                <a href='./prequalify/express' //onClick={handleExpressClick}
                    >
                    <Button variant="contained"
                        loading={loading}
                        sx={{
                            fontSize: fontSizeText, fontWeight: 600, color: 'white', backgroundColor: data.colorfont,
                            //padding: 1,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.red,
                            }
                        }}
                    >
                        <span>Start</span>
                    </Button>
                </a>
            </Stack>
        )
    }


    const handleExpressClick = (event) => {
        setExpress(true)
        event.preventDefault();
    }



    function StandardButton() {
        return (
            <Stack spacing={2}
                direction="row"
                alignItems='center'
                justifyContent='center'
                margin={1}>
                <a href='./prequalify/standard' //onClick={handleStandardClick}
                    >
                    <Button variant="contained"
                        loading={loading}
                        sx={{
                            fontSize: fontSizeText, fontWeight: 600, color: 'white', backgroundColor: data.colorfont,
                            //padding: 0.25,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.red,
                            }
                        }}
                    >
                        <span>Start</span>
                    </Button>
                </a>
            </Stack>
        )
    }

    const handleStandardClick = (event) => {
        setExpress(false)
        //event.preventDefault();
    }





    const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'white',
            color: theme.palette.primary.dark,
            fontWeight: 700,
            margin: 0,
            borderBottom: "none",
            textAlign: 'center',
            verticalAlign: 'bottom',
            position: 'relative', // Necessary for absolute positioning of the line
        },
        [`&.${tableCellClasses.head} span`]: {
            borderBottom: `2px solid #00461c`, // Change color and style as needed
            paddingBottom: '2px', // Adjust as needed to position the line correctly
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: fontSizeText,
            margin: 0,
            paddingX: '0px'
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const matchesXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const matchesSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const matchesMD = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const matchesLG = useMediaQuery((theme) => theme.breakpoints.down('lg'));
    const matchesXL = useMediaQuery((theme) => theme.breakpoints.down('xl'));
    const matchesXL2 = useMediaQuery((theme) => theme.breakpoints.up('xl'));

    //console.log('matchesXS: ',matchesXS)
    //console.log('matchesSM: ',matchesSM)
    //console.log('matchesMD: ',matchesMD)
    //console.log('matchesLG: ',matchesLG)
    //console.log('matchesXL: ',matchesXL)
    //console.log('matchesXL2: ',matchesXL2)

    let cellWidth1 = '250px'; // default width - xl screen
    let cellWidth2 = '125px'; // default width - xl screen
    let tableWidth = 'max-content'; // default width - xl screen
    let fontSizeHeader = 20; //default size - xl screen
    let fontSizeText = 18; //default size - xl screen

    if (matchesXS) {
        // Adjust width for XS breakpoint
        cellWidth1 = '40%';
        cellWidth2 = '20%';
        tableWidth = '100%';
        fontSizeHeader = 14;
        fontSizeText = 14;
    } else if (matchesSM) {
        // Adjust width for SM breakpoint
        cellWidth1 = '150px';
        cellWidth2 = '100px';
        tableWidth = 'max-content';
        fontSizeHeader = 16;
        fontSizeText = 16;
    } else if (matchesMD) {
        // Adjust width for MD breakpoint
        cellWidth1 = '200px';
        cellWidth2 = '125px';
        tableWidth = 'max-content';
        fontSizeHeader = 18;
        fontSizeText = 18;
    } else if (matchesLG) {
        // Adjust width for LG breakpoint
        cellWidth1 = '225px';
        cellWidth2 = '125px';
        tableWidth = 'max-content';
        fontSizeHeader = 20;
        fontSizeText = 18;
    } else if (matchesXL) {
        // Adjust width for LG breakpoint
        cellWidth1 = '225px';
        cellWidth2 = '125px';
        tableWidth = 'max-content';
        fontSizeHeader = 20;
        fontSizeText = 18;
    } else if (matchesXL2) {
        // Adjust width for LG breakpoint
        cellWidth1 = '225px';
        cellWidth2 = '125px';
        tableWidth = 'max-content';
        fontSizeHeader = 24;
        fontSizeText = 18;
    }


    return (
        <div style={{ marginTop: '150px' }} className="Prequalify">
            <Helmet>
                <title>CapTec USA | Prequalify</title>
                <meta name="description" content="5 minute SBA prequalification 24/7. Fully online process on your time. Our initial prequalification is the first step in securing your small business funding." />
                <link rel="canonical" href="https://thenewsouthbank.com/prequalify" />
            </Helmet>
            <div style={{ display: 'flex', justifyContent: 'center', padding: 1 }}>
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <Grid container spacing={{ xs: 2, md: 3 }} sx={{ justifyContent: 'center' }} >
                        <Stack
                            direction="column"
                            spacing={2}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            <Grid item xs={11} sm={10} md={8} lg={6} >
                                <Typography fontWeight={600}
                                    sx={{
                                        fontSize: { xs: 30, sm: 40, md: 50, lg: 60 },
                                        color: theme.palette.primary.dark,
                                        mt: {xs: -4, sm: -2, md: 0, lg: 0 }
                                    }} >
                                    Get Prequalified
                                </Typography>
                            </Grid>
                            <Grid item xs={11} sm={10} md={8} lg={6}>
                                <Typography fontWeight={600}
                                    sx={{
                                        fontSize: { xs: 18, sm: 18, md: 20, lg: 24 },
                                        color: theme.palette.secondary.light
                                    }} >
                                    Our two SBA Programs are tailored to meet your specific needs, ensuring you find the ideal small business financing solution to match your goals and timeline.
                                </Typography>
                            </Grid>
                        </Stack>
                    </Grid>
                    <TableContainer sx={{ width: tableWidth, padding: '0 20px' }}>
                        <Table aria-label="table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: cellWidth1, padding: '6px', borderBottom: "none" }}></TableCell>
                                    <StyledTableCellHead style={{ width: cellWidth2, padding: '6px', borderBottom: "none", lineHeight: '1.2', fontSize: fontSizeHeader }} align="center"><span>Express</span></StyledTableCellHead>
                                    <StyledTableCellHead style={{ width: cellWidth2, padding: '6px', borderBottom: "none", lineHeight: '1.2', fontSize: fontSizeHeader }} align="center"><span>Standard</span></StyledTableCellHead>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* This is a blank tiny row to add some buffer from headers which are forced to the bottom so they align given the stacked Small Business */}
                                <TableRow>
                                    <TableCell style={{ whiteSpace: 'normal', maxHeight: '4px', padding: '0', borderBottom: "none" }} align="center"></TableCell>
                                </TableRow>
                                {rows1.map((row) => (
                                    <TableRow
                                        key={row.category}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, borderBottom: "none" }}
                                    >
                                        <TableCell component="th" scope="row" style={{ whiteSpace: 'normal', maxWidth: cellWidth1, padding: '6px 0', borderBottom: "none", fontSize: fontSizeText }}>
                                            {row.category}
                                        </TableCell>
                                        <TableCell style={{ whiteSpace: 'normal', maxWidth: cellWidth2, padding: '6px 0', borderBottom: "none", fontSize: fontSizeText }} align="center">{row.bolt}</TableCell>
                                        <TableCell style={{ whiteSpace: 'normal', maxWidth: cellWidth2, padding: '6px 0', borderBottom: "none", fontSize: fontSizeText }} align="center">{row.small}</TableCell>
                                    </TableRow>
                                ))}
                                {/* This is a blank tiny row to add some buffer before Uses */}
                                <TableRow>
                                    <TableCell style={{ whiteSpace: 'normal', maxHeight: '40px', padding: '5px', borderBottom: "none" }} align="center"></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{ whiteSpace: 'normal', maxWidth: cellWidth1, padding: '6px 0', borderBottom: "none", fontSize: fontSizeText }} ><b>Uses:</b></TableCell>
                                </TableRow>
                                {rows2.map((row) => (
                                    <StyledTableRow
                                        key={row.category}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, borderBottom: "none" }}
                                    >
                                        <TableCell component="th" scope="row" style={{ whiteSpace: 'normal', maxWidth: cellWidth1, padding: '4px 0px 4px 18px', borderBottom: "none", fontSize: fontSizeText }}>
                                            {row.category}
                                        </TableCell>
                                        <TableCell style={{ whiteSpace: 'normal', maxWidth: cellWidth2, padding: '4px 0', borderBottom: "none" }} align="center">{row.bolt}</TableCell>
                                        <TableCell style={{ whiteSpace: 'normal', maxWidth: cellWidth2, padding: '4px 0', borderBottom: "none" }} align="center">{row.small}</TableCell>
                                    </StyledTableRow>
                                ))}
                                <TableRow >
                                    <TableCell style={{ whiteSpace: 'normal', maxWidth: cellWidth2, padding: '4px 0', borderBottom: "none" }} align="center" sx={{ borderBottom: "none" }} ></TableCell>
                                    <TableCell style={{ whiteSpace: 'normal', maxWidth: cellWidth2, padding: '4px 0', borderBottom: "none" }} align="center" sx={{ borderBottom: "none" }} > <ExpressButton data={data} /> </TableCell>
                                    <TableCell style={{ whiteSpace: 'normal', maxWidth: cellWidth2, padding: '4px 0', borderBottom: "none" }} align="center" sx={{ borderBottom: "none" }} > <StandardButton data={data} /> </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            </div>
            <br />
            <br />
            <br />
        </div>
    );
}
