import * as React from 'react';
import { Box, Typography, Popper, Paper, MenuItem, Menu, Fade, ClickAwayListener } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function UseMenu() {
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [loanOpen, setLoanOpen] = React.useState(false);
    const [loanAnchorEl, setLoanAnchorEl] = React.useState(null);

    const navigate = useNavigate()

    const handleToggle = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setOpen((prevOpen) => !prevOpen);
        setLoanOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const handleLoanToggle = (event) => {
        setLoanAnchorEl(loanAnchorEl ? null : event.currentTarget);
        setLoanOpen((prevLoanOpen) => !prevLoanOpen);
        setOpen(true);
        setAnchorEl(null);
    };

    const handleLoanClose = () => {
        setLoanOpen(false);
        setLoanAnchorEl(null);
    };


    const expressClick = () => {
        navigate('/sbaexpress')
        handleClose();
    };

    const sbaClick = () => {
        navigate('/sba7a')
        handleClose();
    };

    const loanClick = (event) => {
        setLoanAnchorEl(loanAnchorEl ? null : event.currentTarget);
        setLoanOpen((prevloanOpen) => !prevloanOpen);
    };

    const whysbaClick = () => {
        navigate('/sbaexplained')
        handleClose();
    };

    const expectClick = () => {
        navigate('/process')
        handleClose();
    };

    const calcClick = () => {
        navigate('/calculator')
        handleClose();
    };

    const faqClick = () => {
        navigate('/faq')
        handleClose();
    };
    const learningClick = () => {
        navigate('/learning')
        handleClose();
    };
    const industryClick = () => {
        navigate('/industry')
        handleClose();
    };


    return (
        <>
            <MenuItem onClick={handleToggle}
                sx={{
                    display: 'flex', // Use flexbox for alignment
                    alignItems: 'center', // Center items vertically
                    width: 'auto', // Let it grow as wide as needed
                    minHeight: '41px', // Ensure minimum height
                    height: '41px', // Explicit height
                    maxWidth: '60px', // Max width
                    padding: 0, // Remove padding if it affects height
                    '&:hover': {
                        background: 'transparent',
                    },
                }}>
                <MenuIcon />
            </MenuItem >

            <Popper
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
                transition
                disablePortal // Adjust as needed
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <div>
                                    <MenuItem onClick={handleLoanToggle} 
                                        sx={{ fontSize: { xs: 16, sm: 18, md: 20 } }}>
                                        Loan Programs
                                        {loanOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </MenuItem>
                                    {loanOpen ?
                                        <Box sx={{ marginLeft: '15px' }}>
                                            <MenuItem onClick={expressClick}
                                                sx={{ fontSize: { xs: 16, sm: 18, md: 20 } }}>
                                                SBA Express
                                            </MenuItem>
                                        </Box>
                                        : <></>
                                    }
                                    {loanOpen ?
                                        <Box sx={{ marginLeft: '15px' }}>
                                            <MenuItem onClick={sbaClick}
                                                sx={{ fontSize: { xs: 16, sm: 18, md: 20 } }}>
                                                SBA 7(a)
                                            </MenuItem>
                                        </Box>
                                        : <></>
                                    }
                                    <MenuItem onClick={whysbaClick}
                                        sx={{ fontSize: { xs: 16, sm: 18, md: 20 } }}>
                                        SBA 7(a) Explained
                                    </MenuItem>
                                    <MenuItem onClick={expectClick}
                                        sx={{ fontSize: { xs: 16, sm: 18, md: 20 } }}>
                                        Our Process
                                    </MenuItem>
                                    <MenuItem onClick={industryClick}
                                        sx={{ fontSize: { xs: 16, sm: 18, md: 20 } }}>
                                        Industry Resources
                                    </MenuItem>
                                    <MenuItem onClick={faqClick}
                                        sx={{ fontSize: { xs: 16, sm: 18, md: 20 } }}>
                                        FAQs
                                    </MenuItem>
                                    <MenuItem onClick={learningClick}
                                        sx={{ fontSize: { xs: 16, sm: 18, md: 20 } }}>
                                        Learning Center
                                    </MenuItem>
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </>
    );
}

