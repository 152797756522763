import * as React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Stack, Typography, Box, useMediaQuery } from '@mui/material';
import ExpectPanel from './ExpectPanel'
import prequalify from '../images/prequalify2.png'
import apply from '../images/apply.png'
import underwriting from '../images/underwriting.png'
import approval from '../images/approval.png'
import sba from '../images/sba2.png'
import funding from '../images/funding.png'
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async'


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            width='50%'
            {...other}
        >
            {value === index && (
                <Box sx={{ paddingLeft: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function VerticalTabs() {
    const [value, setValue] = React.useState(0);
    const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div style={{ marginTop: '40px', marginLeft: '50px' }} className="Expect">
            <Helmet>
                <title>CapTec USA | What to Expect</title>
                <meta name="description" content="Learn what to expect through the SBA loan process. From prequestionnaire to funding, we'll break it down." />
                <link rel="canonical" href="https://thenewsouthbank.com/expect" />
            </Helmet>
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >

                <Box sx={{
                    width: '70vw',
                    height: '5rem',
                    borderBottom: 1,
                    textAlign: 'left',
                    m: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                }}>
                    <Typography fontSize={30} fontWeight={500} color={theme.palette.primary.main}    //blue
                    //style={{ paddingBottom: '1rem' }} {/* Adjust padding to control distance from bottom 
                    >
                        What to Expect
                    </Typography>
                </Box>

                <br />
                <Typography sx={{
                    fontSize: { xs: 18, sm: 18, md: 20, lg: 22 },
                    paddingRight: { xs: '50px', sm: '80px', md: '100px', lg: '300px' }
                }}
                    fontWeight={500}
                    color={'secondary.light'}    //blue

                //style={{ paddingBottom: '1rem' }} {/* Adjust padding to control distance from bottom 
                >
                    Let's break it down. Here's a comprehensive overview of what you can expect as you navigate through the SBA 7(a) loan process, from the initial stages of prequalification all the way through to securing funding.
                </Typography>
                <br />
                <br />
                <Box sx={{
                    width: { xs: '80%', sm: '70%' },
                    display: 'flex',
                }}>
                    <Box sx={{ minWidth: {xs: '125px', sm: '150px'}, borderRight: 1, borderColor: 'divider', marginLeft: {xs: '-32px', sm:'0px'} }}>
                        <Tabs
                            orientation="vertical"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{ width: '100%' }}
                        >
                            <Tab label="Prequalify" {...a11yProps(0)} />
                            <Tab label="Apply" {...a11yProps(1)} />
                            <Tab label="Underwriting" {...a11yProps(2)} />
                            <Tab label="Approval" {...a11yProps(3)} />
                            <Tab label="Closing" {...a11yProps(4)} />
                            <Tab label="Funding" {...a11yProps(5)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <ExpectPanel image={prequalify}
                            timing='TIMING: 5 MINUTES'
                            descr='Complete our simple online prequalification for either an SBA Express or SBA 7(a) loan.'
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ExpectPanel image={apply}
                            timing='TIMING: 15 MINUTES'
                            descr='Upon prequalification, you&apos;ll recieve a custom url where you can complete your application. We&apos;ll need a few docments like a copy of your driver&apos;s license and tax returns for the business.'
                        />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <ExpectPanel image={underwriting}
                            timing='TIMING: 5 - 7 BUSINESS DAYS'
                            descr='Our team of specialists will carefully review your application. We may reach out to ensure we&apos;ve gathered all information needed to submit for approval.'
                        />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <ExpectPanel image={approval}
                            timing='TIMING: 24 - 48 HOURS'
                            descr='Our credit team reviews all documentation and issues a final decision. If approved, you&apos;ll receive a commitment letter.'
                        />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <ExpectPanel image={sba}
                            timing='TIMING: 20 - 45 DAYS'
                            descr='All documentation is submitted to the SBA for approval.'
                        />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        <ExpectPanel image={funding}
                            timing='TIMING: 24 HOURS'
                            descr='Sign final documentation and distribute funds. Congratulations!'
                        />
                    </TabPanel>
                </Box>

                <br />
                <br />
                <br />

            </Stack>
        </div>
    );
}

/*




import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography, Stack, Badge } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ExpectAccordian from './ExpectAccordian'
import ExpectCard from './ExpectCard'

const shapeStyles = {
    bgcolor: '#08256C',
    width: 30,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
};

const CircleWithNumber = ({ number }) => (
    <Box
        component="span"
        sx={{ ...shapeStyles }}
    >
        <svg width="100%" height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <text x="48%" y="67%" text-anchor="middle" fill="#FFFFFF" font-size="50" font-weight="bold">{number}</text>
        </svg>
    </Box>
);



function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
         /* <Box sx={{ p: 3 }}> 
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }


function Expect() {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <div style={{ marginTop: '40px', marginLeft: '50px' }} className="Expect">
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >

                <Box sx={{
                    width: '50rem',
                    height: '5rem',
                    borderBottom: 1,
                    textAlign: 'left',
                    m: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    marginLeft: '-30rem',
                }}>
                    <Typography fontSize={30} fontWeight={500} color={'#08256C'}    //blue
                    //style={{ paddingBottom: '1rem' }} {/* Adjust padding to control distance from bottom 
                    >
                        What to Expect
                    </Typography>
                </Box>

                <br />
                <Typography sx={{
                    fontSize: { xs: 18, sm: 20, md: 22, lg: 24 },
                    paddingRight: { xs: '50px', sm: '80px', md: '100px', lg: '300px' }
                }}
                    fontWeight={500}
                    color={'secondary.light'}    //blue

                //style={{ paddingBottom: '1rem' }} {/* Adjust padding to control distance from bottom 
                >
                    Let's break it down. Here's a comprehensive overview of what you can expect as you navigate through the SBA 7(a) loan process, from the initial stages of prequalification all the way through to securing funding.
                </Typography>
                <br />
                <Box sx={{ width: '70%' }}>
                    <Box
                        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
                    >
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                            TabIndicatorProps={{ style: { backgroundColor: '#08256C' } }}
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                            orientation="vertical"
                            textColor="primary"
                            indicatorColor="primary">
                            <Tab label="Prequalify" {...a11yProps(0)} />
                            <Tab label="Apply" {...a11yProps(1)} />
                            <Tab label="Underwriting" {...a11yProps(2)} />
                            <Tab label="Approval" {...a11yProps(3)} />
                            <Tab label="Closing" {...a11yProps(4)} />
                            <Tab label="Funding" {...a11yProps(5)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <Typography fontSize={12} fontWeight={600} color='secondary.main' marginTop={'4px'}   //blue
                        >
                            TIMING: 5 MINUTES
                        </Typography>
                        <br />
                        <Typography fontSize={16} fontWeight={600} color='secondary.light' marginTop={'4px'}   //blue
                        >
                            Complete our simple online prequalification for either an SBA Express or SBA 7(a) loan.
                        </Typography>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Typography fontSize={12} fontWeight={600} color='secondary.main' marginTop={'4px'}   //blue
                        >
                            TIMING: 15 MINUTES
                        </Typography>
                        <br />
                        <Typography fontSize={16} fontWeight={600} color='secondary.light' marginTop={'4px'}   //blue
                        >
                            Upon prequalification, you'll recieve a custom url where you can complete your application. We'll need a few docments like a copy of your driver's license and tax returns for the business.
                        </Typography>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Typography fontSize={12} fontWeight={600} color='secondary.main' marginTop={'4px'}   //blue
                        >
                            TIMING: 5 - 7 BUSINESS DAYS
                        </Typography>
                        <br />
                        <Typography fontSize={16} fontWeight={600} color='secondary.light' marginTop={'4px'}   //blue
                        >
                            Our team of specialists will carefully review your application. We may reach out to ensure we've gathered all information needed to submit for approval.
                        </Typography>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Typography fontSize={12} fontWeight={600} color='secondary.main' marginTop={'4px'}   //blue
                        >
                            TIMING: 24 - 48 HOURS
                        </Typography>
                        <br />
                        <Typography fontSize={16} fontWeight={600} color='secondary.light' marginTop={'4px'}   //blue
                        >
                            Credit team reviews all documentation and issues a final decision. If approved, you'll receive a commitment letter.
                        </Typography>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <Typography fontSize={12} fontWeight={600} color='secondary.main' marginTop={'4px'}   //blue
                        >
                            TIMING: 20 - 45 DAYS
                        </Typography>
                        <br />
                        <Typography fontSize={16} fontWeight={600} color='secondary.light' marginTop={'4px'}   //blue
                        >
                            All documentation is submitted to the SBA for approval.
                        </Typography>
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        <Typography fontSize={12} fontWeight={600} color='secondary.main' marginTop={'4px'}   //blue
                        >
                            TIMING: 24 HOURS
                        </Typography>
                        <br />
                        <Typography fontSize={16} fontWeight={600} color='secondary.light' marginTop={'4px'}   //blue
                        >
                            Sign final documentation and distribute funds. Congratulations!
                        </Typography>
                    </TabPanel>
                </Box>

            </Stack>
            {/* <ExpectAccordian /> 
            {/* <ExpectCard /> 
        </div>
    );
}

export default Expect;
*/