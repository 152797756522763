import { styled, alpha } from '@mui/material/styles';
import { FormGroup, FormControlLabel, Switch, Box, Card, CardContent, Stack, Typography } from '@mui/material';


const ToggleSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 450,
    height: 50,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 4,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: `translateX(${450 - (220 - 32) - 40}px)`,
            color: '#fff',
            '& .MuiSwitch-thumb': {
                backgroundColor: theme.palette.primary.brighterLight, // Thumb color when checked
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&:before': {
                    fontSize: '20px', /* Adjust font size as needed */
                    position: 'absolute', /* Positioning absolute to align within thumb */
                    right: 10,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    content: '"Standard 7(a)"', /* Text to display */
                    color: theme.palette.primary.dark, /* Change text color as needed */
                    fontWeight: 600,
                    fontFamily: theme.typography.fontFamily
                    //fontSize: '16px', /* Adjust font size if needed */
                    //display: 'block', /* Ensure the text is rendered as a block element */
                    //textAlign: 'center', /* Center text within the thumb */
                },
            },
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.primary.dark, // track color when toggled right
                opacity: 1,
                border: 0,
                ...theme.applyStyles('dark', {
                    backgroundColor: '#2ECA45',
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
            ...theme.applyStyles('dark', {
                color: theme.palette.grey[600],
            }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
            ...theme.applyStyles('dark', {
                opacity: 0.3,
            }),
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.primary.brightLight, // Default thumb color when unchecked
        boxSizing: 'border-box',
        width: 220,
        height: 42,
        borderRadius: 20, // Set to half of height to make it oval
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&::before': {
            fontSize: '20px', /* Adjust font size as needed */
            position: 'absolute', /* Positioning absolute to align within thumb */
            left: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            content: '"Express Lane 7(a)"', /* Text to display */
            color: theme.palette.primary.dark, /* Change text color as needed */
            fontWeight: 600,
            fontFamily: theme.typography.fontFamily
        },
    },
    '& .MuiSwitch-track': {
        borderRadius: 25,
        backgroundColor: theme.palette.primary.dark, // track color when toggled left
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        ...theme.applyStyles('dark', {
            backgroundColor: '#39393D',
        }),
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            fontSize: '20px', /* Adjust font size as needed */
            color: 'white', /* Adjust text color as needed */
            width: 200,
            height: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: theme.typography.fontFamily
        },
        '&::before': {
            content: '"Express Lane 7(a)"',
            left: 10,
            fontFamily: theme.typography.fontFamily
        },
        '&::after': {
            content: '"Standard 7(a)"',
            right: 10,
            fontFamily: theme.typography.fontFamily
        },
    },
}));

export default ToggleSwitch