
import { Button, Stack, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';

function NextButtonFinal({ handleSubmit, data, handleBack, loading, disabled, contactinfo }) {
    return (
        <Grid container
            direction="row"
            justifyContent="center"
        >
            <Grid item xs={10} sm={8} md={6} >
                <Stack mt={0} sx={{ backgroundColor: 'white' }} mr={0}>
                    <Stack spacing={2}
                        direction="row"
                        alignItems='center'
                        justifyContent='center'
                        mt={3}
                        mb={3}
                        sx={{ width: { md: '100%', lg: 'calc(100% - 240px)'}  }}>
                        {/*<Button variant="contained"
                            sx={{
                                fontWeight: 600, color: 'white', backgroundColor: data.colorfont,
                                '&:hover': {
                                    backgroundColor: data.colorfont,
                                    opacity: 0.70,
                                }
                            }}
                            onClick={handleBack}
                        >
                            <span>BACK</span>
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        */} 
                        <LoadingButton variant="contained"
                            sx={{
                                fontWeight: 600, fontSize: 18, color: 'white', backgroundColor: data.colorfont,
                                '&:hover': {
                                    backgroundColor: data.colorfont,
                                    opacity: 0.70,
                                }
                            }}
                            onClick={handleSubmit}
                            loading={loading}
                            disabled={!disabled}
                        >
                            <span>SUBMIT</span>
                        </LoadingButton>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default NextButtonFinal