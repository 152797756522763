import { Card, CardContent, Typography, Stack, Box } from "@mui/material"
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/system';

export default function AboutBox({ icon, title
    , subtitle1, descr1
    , subtitle2, descr2
    , subtitle3, descr3
    , subtitle4, descr4 }) {

    const theme = useTheme();

    return (
        <Card sx={{
            boxShadow: 'none', marginLeft: { xs: '-30px', sm: '0px' }
            //, backgroundColor: alpha(theme.palette.primary.contrastText, 0.5), borderRadius: 6
            , border: 1
            , borderColor: theme.palette.primary.bright
        }}>
            <CardContent>
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        {React.cloneElement(icon, { sx: { color: theme => theme.palette.secondary.light, marginTop: 1 } })}
                        <Typography sx={{ fontSize: { xs: 18, sm: 18, md: 18, lg: 24 }, color: 'primary.red', fontWeight: 700 }}>
                            {title}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="column"
                        spacing={0.5}
                        width="100%"
                        sx={{ display: 'flex' }}
                    >
                        <Typography sx={{ fontSize: { xs: 16, sm: 16, md: 16, lg: 18 }, color: 'secondary.light', fontWeight: 600, lineHeight: 1 }}>
                            {subtitle1}
                        </Typography>
                        <Typography sx={{
                            fontSize: { xs: 13, sm: 14, md: 14, lg: 16 }, color: 'secondary.light', fontWeight: 500, lineHeight: 1
                            , paddingLeft: 6
                        }}>
                            {descr1}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="column"
                        spacing={0.5}
                        width="100%"
                        sx={{ display: 'flex' }}
                    >
                        <Typography sx={{ fontSize: { xs: 16, sm: 16, md: 16, lg: 18 }, color: 'secondary.light', fontWeight: 600, lineHeight: 1 }}>
                            {subtitle2}
                        </Typography>
                        <Typography sx={{
                            fontSize: { xs: 13, sm: 14, md: 14, lg: 16 }, color: 'secondary.light', fontWeight: 500, lineHeight: 1
                            , paddingLeft: 6
                        }}>
                            {descr2}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="column"
                        spacing={0.5}
                        width="100%"
                        sx={{ display: 'flex' }}
                    >
                        <Typography sx={{ fontSize: { xs: 16, sm: 16, md: 16, lg: 18 }, color: 'secondary.light', fontWeight: 600, lineHeight: 1 }}>
                            {subtitle3}
                        </Typography>
                        <Typography sx={{
                            fontSize: { xs: 13, sm: 14, md: 14, lg: 16 }, color: 'secondary.light', fontWeight: 500, lineHeight: 1
                            , paddingLeft: 6
                        }}>
                            {descr3}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="column"
                        spacing={0.5}
                        width="100%"
                        sx={{ display: 'flex' }}
                    >
                        <Typography sx={{ fontSize: { xs: 16, sm: 16, md: 16, lg: 18 }, color: 'secondary.light', fontWeight: 600, lineHeight: 1 }}>
                            {subtitle4}
                        </Typography>
                        <Typography sx={{
                            fontSize: { xs: 13, sm: 14, md: 14, lg: 16 }, color: 'secondary.light', fontWeight: 500, lineHeight: 1
                            , paddingLeft: 6
                        }}>
                            {descr4}
                        </Typography>
                    </Stack>

                </Stack>
            </CardContent>
        </Card>
    )
}



/* 
<Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        {React.cloneElement(icon, { sx: { color: theme => theme.palette.secondary.light, marginTop: 1 } })}
                        <Typography sx={{ fontSize: { xs: 16, sm: 18, md: 18, lg: 24 }, color: 'primary.red', fontWeight: 700 }}>
                            {title}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={2}
                        width="100%"
                        sx={{ display: 'flex' }}
                    >
                        <Box sx={{ flex: '0 0 35%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 16, lg: 18 }, color: 'secondary.light', fontWeight: 600, lineHeight: 1 }}>
                                {subtitle1}
                            </Typography>
                        </Box>
                        <Box sx={{ flex: '0 0 65%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <Typography sx={{ fontSize: { xs: 13, sm: 14, md: 14, lg: 16 }, color: 'secondary.light', fontWeight: 500, lineHeight: 1 }}>
                                {descr1}
                            </Typography>
                        </Box>
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={2}
                        width="100%"
                        sx={{ display: 'flex' }}
                    >
                        <Box sx={{ flex: '0 0 35%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 16, lg: 18 }, color: 'secondary.light', fontWeight: 600, lineHeight: 1 }}>
                                {subtitle2}
                            </Typography>
                        </Box>
                        <Box sx={{ flex: '0 0 65%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <Typography sx={{ fontSize: { xs: 13, sm: 14, md: 14, lg: 16 }, color: 'secondary.light', fontWeight: 500, lineHeight: 1 }}>
                                {descr2}
                            </Typography>
                        </Box>
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={2}
                        width="100%"
                        sx={{ display: 'flex' }}
                    >
                        <Box sx={{ flex: '0 0 35%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 16, lg: 18 }, color: 'secondary.light', fontWeight: 600, lineHeight: 1 }}>
                                {subtitle3}
                            </Typography>
                        </Box>
                        <Box sx={{ flex: '0 0 65%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <Typography sx={{ fontSize: { xs: 13, sm: 14, md: 14, lg: 16 }, color: 'secondary.light', fontWeight: 500, lineHeight: 1 }}>
                                {descr3}
                            </Typography>
                        </Box>
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={2}
                        width="100%"
                        sx={{ display: 'flex' }}
                    >
                        <Box sx={{ flex: '0 0 35%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 16, lg: 18 }, color: 'secondary.light', fontWeight: 600, lineHeight: 1 }}>
                                {subtitle4}
                            </Typography>
                        </Box>
                        <Box sx={{ flex: '0 0 65%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <Typography sx={{ fontSize: { xs: 13, sm: 14, md: 14, lg: 16 }, color: 'secondary.light', fontWeight: 500, lineHeight: 1 }}>
                                {descr4}
                            </Typography>
                        </Box>
                    </Stack>
*/



/* 
<Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        {React.cloneElement(icon, { sx: { color: theme => theme.palette.secondary.light, marginTop: 1 } })}
                        <Typography sx={{ fontSize: { xs: 16, sm: 18, md: 18, lg: 24 }, color: 'primary.red', fontWeight: 700 }}>
                            {title}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="column"
                        spacing={0.5}
                        width="100%"
                        sx={{ display: 'flex' }}
                    >
                        <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 16, lg: 18 }, color: 'secondary.light', fontWeight: 600, lineHeight: 1 }}>
                            {subtitle1}
                        </Typography>
                        <Typography sx={{
                            fontSize: { xs: 13, sm: 14, md: 14, lg: 16 }, color: 'secondary.light', fontWeight: 500, lineHeight: 1
                            , paddingLeft: 6
                        }}>
                            {descr1}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="column"
                        spacing={0.5}
                        width="100%"
                        sx={{ display: 'flex' }}
                    >
                        <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 16, lg: 18 }, color: 'secondary.light', fontWeight: 600, lineHeight: 1 }}>
                            {subtitle2}
                        </Typography>
                        <Typography sx={{
                            fontSize: { xs: 13, sm: 14, md: 14, lg: 16 }, color: 'secondary.light', fontWeight: 500, lineHeight: 1
                            , paddingLeft: 6
                        }}>
                            {descr2}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="column"
                        spacing={0.5}
                        width="100%"
                        sx={{ display: 'flex' }}
                    >
                        <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 16, lg: 18 }, color: 'secondary.light', fontWeight: 600, lineHeight: 1 }}>
                            {subtitle3}
                        </Typography>
                        <Typography sx={{
                            fontSize: { xs: 13, sm: 14, md: 14, lg: 16 }, color: 'secondary.light', fontWeight: 500, lineHeight: 1
                            , paddingLeft: 6
                        }}>
                            {descr3}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="column"
                        spacing={0.5}
                        width="100%"
                        sx={{ display: 'flex' }}
                    >
                        <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 16, lg: 18 }, color: 'secondary.light', fontWeight: 600, lineHeight: 1 }}>
                            {subtitle4}
                        </Typography>
                        <Typography sx={{
                            fontSize: { xs: 13, sm: 14, md: 14, lg: 16 }, color: 'secondary.light', fontWeight: 500, lineHeight: 1
                            , paddingLeft: 6
                        }}>
                            {descr4}
                        </Typography>
                    </Stack>
*/