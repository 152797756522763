import React, { useState, useEffect } from 'react';
import { Typography, Stack, Card, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Timeline from './Timeline';
import imagelarge from '../images/imageexpresslarge2.png'
import imagemedium from '../images/imageexpress.png'
import imagesmall from '../images/imageexpresssmall.png'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Helmet } from 'react-helmet-async'

function Express() {
    const [value, setValue] = React.useState(0);
    const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const theme = useTheme();

    const [imageSrc, setImageSrc] = useState('defaultImage.jpg');

    useEffect(() => {
        const updateImage = () => {
            if (window.matchMedia('(max-width: 700px)').matches) {
                setImageSrc(imagesmall);
            } else if (window.matchMedia('(max-width: 900px)').matches) {
                setImageSrc(imagemedium);
            } else {
                setImageSrc(imagelarge);
            }
        };

        updateImage(); // Set initial image

        window.addEventListener('resize', updateImage);
        return () => window.removeEventListener('resize', updateImage);
    }, []);

    return (
        <div>
            <Helmet>
                <title>CapTec USA | Express Loans</title>
                <meta name="description" content="Expedited SBA funding for $500,000 or less. Get quick access to funds with our streamlined application completely online. Business funding after business hours." />
                <link rel="canonical" href="https://thenewsouthbank.com/sbaexpress" />
            </Helmet>
            <div style={{ marginTop: '40px', marginLeft: '50px' }} className="Express">
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                >

                    <Box sx={{
                        width: '70vw',
                        height: '5rem',
                        borderBottom: 0,
                        textAlign: 'left',
                        m: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                    }}>
                        <Typography fontSize={40} fontWeight={600}
                            sx={{ color: theme => theme.palette.primary.main }}
                        >
                            SBA Express
                        </Typography>
                    </Box>
                </Stack>
                <br />
            </div>
            <Card style={{
                color: 'white',
                backgroundColor: theme.palette.primary.main,
                boxShadow: 'none',
                padding: 0,
                marginTop: 0,
                height: 'auto',
                borderRadius: 0,
                position: 'relative', // Ensure relative positioning for absolute children
                overflow: 'hidden', // Clip overflowing content (like the image)
            }}>
                <div style={{ marginTop: '30px', marginLeft: '50px' }} className="WhySBA">

                    {/* Content within the card */}
                    <div style={{
                        position: 'relative', zIndex: 1, textAlign: 'center', padding: '20px',

                    }}>
                        <Card sx={{
                            boxShadow: 'none',
                            bgcolor: 'transparent',
                            marginLeft: { xs: '-30px', sm: '0px' }
                        }}>
                            {/* <Stack direction="row" justifyContent="center" alignItems="center" spacing={3} marginBottom={10} marginTop={5} > */}
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                                sx={{ width: { xs: '40vw', sm: '40vw', md: '50vw' } }}
                            >
                                {/* <Box sx={{
                                    width: '40vw',
                                    height: 'auto',
                                    borderBottom: 1,
                                    borderColor: 'white',
                                    textAlign: 'left',
                                    m: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end',
                                    marginLeft: '-30rem'
                                }}>
                                    <Typography fontSize={40} fontWeight={600} color={'white'}    //blue
                                    >
                                        Why SBA
                                    </Typography>
                                </Box> 
                                <br /> */}
                                <Typography sx={{
                                    fontSize: { xs: 16, sm: 16, md: 18, lg: 20 }, textAlign: 'left',
                                    lineHeight: 1.5
                                    //paddingRight: { xs: '50px', sm: '80px', md: '100px', lg: '300px' }
                                }}
                                    fontWeight={500}
                                    color={'white'}
                                >
                                    <CheckCircleOutlineIcon /> <i>Looking for expedited funding?</i>
                                </Typography>
                                <Typography sx={{
                                    fontSize: { xs: 16, sm: 16, md: 18, lg: 20 },
                                    textAlign: 'left',
                                    lineHeight: 1.5,
                                    paddingLeft: { xs: 0, sm: '16px' }
                                }}
                                    fontWeight={500}
                                    color={'white'}
                                >
                                    <CheckCircleOutlineIcon /> <i>Need $500,000 or less?</i>
                                </Typography>
                                <Typography sx={{
                                    fontSize: { xs: 16, sm: 16, md: 18, lg: 20 },
                                    textAlign: 'left',
                                    lineHeight: 1.5,
                                    paddingLeft: { xs: 0, sm: '32px' }
                                }}
                                    fontWeight={500}
                                    color={'white'}
                                >
                                    <i>Unlock rapid funding for your business with SBA Express Loans, offering swift approvals and streamlined processes. Benefit from flexible terms and quick access to capital, helping you drive growth with ease.</i>
                                </Typography>
                                <br />
                            </Stack>
                        </Card>
                    </div>

                    {/* Absolute positioned image */}
                    <img
                        src={imageSrc}
                        alt='flag'
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            width: 'auto',
                            height: '100%',
                            objectFit: 'contain',
                            zIndex: 0, // Ensure the image is behind other content
                            maskImage: 'linear-gradient(to left, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)', // Gradient mask
                            WebkitMaskImage: 'linear-gradient(to left, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)', // For Safari
                        }}
                    />
                </div>
            </Card>
            <br/>
            <Timeline amtdescr='$100,000 - $500,000'
                termdescr='Express loans have a term of 10 years.'
                usedescr='Express loans can be used for all approved SBA loan uses, with the exception of commercial real estate'
                fundingdescr='SBA Express loans are funded within days.'
            />
            <br />
        </div>
    );
}

export default Express;
