import { Typography, Stack, Grid } from "@mui/material"
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'

function CreditEndNoScore({ data, setCreditResult }) {

    setCreditResult(4)

    const mailtoval = "mailto:"+data.sendingemail
    
    const navigate = useNavigate();
    console.log('data.customurl : ', data.customurl)
    
    const parsedUrl = new URL(data.customurl);
    const path = parsedUrl.pathname;
    

    const handleClick = (path) => {
        console.log('path : ', path)
        //navigate(path, { replace: true });
        navigate(path);
        
    }

    console.log()

    function Result() {
        return (
            <div style={{ marginTop: '40px', marginLeft: '50px' }} className="NoScore">
                <Helmet>
                <title>CapTec USA | No Score</title>
                <meta name="description" content="We were unable to find your credit score. Follow your custom url to complete the prequalification with a 15-minute questionnaire." />
                <link rel="canonical" href="https://thenewsouthbank.com/noscore" />
            </Helmet>
                <br/>
                <br/>
                <Typography variant="h4" align="center" sx={{ fontWeight: 700, color: data.colorstep }}>
                    Thank you!
                </Typography>
                <br />
                <Typography variant="h6" align="center">
                    {/*You passed the credit check. You will receive an email from <a href={mailtoval}>{data.sendingemail}</a> inviting you to our secure document upload portal.*/}
                    The last prequalification step is to complete the simple prequalification questionnaire. We'll need to run your credit again, as it was not found during this pre-screen.
                </Typography>
                <br />
                <Typography variant="h6" align="center">
                    {/*We sent you an email with a <a href={data.customurl}>link</a> to the questionnaire as well as a document outlining the terms we are working towards.*/}
                    We sent you an email with a link to the questionnaire as well as a document outlining the terms we are working towards.
                </Typography>
                <br />
                <Typography variant="h6" align="center">
                    Once we have all the needed information, we can quickly process your prequalification with funding shortly thereafter.
                </Typography>
                <Stack spacing={2}
                    direction="row"
                    alignItems='center'
                    justifyContent='center'
                    mt={3}
                    mb={3}>
                    <Button variant="contained"
                        sx={{
                            fontWeight: 600, fontSize: 14, color: 'white', backgroundColor: data.colorfont,
                            '&:hover': { backgroundColor: data.colorfont,
                                opacity: .70,
                              },
                        maxWidth: '250px', // Adjust the value as needed
                        width: '100%', // Ensures the button takes up the specified maxWidth
                        }}
                        //href={data.customurl}
                        onClick={() => handleClick(path)}
                    >Click here for your Prequalification Questionnaire
                    </Button>
                </Stack>
            </div>
        )
    }

    return (
        <div>
            <Grid container
                direction="row"
                justifyContent="center"
            >
                <Grid item xs={10} sm={8} md={6} >
                    <Result />
                </Grid>
            </Grid>
        </div>
    )
}

export default CreditEndNoScore