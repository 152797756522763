import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function FormPropsTextFields(props) {
    return (
        <Box
            //component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: {xs: 209, sm: 225} } //width: '35ch' },
            }}
            noValidate
            //autoComplete="off"
            alignItems="center"
            display="flex"
        >
            <div>
                <TextField
                    type={props.type}
                    required
                    id={props.id}
                    autoComplete={props.autoComplete}
                    textContentType={props.textContentType}
                    label={props.label}
                    value={props.value}
                    defaultValue={props.defaultValue}
                    onChange={props.onChange}
                    InputLabelProps={{ shrink: true }}
                    onBlur={props.onBlur}
                    error={props.error}
                />
            </div>
        </Box>
    )
}