import * as React from 'react';
import { Stack, Grid, Card, CardMedia, CardContent, useMediaQuery, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AboutBoxBulletPoints from './AboutBoxBulletPoints';
import { useTheme } from '@mui/material/styles';
import easy from '../images/easy.png'




export default function AboutHomePage() {
    const theme = useTheme();
    const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const halfBackgroundStyle = {
        background: `linear-gradient(
    to top,
    transparent 0%, 
    transparent 10%, 
    ${theme.palette.primary.contrastText} 10%, 
    ${theme.palette.primary.contrastText} 30%, 
    transparent 30%, 
    transparent 100%
  )`,
        display: 'inline',
    };

    return (
        <div style={{ display: 'flex', height: 'auto', alignItems: 'stretch', opacity: 1, zIndex: 10 }}>
            <Grid container spacing={3} sx={{ ml: { xs: 0, sm: 2, md: 4, lg: 5, xl: 6, }, }}>
                <Grid item xs={12} sm={9} md={5} lg={6}>
                <br/>
                    {/* Left Half: Typography and Image */}
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between', padding: '20px' }}>
                        <Card sx={{ boxShadow: 'none', display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <Typography
                                sx={{
                                    fontSize: { xs: 28, sm: 30, md: 30, lg: 34 },
                                    //marginTop: { xs: 2, sm: 2, md: 4, lg: 5 },
                                    textAlign: 'left',
                                    position: 'relative',
                                    zIndex: 10,
                                }}
                                fontWeight={600}
                                color={theme.palette.primary.dark}
                                align="left" // Changed to 'left' for proper alignment
                            >
                                We Make SBA 7(a) Loans Easier
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: { xs: 28, sm: 30, md: 30, lg: 34 },
                                    marginTop: 2,
                                    textAlign: 'left',
                                    position: 'relative',
                                    zIndex: 10,
                                }}
                                fontWeight={500}
                                color={theme.palette.primary.dark}
                                align="left" // Changed to 'left' for proper alignment
                            >
                                We simplify the SBA 7(a) loan process with <span style={halfBackgroundStyle}>clear terms</span> upfront and <span style={halfBackgroundStyle}>flexible financing</span>, powered by <span style={halfBackgroundStyle}>efficient technology</span> to save you time and hassle.
                            </Typography>
                            {isMediumScreen ?
                                <></>
                                :
                                <div style={{
                                    width: '75%',
                                    height: '300px', // Adjust the height as needed
                                    position: 'relative',
                                    overflow: 'hidden', // Ensures content does not overflow and respects rounded corners
                                    marginTop: 'auto', // Pushes image to the bottom of the container
                                    marginLeft: '50px',
                                    borderRadius: '20px', // Apply border-radius here
                                }}>
                                    <img
                                        src={easy}
                                        alt="Easy Loan Process"
                                        style={{
                                            position: 'absolute',
                                            top: '0', // Align image to the top
                                            left: '0',
                                            width: '100%',
                                            height: '100%', // Make sure it covers the container
                                            objectFit: 'cover',
                                        }}
                                    />
                                </div>
                            }
                        </Card>
                    </div>
                </Grid>
                <Grid item xs={12} sm={9} md={5} lg={5} sx={{ marginTop: {xs: '-30px', sm: 0, md: 0, ls: 0 } }}
                    >
                        <br/>
                    {/* Right Half: LoanUseBoxes */}
                    <div style={{ flex: 1, padding: '20px', overflowY: 'auto', height: '100%' }}>
                        <Card sx={{ boxShadow: 'none', height: '100%' }}>
                            <Stack spacing={2}>
                                <AboutBoxBulletPoints
                                    icon={<LockOpenIcon color='secondary' />}
                                    title='Know the Deal Upfront'
                                    subtitle1='Expected Interest Rate'
                                    descr1='Understand what you’re likely to pay.'
                                    subtitle2='Loan Terms'
                                    descr2='Get a detailed view of your repayment timeline.'
                                    subtitle3='Estimated Fees'
                                    descr3='Know what costs to expect upfront.'
                                    subtitle4='Loan Timing'
                                    descr4='See how quickly you can receive funding.'
                                />
                                <AboutBoxBulletPoints
                                    icon={<AccessTimeIcon color='secondary' />}
                                    title='24/7: Get Started Anytime, Anywhere'
                                    subtitle1='Accessible Anytime'
                                    descr1='Begin your application at your convenience, even after hours.'
                                    subtitle2='Instant Results'
                                    descr2='Receive your personalized loan estimate within minutes.'
                                    subtitle3='No Waiting'
                                    descr3='Start checking your credit and uploading documents right away.'
                                    subtitle4='On Your Schedule'
                                    descr4='Move forward with your loan at a pace that suits you.'
                                />
                            </Stack>
                        </Card>
                    </div>
                </Grid>
            </Grid>
        </div>


    );
}