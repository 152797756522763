import * as React from 'react';
import { Box, Stack, Grid, Card, CardMedia, CardContent, useMediaQuery, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';




export default function ByTheNumbersDetail({ title, descr }) {
    const theme = useTheme();


    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center', // Center items vertically
                margin: 2
            }}
        >
            <Stack direction='column' spacing={0}
                sx={{ width: '100%', alignItems: {xs: 'flex-end', sm: 'flex-end', md: 'flex-start', lg: 'flex-start' } }} 
            >
                    <Typography color={theme.palette.primary.bright} fontSize={95} 
                        sx={{ lineHeight: 1, textAlign: { xs: 'right', sm: 'right', md: 'left', lg: 'left' } }}>
                        {title}
                    </Typography>
                    <Typography color='white'  
                        sx={{ textAlign: { xs: 'right', sm: 'right', md: 'left', lg: 'left' } 
                            , fontSize: {xs: 16, sm: 18, md: 20, lg: 20}}}>
                        {descr}
                    </Typography>
                    <br/>
            </Stack>
        </Box>
    )
}