import React, { useEffect } from 'react';
import { List, ListItem, Checkbox, ListItemText, ListItemSecondaryAction } from '@mui/material';
import NextButton from './NextButton';
import { Stack, Typography, Grid } from "@mui/material";
import CheckboxWithLabel from './CheckboxWithLabel'


function Step3({ handleBack, handleNext, data,
    qualifyProfit, setQualifyProfit,
    qualifyProfitable, setQualifyProfitable,
    qualifyTaxes, setQualifyTaxes,
    qualifyDelq, setQualifyDelq,
    qualifyDefault, setQualifyDefault,
    qualifyBankrupt, setQualifyBankrupt,
    qualifyIncarc, setQualifyIncarc,
    qualifyCitizen, setQualifyCitizen,
    qualifyIndustry, setQualifyIndustry,
    steps, setSteps }) {

    useEffect(() => {
        //console.log('step1 useEffect qstepdata : ', qstepdata)
        if (qualifyProfit != null
            && qualifyProfitable != null
            && qualifyTaxes != null
            && qualifyDelq != null
            && qualifyDefault != null
            && qualifyBankrupt != null
            && qualifyIncarc != null
            && qualifyCitizen != null
            && qualifyIndustry != null
        ) {
            setSteps(steps => ({ ...steps, 2: true }));
            //console.log('step6 complete: ', driverslicense.size, year1bustax.size, year2bustax.size, personaltax.size)
        } else {
            setSteps(steps => ({ ...steps, 2: false }));
            //console.log('step6 incomplete: ', driverslicense.size, year1bustax.size, year2bustax.size, personaltax.size)
        }
    }, [qualifyProfit, qualifyProfitable, qualifyTaxes, qualifyDelq, qualifyDefault, qualifyBankrupt
        , qualifyIncarc, qualifyCitizen, qualifyIndustry
    ]);

    return (
        <div>
            <Stack mt={0}>
                <Stack alignItems="center" sx={{ width: { md: '100%', lg: 'calc(100% - 240px)' } }} >
                    <Typography variant="h6" sx={{ fontWeight: 700, color: data.colorstep }}>
                        Step 3:
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 700 }}>
                        Qualification Questions
                    </Typography>
                </Stack>

                <br /><br />
                <Grid container justifyContent="center" >
                    <Grid item xs={10} md={6} lg={6} sx={{
                        marginLeft: {
                            xs: 0, // Set marginLeft to -10 for xs breakpoint
                            md: -15, // Set marginLeft to -15 for md breakpoint
                            lg: -15, // Set marginLeft to -20 for lg breakpoint
                        },
                        marginRight: {
                            xs: 5, // Set marginLeft to -10 for xs breakpoint
                            md: 0, // Set marginLeft to -15 for md breakpoint
                            lg: 0, // Set marginLeft to -20 for lg breakpoint
                        }
                    }}>


                        <List>
                            <ListItem>
                                <ListItemText />
                                <ListItemSecondaryAction>
                                    <Typography variant="subtitle2" color="textSecondary">&nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;No</Typography>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Typography fontSize={{ xs: 14, sm: 15, md: 16, lg: 16 }} sx={{ fontWeight: 700, color: data.colorfont, marginRight: 6 }} >
                                            Is the business a for-profit company?
                                        </Typography>
                                    } />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setQualifyProfit(event.target.checked);
                                        }}
                                        checked={qualifyProfit === true}
                                        color="default"
                                    />
                                    <Checkbox
                                        edge="end"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setQualifyProfit(!event.target.checked);
                                        }}
                                        checked={qualifyProfit === false}
                                        color="default"
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Typography fontSize={{ xs: 14, sm: 15, md: 16, lg: 16 }} sx={{ fontWeight: 700, color: data.colorfont, marginRight: 6 }} >
                                            Is the business currently profitable?
                                        </Typography>
                                    } />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setQualifyProfitable(event.target.checked);
                                        }}
                                        checked={qualifyProfitable === true}
                                        color="default"
                                    />
                                    <Checkbox
                                        edge="end"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setQualifyProfitable(!event.target.checked);
                                        }}
                                        checked={qualifyProfitable === false}
                                        color="default"
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={
                                    <Typography fontSize={{ xs: 14, sm: 15, md: 16, lg: 16 }} sx={{ fontWeight: 700, color: data.colorfont, marginRight: 6 }} >
                                        Are all owners U.S. citizens or Permanent Residents?
                                    </Typography>
                                } />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setQualifyCitizen(event.target.checked);
                                        }}
                                        checked={qualifyCitizen === true}
                                        color="default"
                                    />
                                    <Checkbox
                                        edge="end"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setQualifyCitizen(!event.target.checked);
                                        }}
                                        checked={qualifyCitizen === false}
                                        color="default"
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={
                                    <Typography fontSize={{ xs: 14, sm: 15, md: 16, lg: 16 }} sx={{ fontWeight: 700, color: data.colorfont, marginRight: 6 }} >
                                        Are all tax returns for the business and owners filed with the necessary taxes paid?
                                    </Typography>
                                } />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setQualifyTaxes(event.target.checked);
                                        }}
                                        checked={qualifyTaxes === true}
                                        color="default"
                                    />
                                    <Checkbox
                                        edge="end"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setQualifyTaxes(!event.target.checked);
                                        }}
                                        checked={qualifyTaxes === false}
                                        color="default"
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={
                                    <Typography fontSize={{ xs: 14, sm: 15, md: 16, lg: 16 }} sx={{ fontWeight: 700, color: data.colorfont, marginRight: 6 }} >
                                        Is any owner currently delinquent on an outstanding loan?
                                    </Typography>
                                } />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setQualifyDelq(event.target.checked);
                                        }}
                                        checked={qualifyDelq === true}
                                        color="default"
                                    />
                                    <Checkbox
                                        edge="end"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setQualifyDelq(!event.target.checked);
                                        }}
                                        checked={qualifyDelq === false}
                                        color="default"
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={
                                    <Typography fontSize={{ xs: 14, sm: 15, md: 16, lg: 16 }} sx={{ fontWeight: 700, color: data.colorfont, marginRight: 6 }} >
                                        Has any owner ever defaulted on a federally-backed loan?
                                    </Typography>
                                } />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setQualifyDefault(event.target.checked);
                                        }}
                                        checked={qualifyDefault === true}
                                        color="default"
                                    />
                                    <Checkbox
                                        edge="end"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setQualifyDefault(!event.target.checked);
                                        }}
                                        checked={qualifyDefault === false}
                                        color="default"
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={
                                    <Typography fontSize={{ xs: 14, sm: 15, md: 16, lg: 16 }} sx={{ fontWeight: 700, color: data.colorfont, marginRight: 6 }} >
                                        Have any owners filed bankruptcy in the last 7 years or presently going through bankruptcy?
                                    </Typography>
                                } />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setQualifyBankrupt(event.target.checked);
                                        }}
                                        checked={qualifyBankrupt === true}
                                        color="default"
                                    />
                                    <Checkbox
                                        edge="end"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setQualifyBankrupt(!event.target.checked);
                                        }}
                                        checked={qualifyBankrupt === false}
                                        color="default"
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={
                                    <Typography fontSize={{ xs: 14, sm: 15, md: 16, lg: 16 }} sx={{ fontWeight: 700, color: data.colorfont, marginRight: 6 }} >
                                        Have any owners been incarcerated in the last 7 years or currently incarcerated?
                                    </Typography>
                                } />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setQualifyIncarc(event.target.checked);
                                        }}
                                        checked={qualifyIncarc === true}
                                        color="default"
                                    />
                                    <Checkbox
                                        edge="end"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setQualifyIncarc(!event.target.checked);
                                        }}
                                        checked={qualifyIncarc === false}
                                        color="default"
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={
                                    <Typography fontSize={{ xs: 14, sm: 15, md: 16, lg: 16 }} sx={{ fontWeight: 700, color: data.colorfont, marginRight: 6 }} >
                                        Is the business involved in the cannabis, gambling, or adult entertainment industries?
                                    </Typography>
                                } />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setQualifyIndustry(event.target.checked);
                                        }}
                                        checked={qualifyIndustry === true}
                                        color="default"
                                    />
                                    <Checkbox
                                        edge="end"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setQualifyIndustry(!event.target.checked);
                                        }}
                                        checked={qualifyIndustry === false}
                                        color="default"
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>

            </Stack>

            <br />
            <NextButton handleBack={handleBack} handleNext={handleNext} data={data} />
        </div >
    )
}





export default Step3

