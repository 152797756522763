import { Card, CardContent, Typography, Grid, Stack } from "@mui/material";
import InputTextFull from './InputTextFull'


function OwnerCard({ label
    , FirstName
    , LastName
    , Perc
    , SSN
    , Email
    , Phone
    , FirstNameError
    , LastNameError
    , PercError
    , SSNError
    , EmailError
    , PhoneError
    , handleFirstName
    , handleLastName
    , handlePerc
    , handleSSN
    , handleEmail
    , handlePhone
    , onBlurFirstName
    , onBlurLastName
    , onBlurPerc
    , onBlurSSN
    , onBlurEmail
    , onBlurPhone
    , data }) {

    return (
            
        
                <Grid container spacing={2} justifyContent="center" xs={12} lg={11}>
                    <Card>
                        <CardContent sx={{ p: 0, }}>

                            {/* Left column */}
                            <Grid item xs={12} sx={{ backgroundColor: data.colorbackground, textAlign: 'center', paddingBottom: 2, paddingTop: 2 }} >
                                <Typography variant="h6" sx={{ fontWeight: 700, color: data.colorstep }} align='left' marginLeft={2}>
                                    {label}
                                </Typography>
                            </Grid>
                            <br />
                            <Grid item xs={12}
                                sx={{
                                    '& .MuiContainer-root.css-ur2jdm-MuiContainer-root': { paddingLeft: 1 }
                                }}>
                                <Grid container spacing={0} paddingRight={2}>
                                    <Grid item xs={12} sm={6} md={4} >
                                        <InputTextFull
                                            headingText="First Name"
                                            headingStyle={{ color: "red" }}
                                            headingSuffix=" *"
                                            //subheading=". "
                                            //marginTop={34}
                                            type="text"
                                            required={true}
                                            value={FirstName}
                                            defaultValue={FirstName}
                                            error={FirstNameError}
                                            color={data.colorfont}
                                            //autoComplete="given-name"
                                            //id="given-name"
                                            onChange={handleFirstName}
                                            onBlur={onBlurFirstName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} >
                                        <InputTextFull //label="DBA Name (if applicable)"
                                            headingText="Last Name"
                                            headingStyle={{ color: "red" }}
                                            headingSuffix=" *"
                                            //subheading=". "
                                            //marginTop={34}
                                            type="text"
                                            required={true}
                                            value={LastName}
                                            defaultValue={LastName}
                                            error={LastNameError}
                                            color={data.colorfont}
                                            //autoComplete="given-name"
                                            //id="given-name"
                                            onChange={handleLastName}
                                            onBlur={onBlurLastName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} >
                                        <InputTextFull //label="DBA Name (if applicable)"
                                            headingText="Ownership %"
                                            headingStyle={{ color: "red" }}
                                            headingSuffix=" *"
                                            //subheading="Must be at least 20%"
                                            type="text"
                                            required={true}
                                            value={Perc}
                                            defaultValue={Perc}
                                            error={PercError}
                                            color={data.colorfont}
                                            //autoComplete="given-name"
                                            //id="given-name"
                                            onChange={handlePerc}
                                            onBlur={onBlurPerc}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} paddingRight={2}>
                                    {/*<Grid item xs={4}>
                                        <InputTextFull
                                            headingText="SSN"
                                            //headingStyle={{ color: "red" }}
                                            //headingSuffix=" *"
                                            //subheading=". "
                                            //marginTop={34}
                                            type="text"
                                            required={true}
                                            value={SSN}
                                            defaultValue={SSN}
                                            error={SSNError}
                                            color={data.colorfont}
                                            //autoComplete="given-name"
                                            //id="given-name"
                                            onChange={handleSSN}
                                            onBlur={onBlurSSN}
                                        />
                            </Grid>*/}
                                    <Grid item xs={12} sm={6} md={4} >
                                        <InputTextFull //label="DBA Name (if applicable)"
                                            headingText="Email"
                                            headingStyle={{ color: "red" }}
                                            headingSuffix=" *"
                                            //subheading=". "
                                            //marginTop={34}
                                            type="text"
                                            required={true}
                                            value={Email}
                                            defaultValue={Email}
                                            error={EmailError}
                                            color={data.colorfont}
                                            //autoComplete="given-name"
                                            //id="given-name"
                                            onChange={handleEmail}
                                            onBlur={onBlurEmail}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} >
                                        <InputTextFull //label="DBA Name (if applicable)"
                                            headingText="Phone"
                                            headingStyle={{ color: "red" }}
                                            headingSuffix=" *"
                                            //subheading="Must be at least 20%"
                                            type="text"
                                            required={true}
                                            value={Phone}
                                            defaultValue={Phone}
                                            error={PhoneError}
                                            color={data.colorfont}
                                            //autoComplete="given-name"
                                            //id="given-name"
                                            onChange={handlePhone}
                                            onBlur={onBlurPhone}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>
  
    )
}

export default OwnerCard