
import { Button, Stack, Table, TableBody, TableContainer, Grid, TableRow, Typography } from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import axios from "axios";
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import CircularProgress from './CircularProgress';
import NextButton from './NextButton';
import { createTheme, ThemeProvider } from '@mui/material/styles';




function Step4({ data, setData,
    contactinfo, setContactInfo,
    driverslicense, setDriversLicense,
    year1bustax, setYear1BusTax,
    year2bustax, setYear2BusTax,
    personaltax, setPersonalTax,
    steps, setSteps, handleBack, handleNext, qstepdata
}) {

    const theme = createTheme({
        palette: {
            primary: {
                main: data.colorstep
            },
            secondary: {
                main: "#f6851f"
            }
        },
    });


    const fileInput = useRef();
    const fileInput2 = useRef();
    const fileInput3 = useRef();
    const fileInput4 = useRef();

    const onFileChange = (e) => {
        //console.log(e.target.files[0])
        setDriversLicense({ ...driverslicense, name: e.target.files[0].name, size: getReadableFileSizeString(e.target.files[0].size) });
        //console.log(e.target.files[0])
    };

    useEffect(() => {
        if ((driverslicense.name != 'No file chosen' ) //|| qstepdata.qDriversLicenseName != null) 
            && (year1bustax.name != 'No file chosen' )//|| qstepdata.qBusTax1Name != null)  
            && (year2bustax.name != 'No file chosen' )//|| qstepdata.qBusTax2Name != null)  
            && (personaltax.name != 'No file chosen' ) ) {//|| qstepdata.qPersonalTaxName != null) ) {
                setSteps(steps => ({ ...steps, 3: true }));
                //console.log('step6 complete: ', driverslicense.size, year1bustax.size, year2bustax.size, personaltax.size)
            } else {
                setSteps(steps => ({ ...steps, 3: false }));
                //console.log('step6 incomplete: ', driverslicense.size, year1bustax.size, year2bustax.size, personaltax.size)
            }
    }, [driverslicense.size, year1bustax.size, year2bustax.size, personaltax.size]);


    function getReadableFileSizeString(fileSizeInBytes) {
        var i = -1;
        var byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        do {
            fileSizeInBytes /= 1024;
            i++;
        } while (fileSizeInBytes > 1024);

        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    }


    // API Gateway url to invoke function to generate presigned url
    const API_ENDPOINT = "https://b5fjiiu2wl.execute-api.us-east-1.amazonaws.com/v1";

    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleDriversLicenseChange = (e) => {
        setSelectedFile(e.target.files[0]);
        setDriversLicense({ ...driverslicense
                            , file: e.target.files[0]
                            , name: e.target.files[0].name
                            , size: getReadableFileSizeString(e.target.files[0].size)
                            , type: e.target.files[0].type });
        //console.log(e.target.files[0])
    };

    const handleYear1BusTaxChange = (e) => {
        setSelectedFile(e.target.files[0]);
        setYear1BusTax({ ...year1bustax
                            , file: e.target.files[0]
                            , name: e.target.files[0].name
                            , size: getReadableFileSizeString(e.target.files[0].size)
                            , type: e.target.files[0].type });
        //console.log(e.target.files[0])
    };

    const handleYear2BusTaxChange = (e) => {
        setSelectedFile(e.target.files[0]);
        setYear2BusTax({ ...year2bustax
                            , file: e.target.files[0]
                            , name: e.target.files[0].name
                            , size: getReadableFileSizeString(e.target.files[0].size)
                            , type: e.target.files[0].type });
        //console.log(e.target.files[0])
    };

    const handlePersonalTaxChange = (e) => {
        setSelectedFile(e.target.files[0]);
        setPersonalTax({ ...personaltax
                            , file: e.target.files[0]
                            , name: e.target.files[0].name
                            , size: getReadableFileSizeString(e.target.files[0].size)
                            , type: e.target.files[0].type });
        //console.log(e.target.files[0])
    };

    // Function to generate the presigned url
    const getPresignedUrl = async () => {
        // GET request: presigned URL
        //console.log('starting getPresignedUrl')
        try {
            const response = await axios({
                method: 'POST',
                url: API_ENDPOINT,
            });
            //console.log('Response:', response.data);
            //console.log('Response:', response.data.body);
            //console.log('Response:', JSON.parse(response.data.body));
            //console.log('Response:', JSON.parse(response.data.body).presignedUrl);
            const presignedUrl = JSON.parse(response.data.body).presignedUrl
            //console.log(presignedUrl);
            return presignedUrl;
        } catch (error) {
            console.error('Error:', error);
        }

    };



    // Function to upload the selected file using the generated presigned url
    const uploadToPresignedUrl = async (presignedUrl) => {
        // Upload file to pre-signed URL
        const uploadResponse = await axios.put(presignedUrl, selectedFile, {
            headers: {
                "Content-Type": "application/png",
                "Access-Control-Allow-Origin": "*",
            },
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setUploadProgress(percentCompleted);
                console.log(`Upload Progress: ${percentCompleted}%`);
            },
        });
        //console.log(uploadResponse);
    };

    // Function to orchestrate the upload process
    const handleUpload = async () => {
        try {
            // Ensure a file is selected
            if (!selectedFile) {
                console.error("No file selected.");
                return;
            }

            const presignedUrl = await getPresignedUrl();
            uploadToPresignedUrl(presignedUrl);
        } catch (error) {
            // Handle error
            console.error("Error uploading file:", error);
        }
    };

    const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'white',
            color: '#00461c',
            fontWeight: 700,
            margin: 0,
            borderBottom: "none",
            textAlign: 'center',
            verticalAlign: 'bottom',
            position: 'relative', // Necessary for absolute positioning of the line
        },
        [`&.${tableCellClasses.head} span`]: {
            borderBottom: `2px solid #00461c`, // Change color and style as needed
            paddingBottom: '2px', // Adjust as needed to position the line correctly
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: fontSizeText,
            margin: 0,
            paddingX: '0px'
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const matchesXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const matchesSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const matchesMD = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const matchesLG = useMediaQuery((theme) => theme.breakpoints.down('lg'));
    const matchesXL = useMediaQuery((theme) => theme.breakpoints.down('xl'));
    const matchesXL2 = useMediaQuery((theme) => theme.breakpoints.up('xl'));

    //console.log('matchesXS: ', matchesXS)
    //console.log('matchesSM: ', matchesSM)
    //console.log('matchesMD: ', matchesMD)
    //console.log('matchesLG: ', matchesLG)
    //console.log('matchesXL: ', matchesXL)
    //console.log('matchesXL2: ', matchesXL2)

    let cellWidth1 = '300px'; // default width - xl screen
    let cellWidth2 = '150px'; // default width - xl screen
    let cellWidth3 = '200px'; // default width - xl screen
    let tableWidth = 'max-content'; // default width - xl screen
    let fontSizeHeader = 20; //default size - xl screen
    let fontSizeText = 18; //default size - xl screen

    if (matchesXS) {
        // Adjust width for XS breakpoint
        cellWidth1 = '30%';
        cellWidth2 = '20%';
        cellWidth3 = '30%'
        tableWidth = '100%';
        fontSizeHeader = 14;
        fontSizeText = 14;
    } else if (matchesSM) {
        // Adjust width for SM breakpoint
        cellWidth1 = '200px';
        cellWidth2 = '100px';
        cellWidth3 = '150px';
        tableWidth = 'max-content';
        fontSizeHeader = 16;
        fontSizeText = 16;
    } else if (matchesMD) {
        // Adjust width for MD breakpoint
        cellWidth1 = '300px';
        cellWidth2 = '150px';
        cellWidth3 = '200px';
        tableWidth = 'max-content';
        fontSizeHeader = 18;
        fontSizeText = 18;
    } else if (matchesLG) {
        // Adjust width for LG breakpoint
        cellWidth1 = '300px';
        cellWidth2 = '150px';
        cellWidth3 = '200px';
        tableWidth = 'max-content';
        fontSizeHeader = 20;
        fontSizeText = 18;
    } else if (matchesXL) {
        // Adjust width for LG breakpoint
        cellWidth1 = '300px';
        cellWidth2 = '150px';
        cellWidth3 = '200px';
        tableWidth = 'max-content';
        fontSizeHeader = 20;
        fontSizeText = 18;
    } else if (matchesXL2) {
        // Adjust width for LG breakpoint
        cellWidth1 = '300px';
        cellWidth2 = '150px';
        cellWidth3 = '200px';
        tableWidth = 'max-content';
        fontSizeHeader = 20;
        fontSizeText = 18;
    }

    console.log('data.reactKey : ', data.reactKey)


    return (
        <ThemeProvider theme={theme}>
            <Stack mt={0}>
                <Stack alignItems="center" sx={{ width: { md: '100%', lg: 'calc(100% - 240px)' } }}>
                    <Typography variant="h6" sx={{ fontWeight: 700, color: data.colorstep }}>
                        Step 4:
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 700 }}>
                        Upload Documents
                    </Typography>
                    <br />
                    <Grid container justifyContent="center">
                        <Grid item xs={10} md={8} lg={6}> {/* Adjust xs and md values as per your layout */}


                            <Typography align="left" fontSize={16} sx={{ fontWeight: 700, color: data.colorfont }} >
                                Driver's License <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <Typography align="left" fontSize={14} color='gray' gutterBottom={true}>
                                Upload a color copy of the owner's driver's license
                            </Typography>
                            <Stack spacing={2}
                                direction="row"
                                alignItems='left'
                                justifyContent='left'
                                mt={0}
                                mb={0}>
                                <Button variant="outlined" size="small"
                                    //loading={driverslicense.loading}
                                    sx={{
                                        fontSize: 13, fontWeight: 600, display: 'inline-block',
                                        pl: 1, pr: 1, minHeight: 0, backgroundColor: "#EFF0F2",
                                        pt: 0, pb: 0,
                                        '&:hover': {
                                            backgroundColor: "white",
                                        }
                                    }}
                                    onClick={() => fileInput.current.click()}
                                >
                                    <span>Choose File</span>
                                </Button>
                                <input
                                    ref={fileInput}
                                    type="file"
                                    onChange={handleDriversLicenseChange}
                                    style={{ display: 'none' }}
                                />
                                <Typography fontSize={13} sx={{ color: "gray" }} align="left" style={{ position: 'relative', top: '2px' }}>
                                    {driverslicense.name}
                                </Typography>
                            </Stack>
                            <br />


                            <Typography fontSize={16} sx={{ fontWeight: 700, color: data.colorfont }}>
                                    Most Recent Business Tax Return {data.reactKey.endsWith('1') || data.reactKey.endsWith('6') ? <></> : <span style={{ color: "red" }}>*</span> }
                            </Typography>
                            <Typography fontSize={14} color='gray' gutterBottom={true}>
                                Upload the most recent filed business federal tax return
                            </Typography>
                            <Stack spacing={2}
                                direction="row"
                                alignItems='left'
                                justifyContent='left'
                                mt={0}
                                mb={0}>
                                <Button variant="outlined" size="small"
                                    //loading={driverslicense.loading}
                                    sx={{
                                        fontSize: 13, fontWeight: 600, display: 'inline-block',
                                        pl: 1, pr: 1, minHeight: 0, backgroundColor: "#EFF0F2",
                                        pt: 0, pb: 0,
                                        '&:hover': {
                                            backgroundColor: "white",
                                        }
                                    }}
                                    onClick={() => fileInput2.current.click()}
                                >
                                    <span>Choose File</span>
                                </Button>
                                <input
                                    ref={fileInput2}
                                    type="file"
                                    onChange={handleYear1BusTaxChange}
                                    style={{ display: 'none' }}
                                />
                                <Typography fontSize={13} sx={{ color: "gray" }} align="left" style={{ position: 'relative', top: '2px' }}>
                                    {year1bustax.name}
                                </Typography>
                            </Stack>
                            <br />


                            <Typography fontSize={16} sx={{ fontWeight: 700, color: data.colorfont }} >
                                Second Most Recent Business Tax Return {data.reactKey.endsWith('1') || data.reactKey.endsWith('6') ? <></> : <span style={{ color: "red" }}>*</span> }
                            </Typography>
                            <Typography fontSize={14} color='gray' gutterBottom={true}>
                                Upload the 2nd most recent filed business federal tax return
                            </Typography>
                            <Stack spacing={2}
                                direction="row"
                                alignItems='left'
                                justifyContent='left'
                                mt={0}
                                mb={0}>
                                <Button variant="outlined" size="small"
                                    //loading={driverslicense.loading}
                                    sx={{
                                        fontSize: 13, fontWeight: 600, display: 'inline-block',
                                        pl: 1, pr: 1, minHeight: 0, backgroundColor: "#EFF0F2",
                                        pt: 0, pb: 0,
                                        '&:hover': {
                                            backgroundColor: "white",
                                        }
                                    }}
                                    onClick={() => fileInput3.current.click()}
                                >
                                    <span>Choose File</span>
                                </Button>
                                <input
                                    ref={fileInput3}
                                    type="file"
                                    onChange={handleYear2BusTaxChange}
                                    style={{ display: 'none' }}
                                />
                                <Typography fontSize={13} sx={{ color: "gray" }} align="left" style={{ position: 'relative', top: '2px' }}>
                                    {year2bustax.name}
                                </Typography>
                            </Stack>
                            <br />
                            <Typography align="left" fontSize={16} sx={{ fontWeight: 700, color: data.colorfont }} >
                                Most Recent Personal Tax Return <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <Typography align="left" fontSize={14} color='gray' gutterBottom={true}>
                                Upload the most recent filed personal federal tax return
                            </Typography>
                            <Stack spacing={2}
                                direction="row"
                                alignItems='left'
                                justifyContent='left'
                                mt={0}
                                mb={0}>
                                <Button variant="outlined" size="small"
                                    //loading={driverslicense.loading}
                                    sx={{
                                        fontSize: 13, fontWeight: 600, display: 'inline-block',
                                        pl: 1, pr: 1, minHeight: 0, backgroundColor: "#EFF0F2",
                                        pt: 0, pb: 0,
                                        '&:hover': {
                                            backgroundColor: "white",
                                        }
                                    }}
                                    onClick={() => fileInput4.current.click()}
                                >
                                    <span>Choose File</span>
                                </Button>
                                <input
                                    ref={fileInput4}
                                    type="file"
                                    onChange={handlePersonalTaxChange}
                                    style={{ display: 'none' }}
                                />
                                <Typography fontSize={13} sx={{ color: "gray" }} align="left" style={{ position: 'relative', top: '2px' }}>
                                    {personaltax.name}
                                </Typography>
                            </Stack>
                            <br />

                            <br />


                        </Grid>
                    </Grid>

                </Stack>
                <NextButton handleBack={handleBack} handleNext={handleNext} data={data} />
            </Stack>
        </ThemeProvider>
    );
}


export default Step4




