import { Stack, Typography, Grid } from "@mui/material";
import { Helmet } from 'react-helmet-async'

function SubmitExit({ data }) {
    return (
        <div>
            <Helmet>
                <title>CapTec USA | Thank You</title>
                <meta name="description" content="Thank you for completing the final prequalification step. We will reach out with next steps within 3 business days." />
                <link rel="canonical" href="https://thenewsouthbank.com/questionnaire/submit" />
            </Helmet>
            <Grid container
                direction="row"
                justifyContent="center"
            >
                <Grid item xs={10} sm={8} md={5} >
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <Stack mt={0}>
                        <Stack alignItems="center" sx={{ width: { md: '100%', lg: 'calc(100% - 240px)' } }} >
                            <Typography variant="h4" align="center" sx={{ fontWeight: 700, color: data.colorstep }}>
                                Thank you!
                            </Typography>
                            <br />
                            <br />
                            <Typography variant="h6" align="center">
                                Our team will review your submission in detail and provide you with next steps within <b>3 business days</b>.
                            </Typography>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </div>
    )
}

export default SubmitExit