import * as React from 'react';
import { Stack, Grid, Card, CardMedia, CardContent, Box, Typography } from '@mui/material';
import creditcards from '../images/creditcards.png'
import building from '../images/building.png'
import expandphoto from '../images/expandphoto.png'
import partnerbuyout from '../images/partnerbuyout.png'
import equipment from '../images/equipment.png'
import cashregister from '../images/cashregister.png'
import buildingConstruction from '../images/buildingConstruction.png'
import businessAcquisition from '../images/businessAcquisition.png'
import esop from '../images/ESOP.png'
import startUp from '../images/startUp.png'
import MediaCard from './MediaCard';
import { useNavigate } from 'react-router-dom';

export default function LoanUsesSubNew() {
    const navigate = useNavigate()

    const handleAcquisition = () => {
        navigate('/loanuses/acquisition'); // Correctly navigate on click
    };

    const handleWorkingCapital = () => {
        navigate('/loanuses/workingcapital'); // Correctly navigate on click
    };

    

    return (
        <div style={{ marginTop: '0rem' }}>

            <Card style={{
                color: 'white',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                padding: 0,
                marginTop: 0,
                height: 'auto',
                borderRadius: 0,
                position: 'relative', // Ensure relative positioning for absolute children
                overflow: 'hidden', // Clip overflowing content (like the image)
            }}>
                {/* Content within the card */}
                <div style={{ position: 'relative', zIndex: 1, textAlign: 'center' }}>
                    <Card sx={{
                        boxShadow: 'none',
                        bgcolor: 'transparent',
                    }}>
                        <br />
                        {/* <Stack direction="row" justifyContent="center" alignItems="center" spacing={3} marginBottom={10} marginTop={5} > */}
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            
                        >
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={12} md={10}>
                                    {/* Inside this Grid item, you can place your content */}
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} lg={6}>
                                            <MediaCard image={businessAcquisition} imagename="Acquisition" text={["Business", <br />, "Acquisition"]} keyName="1" 
                                                        onClick={handleAcquisition}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={6}>
                                            <MediaCard image={expandphoto} imagename="Expansion" text={["Expansion", <br />, "& Growth"]} keyName="2" />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={6}>
                                            <MediaCard image={buildingConstruction} imagename="Construction" text={["Building", <br />, "Construction"]} keyName="3" />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={6}>
                                            <MediaCard image={creditcards} imagename="Refinance" text={["Debt", <br />, "Refinance"]} keyName="4" />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={6}>
                                            <MediaCard image={equipment} imagename="Equipment" text={["Equipment", <br />, "& Machinery"]} keyName="5" />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={6}>
                                            <MediaCard image={esop} imagename="ESOP" text={["ESOP", <br />, ""]} keyName="6" />
                                            {/* </Stack> */}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={6}>
                                            <MediaCard image={partnerbuyout} imagename="Partner" text={["Partner", <br />, "Buyout"]} keyName="6" />
                                            {/* </Stack> */}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={6}>
                                            <MediaCard image={building} imagename="Real Estate" text={["Real Estate", <br />, "Purchase"]} keyName="6" />
                                            {/* </Stack> */}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={6}>
                                            <MediaCard image={startUp} imagename="Start-Up" text={["Start-Up", <br />, "Costs"]} keyName="6" />
                                            {/* </Stack> */}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={6}>
                                            <MediaCard image={cashregister} imagename="Working Capital" text={["Working", <br />, "Capital"]} keyName="6" 
                                                    onClick={handleWorkingCapital}/>
                                            {/* </Stack> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Card>
                </div>

            </Card>
            <br />
        </div>
    );
}


