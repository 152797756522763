import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Card, Typography } from '@mui/material';
import two_paths from '../images/two_paths2.png'
import { useNavigate } from 'react-router-dom';
import HoverCard from './HoverCard';
import { useTheme } from '@mui/material/styles';
import { css } from '@emotion/react';


export default function LoanType() {

    const theme = useTheme();

    const navigate = useNavigate()
    const expressClick = () => {
        navigate('/prequalify/express')
    };
    const sbaClick = () => {
        navigate('/prequalify/standard')
    };


    // Function to determine text based on screen width
    const getText1 = () => {
        if (window.innerWidth < 775) {
            return 'ONE LOAN PROGRAM';
        } else if (window.innerWidth < 1200) {
            return 'ONE LOAN PROGRAM, TWO EASY PATHS';
        } else {
            return 'ONE LOAN PROGRAM';
        }
    };

    const getText2 = () => {
        if (window.innerWidth < 775) {
            return 'TWO EASY PATHS';
        } else if (window.innerWidth < 1200) {
            return '';
        } else {
            return 'TWO EASY PATHS';
        }
    };

    // Set initial text
    const [text1, setText1] = React.useState(getText1);
    const [text2, setText2] = React.useState(getText2);

    React.useEffect(() => {
        // Update text on window resize
        const handleResize = () => {
            setText1(getText1);
            setText2(getText2);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const highlightStyle = css`
  color: red; /* Change to your desired color */
`;

    return (
        <div style={{ marginTop: '0rem' }}>
            <Card
                sx={{
                    color: 'white',
                    backgroundColor: theme.palette.secondary.contrastText, // Fallback color
                    backgroundImage: {
                        xs: 'none', // Hide background image on extra small screens
                        md: 'none',
                        lg: `url(${two_paths})` // Show background image on small screens and larger
                    },
                    backgroundSize: 'contain', // Ensure the image scales to fit the card
                    backgroundPosition: 'right center', // Right align the background image
                    backgroundRepeat: 'no-repeat', // Prevent repeating the background image
                    boxShadow: 'none',
                    padding: 3,
                    marginTop: 10,
                    height: '100%', // Adjust height as needed
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center' // Center content vertically
                }}
            >
                <Box
                    sx={{
                        marginRight: {
                            xs: '0rem', // Extra small screens
                            sm: '0rem', // Small screens
                            md: '0rem', // Medium screens
                            lg: '20rem'  // Large screens and up
                        }
                    }}
                >
                    <br />
                    <Typography sx={{ fontSize: { xs: 28, sm: 32, md: 36, lg: 50 }, marginLeft: { xs: 5, sm: 10, md: 0, lg: 20 }, textAlign: { xs: "left", md: "center", lg: "left" }, color: theme.palette.primary.dark }} fontWeight={600}  >{text1}</Typography>
                    <Typography sx={{ fontSize: { xs: 28, sm: 32, md: 36, lg: 50 }, marginLeft: { xs: 5, sm: 10, md: 0, lg: 40 }, textAlign: { xs: "left", md: "center", lg: "left" }, color: theme.palette.primary.dark }} fontWeight={600}  >{text2}</Typography>
                    <Grid container
                        justifyContent="center"  // Center the grid horizontally
                    >
                        <Box
                            sx={{
                                boxShadow: 0,
                                width: '60rem',
                                //height: '20rem',
                                //bgcolor: 'white',
                                color: theme.palette.secondary.light,       //grey
                                p: 1,
                                m: 1,
                                //borderRadius: 2,
                                textAlign: 'center',
                                fontSize: '20px',
                                fontWeight: '700',
                                display: 'grid',
                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' },
                                gridColumnGap: '50px', // Adjust the gap between columns
                            }}
                        >
                            <HoverCard title='Express Lane SBA 7(a)'
                                point1='Up to $150,000'
                                point2='Funded within days'
                                point3='10 Year Term'
                                point4='Uses: Working Capital, Debt Refinance'
                                descr=' about Express Lane'
                                onClick={expressClick}
                                linkClick=''
                                buttonText='Prequalify for Express Lane'
                                breakLine='1'
                            />
                            <HoverCard title='Standard SBA 7(a)'
                                point1='Up to $5 million'
                                point2='Funded within weeks'
                                point3='10-25 Year Term'
                                point4='Uses: All SBA approved uses'
                                descr=' about Standard 7(a)'
                                onClick={sbaClick}
                                linkClick=''
                                buttonText='Prequalify for Standard 7(a)'
                                breakLine='1'
                            />



                        </Box>
                    </Grid>

                    <br />
                </Box>
            </Card>
        </div >
    );
}
