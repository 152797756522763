import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useState } from "react";

export default function FormPropsTextFieldsPhone({ contactInfo, setContactInfo, qstepdata, setQStepData }, phoneError, autoComplete, id) {

    const handleInput = e => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value)
        setContactInfo({
            ...contactInfo
            ,phone: formattedPhoneNumber
        })
        /* setQStepData({
            ...qstepdata
            ,owner1Phone: formattedPhoneNumber
        }) */
    }

    //return <input onChange={e => handleInput(e)} value={phoneInput} />;

    function formatPhoneNumber(value) {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6,)}-${phoneNumber.slice(6, 10)}`;
    }

    return (
        <Box
            //component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: {xs: 209, sm: 225} } //width: '35ch' },
            }}
            noValidate
            //autoComplete="off"
        >
            <div>
                <TextField
                    required
                    id={id}
                    label="Phone"
                    autoComplete={autoComplete}
                    value={contactInfo.phone}
                    defaultValue={contactInfo.phone}
                    onChange={e => handleInput(e)}
                    InputLabelProps={{ shrink: true }}
                    error={contactInfo.phoneError}
                    onBlur={() => {
                        contactInfo.phone.length != 14 ? setContactInfo({
                                ...contactInfo,
                                phoneError: true
                            }) : setContactInfo({
                                ...contactInfo,
                                phoneError: false
                            })

                    }}
                />
            </div>
        </Box>
    )

}






