import React from 'react';
import { useEffect, useState } from "react";
import { Stack, Typography, Grid, Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import InputText from './InputText'
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import StateDropDown from './StateDropDown';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Helmet } from 'react-helmet-async'

import ReactGA from "react-ga4"

let cnt = 0



function CreditPull({ contactInfo, setContactInfo, data, setData, qstepdata, setQStepData
            , creditResult, setCreditResult, realEstate }) {

    ReactGA.send({
        hitType: "pageview",
        page: "/credit",
        title: "Credit",
      })

    const theme = useTheme();

    const [loading, setLoading] = React.useState(false);

    function formatAddress1(value) {
        if (!value) return value;
        const Address1 = value.replace(/[^\w\s,#.\'\-]/g, '');
        const Address1Length = Address1.length;
        if (Address1Length <= 40) {
            return Address1;
        }
        return `${Address1.slice(0, 40)}`;
    }

    function formatAddress2(value) {
        if (!value) return value;
        const Address2 = value.replace(/[^\w\s,#.\'\-]/g, '');
        const Address2Length = Address2.length;
        if (Address2Length <= 20) {
            return Address2;
        }
        return `${Address2.slice(0, 20)}`;
    }

    function formatZip(value) {
        if (!value) return value;
        const Zip = value.replace(/[^\d]/g, '');
        const ZipLength = Zip.length;
        if (ZipLength <= 5) {
            return Zip;
        }
        return `${Zip.slice(0, 5)}`;
    }

    const handleHomeAddress = e => {
        const formattedAddress1 = formatAddress1(e.target.value)
        setContactInfo({
            ...contactInfo
            , address: formattedAddress1
        })
        setQStepData({
            ...qstepdata
            , owner1HomeAddress1: formattedAddress1
        })
    }

    const handleCity = e => {
        const formattedAddress2 = formatAddress2(e.target.value)
        setContactInfo({
            ...contactInfo
            , city: formattedAddress2
        })
        setQStepData({
            ...qstepdata
            , owner1City: formattedAddress2
        })
    }

    const handleState = e => {
        //const formattedAddress2 = formatAddress2(e.target.value)
        setContactInfo({
            ...contactInfo
            //, state: formattedAddress2
            , state: (e.target.value)
        })
        setQStepData({
            ...qstepdata
            , owner1State: (e.target.value)
        })
    }

    const handleZip = e => {
        const formattedZip = formatZip(e.target.value)
        setContactInfo({
            ...contactInfo
            , zip: formattedZip
        })
        setQStepData({
            ...qstepdata
            , owner1Zip: formattedZip
        })
    }


    let creditresult = 4
    const navigate = useNavigate();
    const handleClick = e => {
        //console.log(contactInfo.ssn.replaceAll("-", ""))
        setLoading(true)

        //console.log('qstepdata 1: ', qstepdata)
        fetch('https://6bkzudm979.execute-api.us-east-1.amazonaws.com/v1', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                first_name: contactInfo.firstname.replace(/ +(?= )/g, '').trim(),
                last_name: contactInfo.lastname.replace(/ +(?= )/g, '').trim(),
                address: contactInfo.address.replace(/ +(?= )/g, '').trim(),
                city: contactInfo.city.replace(/ +(?= )/g, '').trim(),
                state: contactInfo.state,
                zip: contactInfo.zip,
                ssn: contactInfo.ssn.replaceAll("-", ""),
                date_of_birth: contactInfo.dob,
                dateTime: contactInfo.datetimeadded,
                email: contactInfo.email,
                business_name: contactInfo.businessname.replace(/ +(?= )/g, '').trim(),
                amount: data.amount,
                interest: data.interest,
                margin: data.margin,
                term: data.term,
                phone: contactInfo.phone,
                real_estate: data.real_estate,
                payment: data.payment,
                apiinputpk: data.apiinputpk,
                appdemo: 1,
                minCredit: data.minCredit,
                reactKey: data.reactKey
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                console.log('response: ', response);
                return response.json();
            })
            .then(data => {
                // Update state with the API response data
                console.log('data: ', data)
                //console.log('data.result: ', data.result)
                //console.log('data.name: ', data.name)
                data.creditpass == 1 ? creditresult = 1 :
                    data.frozen == 1 ? creditresult = 2 :
                        data.nomatch == 1 || data.noscore == 1 ? creditresult = 3 : 
                        data.lowscore == 1 ? creditresult = 5 : creditresult = 0;
            })
            .catch(error => {
                // Handle errors
                console.error('There was a problem with the request:', error);
            })
            .then(() => {
                console.log('creditresult: ',creditresult)
                switch (creditresult) {
                    case 5: navigate('/concl'); break;
                    case 4: navigate('/noscore'); break;
                    case 3: navigate('/noscore'); break;
                    case 2: navigate('/frozen'); break;
                    case 1: navigate('/congrats'); break;
                    case 0: navigate('/noscore'); break;
                }
            });
    

        //console.log('start secong fetch')    

        fetch('https://ry2meyf401.execute-api.us-east-1.amazonaws.com/v1', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                clienturl: data.clienturl,
                dateTime: contactInfo.datetimeadded,
                email: contactInfo.email
            })
        })
            .then(response1 => {
                console.log('response: ', response1);
                return response1.json();
            })
            .then(data1 => {
                // Update state with the API response data
                console.log('data: ', data1)
                //console.log('data.result: ', data.result)
                console.log('data response: ', data1)
                console.log('data response customurl: ', data1.CustomURL)
                setData({ ...data, customurl: data1.CustomURL, finishCredit: true })
            })
            .catch(error => {
                // Handle errors
                console.error('There was a problem with the request:', error);
            })

    }

    const handleInput = e => {
        const formattedSSN = formatSSN(e.target.value)
        setContactInfo({
            ...contactInfo
            , ssn: formattedSSN
        })
        setQStepData({
            ...qstepdata
            , owner1SSN: formattedSSN
        })
        //console.log(contactInfo.dob)
    }

    function formatSSN(value) {
        if (!value) return value;
        const socialsn = value.replace(/[^\d]/g, '');
        const socialsnLength = socialsn.length;
        if (socialsnLength < 4) return socialsn;
        if (socialsnLength < 6) {
            return `${socialsn.slice(0, 3)}-${socialsn.slice(3)}`;
        }
        return `${socialsn.slice(0, 3)}-${socialsn.slice(3, 5,)}-${socialsn.slice(5, 9)}`;
    }

    function isValidDate(dateString) {
        var regEx = /^\d{4}-\d{2}-\d{2}$/;
        if (!dateString.match(regEx)) return false;  // Invalid format
        var d = new Date(dateString);
        var dNum = d.getTime();
        if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
        return d.toISOString().slice(0, 10) === dateString;
    }

    function CreditButton() {
        if (contactInfo.ssn.length == "11"
            && contactInfo.dob != ""
            && contactInfo.address != ""
            && contactInfo.city != ""
            && contactInfo.state != ""
            && contactInfo.zip.length == "5"
            && contactInfo.ssnconsent == true
        ) {
            return (
                <Stack spacing={2}
                    direction="row"
                    alignItems='center'
                    justifyContent='center'
                    mt={3}
                    mb={3}>
                    <LoadingButton variant="contained"
                        loading={loading}
                        sx={{
                            fontWeight: 600, color: 'white', backgroundColor: data.colorfont,
                            '&:hover': {
                                backgroundColor: data.colorfont,
                                opacity: 0.70,
                            }
                        }}
                        onClick={handleClick}
                    >
                        <span>Run Credit Check</span>
                    </LoadingButton>
                </Stack>
            )
        } else {
            return (
                <Stack spacing={2}
                    direction="row"
                    alignItems='center'
                    justifyContent='center'
                    mt={3}
                    mb={3}>
                    <LoadingButton variant="contained"
                        disabled
                        sx={{
                            fontWeight: 600, color: 'white', backgroundColor: data.colorfont,
                            '&:hover': {
                                backgroundColor: data.colorfont,
                                opacity: 0.70,
                            }
                        }}
                        onClick={handleClick}
                    >Run Credit Check
                    </LoadingButton>
                </Stack>
            )
        }
    }

    function dateAdd(days, months, years) {
        var date = new Date();
        date.setDate(date.getDate() + days);
        date.setMonth(date.getMonth() + months);
        date.setFullYear(date.getFullYear() + years);
        return date;
    }

    const handleDateFieldBlur = () => {
        contactInfo.dob <= dateAdd(0, 0, -18) && contactInfo.dob >= dateAdd(0, 0, -110) && contactInfo.dob != "" ?
            setContactInfo({
                ...contactInfo,
                dobError: false
            }) : setContactInfo({
                ...contactInfo,
                dobError: true
            })
    };


    useEffect(() => {
        cnt++
        cnt > 2 ? handleDateFieldBlur() : console.log('useeffect pass')
        console.log('useeffect')
    }, [contactInfo.dob])

    const bordercolor = data.colorfont
    const style = {
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: data.colorfont
            }
        },
        '& label.Mui-focused': {
            color: data.colorfont,
        }
    }

    return (
        <div style={{ marginTop: '40px', marginLeft: '50px' }} className="Credit">
            <Helmet>
                <title>CapTec USA | Run Credit</title>
                <meta name="description" content="Prequalify for an SBA with a soft credit check that won't impact your credit." />
                <link rel="canonical" href="https://thenewsouthbank.com/credit" />
            </Helmet>
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >

                <Box sx={{
                    width: '70vw',
                    height: '5rem',
                    borderBottom: 1,
                    textAlign: 'left',
                    m: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                }}>
                    <Typography fontSize={30} fontWeight={500} color={theme.palette.primary.main}    //blue
                    //style={{ paddingBottom: '1rem' }} {/* Adjust padding to control distance from bottom 
                    >
                        Run Credit Check
                    </Typography>
                </Box>

                <br />
                <Typography sx={{
                    fontSize: { xs: 18, sm: 18, md: 20, lg: 22 },
                    paddingRight: { xs: '50px', sm: '80px', md: '100px', lg: '300px' },
                }}
                    fontWeight={500}
                    color={'secondary.light'}    //blue

                //style={{ paddingBottom: '1rem' }} {/* Adjust padding to control distance from bottom 
                >
                    Next we'll run a soft pull for your credit. Don't worry, this won't impact your credit score.
                </Typography>
                <Typography sx={{
                    fontSize: { xs: 16, sm: 16, md: 18, lg: 20 },
                    paddingRight: { xs: '50px', sm: '80px', md: '100px', lg: '300px' },
                    transform: 'translateY(-15px)',
                }}
                    fontWeight={500}
                    color={'secondary.main'}    //blue

                //style={{ paddingBottom: '1rem' }} {/* Adjust padding to control distance from bottom 
                >
                    Any 9-digit SSN number will be sufficient to proceed to the next step of the demonstration.
                </Typography>
                <br />
                <Grid container
                    direction="row"
                    justifyContent={{ xs: 'flex-start', sm: 'center' }}
                    sx={{
                        marginLeft: {
                            xs: 0,
                            sm: '-10vw !important',
                            md: '-15vw !important',
                            lg: '-20vw !important',
                        },
                    }}
                >
                    <Grid item xs={10} sm={8} md={6} >
                        <Stack direction="column" justifyContent={'center'} alignItems="center" sx={{ width: 'auto' }} >
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: { xs: 209, sm: 225 } } //width: '35ch' },
                                }}
                                noValidate
                                autoComplete="off"
                                alignItems="center"
                                display="flex"
                                justifyContent='center'
                            >
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Social Security Number"
                                    type="text"
                                    value={contactInfo.ssn}
                                    defaultValue={contactInfo.ssn}
                                    onChange={e => handleInput(e)}
                                    InputLabelProps={{ shrink: true }}
                                    error={contactInfo.ssnError}
                                    sx={style}
                                    onBlur={() => {
                                        contactInfo.ssn.length != 11 ? setContactInfo({
                                            ...contactInfo,
                                            ssnError: true
                                        }) : setContactInfo({
                                            ...contactInfo,
                                            ssnError: false
                                        })

                                    }}
                                />
                            </Box>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: { xs: 209, sm: 225 } } //width: '35ch' },
                                }}
                                noValidate
                                autoComplete="off"
                                alignItems="center"
                                display="flex"
                                justifyContent='center'
                            >
                                <LocalizationProvider dateAdapter={AdapterDayjs}
                                    label="Responsive variant"
                                >
                                    {/* label options are "Responsive variant"
                                                            , "Desktop variant"
                                                            , "Mobile variant"*/}
                                    <DatePicker
                                        label="Date of Birth"
                                        sx={style}
                                        slotProps={{
                                            openPickerButton: {}
                                            , textField: {
                                                InputLabelProps: { shrink: true }
                                                , required: true
                                                , error: contactInfo.dobError
                                                , onBlur: handleDateFieldBlur
                                            }
                                        }}
                                        maxDate={dayjs(dateAdd(0, 0, -(data.minage)))}
                                        minDate={dayjs(dateAdd(1, 0, -(data.maxage)))}
                                        value={contactInfo.dob}
                                        onChange={(newValue) => {
                                            //console.log('hello', contactInfo.dob)
                                            setContactInfo({
                                                ...contactInfo,
                                                dob: (newValue)
                                            })
                                        }}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <InputText label="Home Address"
                                type="text"
                                value={contactInfo.address}
                                error={contactInfo.addressError}
                                autoComplete="street-address"
                                id="street-address"
                                onChange={e => handleHomeAddress(e)}
                                color={data.colorfont}
                                onBlur={() => {
                                    contactInfo.address == "" ? setContactInfo({
                                        ...contactInfo,
                                        addressError: true
                                    }) : setContactInfo({
                                        ...contactInfo,
                                        addressError: false
                                    })
                                }}
                            />
                            <InputText label="City"
                                type="text"
                                value={contactInfo.city}
                                error={contactInfo.cityError}
                                autoComplete="address-level2"
                                id="address-level2"
                                onChange={e => handleCity(e)}
                                color={data.colorfont}
                                onBlur={() => {
                                    contactInfo.city == "" ? setContactInfo({
                                        ...contactInfo,
                                        cityError: true
                                    }) : setContactInfo({
                                        ...contactInfo,
                                        cityError: false
                                    })
                                }}
                            />
                            <StateDropDown
                                propsValue={contactInfo.state}
                                autoComplete="address-level1"
                                id="address-level1"
                                propsError={contactInfo.stateError}
                                color={data.colorfont}
                                onChange={e => {
                                    setContactInfo({
                                        ...contactInfo
                                        , state: (e.target.value)
                                    })
                                    setQStepData({
                                        ...qstepdata
                                        , owner1State: (e.target.value)
                                    })
                                }}
                                onBlur={() => {
                                    setContactInfo({
                                        ...contactInfo,
                                        stateError: contactInfo.state == "" ? true : false
                                    })
                                }}
                            />
                            <InputText label="Zip Code"
                                type="text"
                                value={contactInfo.zip}
                                error={contactInfo.zipError}
                                autoComplete="postal-code"
                                id="postal-code"
                                onChange={e => handleZip(e)}
                                color={data.colorfont}
                                onBlur={() => {
                                    contactInfo.zip.length != 5 ? setContactInfo({
                                        ...contactInfo,
                                        zipError: true
                                    }) : setContactInfo({
                                        ...contactInfo,
                                        zipError: false
                                    })
                                }}
                            />
                        </Stack>
                        <br />
                        <Stack direction="row" justifyContent={'center'} alignItems="center">
                            <Stack direction="row" justifyContent={'center'} alignItems="center" sx={{ width: 500 }}>
                                <FormGroup>
                                    <FormControlLabel
                                        value={contactInfo.ssnconsent}
                                        control={<Checkbox checked={contactInfo.ssnconsent}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setContactInfo({
                                                    ...contactInfo,
                                                    ssnconsent: event.target.checked
                                                })
                                            }}
                                            color="default" />}
                                        label=""
                                    />
                                </FormGroup>
                                <Typography>
                                    I understand that by clicking the "Run Credit Check" button,
                                    I am providing "written instruction" under the FCRA authorizing {data.clientname} to
                                    obtain personal credit & other information from iSoftpull, Experian, TransUnion, and/or Equifax
                                    solely for credit pre-qualification. This process will not affect my credit score.
                                    {/*I also give permission for my contact information to be given to a third party lender, if applicable.*/}
                                </Typography>
                            </Stack>
                        </Stack>
                        <br />
                        <CreditButton />
                        <Stack direction="row" justifyContent={'center'} alignItems="center">
                            <Stack direction="row" justifyContent={'center'} alignItems="center" sx={{ width: 500 }}>
                                <Typography fontSize={12} align="center">
                                    Please note, submitting this form is an initial step and does not constitute an official loan application with {data.clientname}.
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
            <br />
            <br />
        </div >
    )
}

export default CreditPull




