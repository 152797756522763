import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar, Typography, Button, MenuItem, useMediaQuery } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import MenuIcon from '@mui/icons-material/Menu';
import capteclogo from '../images/capteclogo3.png'
import Menu1 from './Menu1'
import Menu2 from './Menu2'
import Menu3 from './Menu3NoDropDown'
import MenuAmberly from './MenuAmberly'
import MenuLoans from './MenuLoans'
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import { motion, useMotionValueEvent, useScroll, useAnimation } from 'framer-motion';


const pages = ['Why SBA', 'What to Expect', 'Forms', 'Referral Partners'];

function NavBar({ creditResult, data, navBarHeight, setNavBarHeight, navBarHeight2, setNavBarHeight2,
    showNavbar, setShowNavbar
}) {

    const theme = useTheme();

    const navigate = useNavigate()

    const homeClick = () => {
        navigate('/')
    };
    const aboutClick = () => {
        navigate('/about')
    };
    const contactClick = () => {
        navigate('/contact')
    };
    const whysbaClick = () => {
        navigate('/sbaexplained')
    };
    const expectClick = () => {
        navigate('/process')
    };
    const learningClick = () => {
        navigate('/learning')
    };
    const faqClick = () => {
        navigate('/faq')
    };
    const industryClick = () => {
        navigate('/industry')
    };

    ////////////////////// set navBarHeight
    const navBarRef = useRef(null)
    const navBarRef2 = useRef(null)

    const updateNavBarHeight = () => {
        if (navBarRef.current) {
            setNavBarHeight(navBarRef.current.offsetHeight);
        }
    };

    const updateNavBarHeight2 = () => {
        if (navBarRef2.current) {
            setNavBarHeight2(navBarRef2.current.offsetHeight);
        }
    };

    useEffect(() => {

        const updateNavBarHeightFinal = () => {
            const height = navBarHeight + navBarHeight2
            setNavBarHeightFinal(height);

            //console.log('navBarHeightFinal: ', navBarHeightFinal)
        }

        updateNavBarHeightFinal()
    }, [navBarHeight, navBarHeight2]);


    useLayoutEffect(() => {

        const handleResize = () => {
            updateNavBarHeight();
            updateNavBarHeight2()
        };

        updateNavBarHeight();
        updateNavBarHeight2()

        // Optionally update height on window resize
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isSmallScreen = useMediaQuery('(max-width:1100px)');

    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [scrolledEnough, setScrolledEnough] = useState(false);
    const [navBarHeightFinal, setNavBarHeightFinal] = useState(0)

    const handleScroll = () => {
        const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
        //console.log('currentScrollTop: ', currentScrollTop)

        // Determine if the page has been scrolled enough to toggle the navbar
        if (currentScrollTop > 150) {
            setScrolledEnough(true);
        } else {
            setScrolledEnough(false);
        }

        if (isSmallScreen) {
            if (scrolledEnough) {
                if (currentScrollTop > (lastScrollTop)) {
                    // Scrolling down
                    setShowNavbar(false);
                } else {
                    // Scrolling up
                    setShowNavbar(true);
                }
            }
        } else {
            // For larger screens, always show the navbar
            setShowNavbar(true);
        }

        setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        };
    }, [lastScrollTop, scrolledEnough]);

    /*const isXSmall = useMediaQuery('(max-width:600px)');
    const isSmall = useMediaQuery('(max-width:900px)');
    const isMedium = useMediaQuery('(max-width:1200px)');



    const navbarHeightFinal3 = isXSmall ? '-89px' :
        isSmall ? '-105px' :
        isMedium ? '-104px' :
        '-104px';

    const navbarHeightFinal4 = navBarHeightFinal ? (typeof navBarHeightFinal === 'number' ? `${-navBarHeightFinal}px` : -navBarHeightFinal) : '0px'; */

    const navbarHeightFinal2 = '-105px'
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);


    const navbarStyle = {
        position: 'fixed',
        top: 0,
        width: '100%',
        backgroundColor: '#333', // Example background color
        color: '#fff', // Example text color
        //padding: '10px', // Example padding
        zIndex: 1000, // Ensure the navbar is on top of other content
        transition: 'transform 0.3s ease',
        //transform: showNavbar ? 'translateY(0)' : `translateY(${navbarHeightFinal2})`,
        //...(isSafari ? { transform: 'none' } : { transform: showNavbar ? 'translateY(0)' : `translateY(${navbarHeightFinal2})` }),
        //transform: showNavbar ? 'translateY(0)' : `translateY(${navbarHeightFinal2})`,
        //WebkitTransform: showNavbar ? 'translateY(0)' : `translateY(${navbarHeightFinal2})`, // Safari-specific prefix
        ...(isSafari
            ? { WebkitTransform: showNavbar ? 'translateY(0)' : `translateY(${navbarHeightFinal2})` }
            : { transform: showNavbar ? 'translateY(0)' : `translateY(${navbarHeightFinal2})` }
        ),
    };


    return (
        <div style={navbarStyle} ref={navBarRef}> {/* style={navbarStyle} */}
            <Box className="NavBar" sx={{ display: 'flex', justifyContent: 'flex-start', height: { xs: '44px', sm: '64px' } }}>

                <CssBaseline />
                <AppBar position="fixed"
                    elevation={0}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start', bgcolor: "white"
                        , width: '100vw'
                        , height: { xs: '54px', sm: '64px' }
                    }}
                    className='NavBar2'
                    style={{ position: 'absolute', left: 0 }}>
                    <Toolbar
                        className='ToolBarAmberly'
                        sx={{
                            padding: 0,
                            width: '100vw', // Full width of viewport
                            display: 'flex',
                            justifyContent: 'space-between', // Items split as far apart as possible
                            backgroundColor: 'white',
                            paddingRight: { xs: 2, sm: 4 },
                        }}
                        style={{ position: 'fixed', left: 0 }}>
                        <Typography
                            variant="h6"
                            component="div"
                        //sx={{ flexGrow: 1, display: 'block' }}
                        >
                            <Button onClick={homeClick} disableRipple
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: 'transparent', // Lighter background color on hover
                                    },
                                    width: { xs: '150px', md: '280px' },
                                    paddingBottom: 0,
                                }}>
                                <img src={capteclogo}
                                    alt='United Trust Bank'
                                    width="100%"
                                />
                            </Button>
                        </Typography>
                        <Box className='Box2'
                            sx={{
                                //display: { xs: 'none', sm: 'block' },
                                //flexGrow: 1,
                                display: 'flex',
                                justifyContent: 'flex-end', // Align items to the right
                                width: { xs: '100vw - 150px', md: '100vw - 280px' }
                            }}
                        >
                            <Button
                                onClick={homeClick}
                                sx={{
                                    display: { xs: 'none', sm: 'flex' },
                                    color: theme.palette.secondary.light,
                                    fontWeight: 700,
                                    '&:hover': {
                                        backgroundColor: alpha(theme.palette.primary.main, 0.2), // 20% opacity
                                    },
                                    paddingX: { xs: 0.5, sm: 1 },
                                    minHeight: 0,
                                    minWidth: 0,
                                }}>
                                <Typography sx={{ fontSize: { xs: 11, md: 14 } }} fontWeight={700}>   {/* dark blue */}
                                    Home
                                </Typography>
                            </Button>
                            <Button href='https://thenewsouthbank.com/about'
                                className='AboutButtonHref'
                                sx={{
                                    display: { xs: 'none', sm: 'flex' },
                                    color: theme.palette.secondary.light,
                                    fontWeight: 700,
                                    '&:hover': {
                                        backgroundColor: alpha(theme.palette.primary.main, 0.2), // 20% opacity
                                    },
                                    paddingX: { xs: 0.5, sm: 1 },
                                    minHeight: 0,
                                    minWidth: 0,
                                }}>
                                <Typography sx={{ fontSize: { xs: 11, md: 14 } }} fontWeight={700}>   {/* dark blue */}
                                    About
                                </Typography>
                            </Button>
                            <Button onClick={contactClick}
                                sx={{
                                    display: { xs: 'none', sm: 'flex' },
                                    color: theme.palette.secondary.light,
                                    fontWeight: 700,
                                    '&:hover': {
                                        backgroundColor: alpha(theme.palette.primary.main, 0.2), // 20% opacity
                                    },
                                    paddingX: { xs: 0.5, sm: 1 },
                                    minHeight: 0,
                                    minWidth: 0,
                                }}>
                                <Typography sx={{ fontSize: { xs: 11, md: 14 } }} fontWeight={700}>   {/* dark blue */}
                                    Contact
                                </Typography>
                            </Button>
                            <Menu3 creditResult={creditResult} data={data} />
                        </Box>
                    </Toolbar>
                </AppBar>
                <Toolbar /> {/* Ensure content below the fixed AppBar */}
                <Box
                    sx={{
                        //position: 'fixed',
                        top: { xs: '44px', sm: '64px' },
                        width: '100vw',
                        //display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        //height: 40,
                        backgroundColor: theme.palette.primary.main,   //blue
                        zIndex: 1000, // Ensure the menu bar is above other content
                        //overflow: 'hidden',
                    }}
                >
                    <Grid ref={navBarRef2}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            position: 'fixed',
                            top: { xs: '54px', sm: '64px' },
                            left: 0,
                            width: '100vw',
                            height: { xs: 41, md: 40 },
                            backgroundColor: theme.palette.primary.dark,
                            zIndex: 1000,
                            paddingLeft: '0',
                            paddingRight: '10px',
                        }}
                    >
                        <Grid container direction="row" width={'auto'} ml={'10px'} > {/* sx={{ zIndex: 2000 }}> */}
                            <MenuLoans />
                            <Grid sx={{
                                color: 'white',
                                backgroundColor: 'transparent',
                                height: 40,
                                border: 'none',
                                //margin: '10 10px',
                                fontSize: 16,
                                fontWeight: 500,
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: '#FFFFFF20', // Lighter background color on hover
                                },
                                display: { xs: 'inline', md: 'none' },
                            }}>
                                <MenuAmberly />
                            </Grid>
                            <Button
                                onClick={whysbaClick}
                                sx={{
                                    color: 'white',
                                    backgroundColor: 'transparent',
                                    height: 40,
                                    border: 'none',
                                    //marginLeft: '10px',
                                    fontSize: 16,
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#FFFFFF20', // Lighter background color on hover
                                    },
                                    display: { xs: 'none', md: 'inline' },
                                }}
                            >
                                <Typography sx={{
                                    fontSize: { sm: 14, md: 14 },
                                    textTransform: 'none' // Prevent text from being capitalized
                                }}
                                    fontWeight={600}>   {/* dark blue */}
                                    SBA 7(a) EXPLAINED
                                </Typography>
                            </Button>
                            <Button
                                onClick={expectClick}
                                sx={{
                                    color: 'white',
                                    backgroundColor: 'transparent',
                                    height: 40,
                                    border: 'none',
                                    margin: '0 0px',
                                    fontSize: 16,
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#FFFFFF20', // Lighter background color on hover
                                    },
                                    display: { xs: 'none', md: 'inline' },
                                }}
                            >
                                <Typography sx={{ fontSize: { sm: 14, md: 14 } }} fontWeight={600}>   {/* dark blue */}
                                    Our Process
                                </Typography>
                            </Button>
                            <Button
                                onClick={industryClick}
                                sx={{
                                    color: 'white',
                                    backgroundColor: 'transparent',
                                    height: 40,
                                    border: 'none',
                                    margin: '0 0px',
                                    fontSize: 16,
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#FFFFFF20', // Lighter background color on hover
                                    },
                                    display: { xs: 'none', md: 'inline' },
                                }}
                            >
                                <Typography sx={{ fontSize: { sm: 14, md: 14 } }} fontWeight={600}>   {/* dark blue */}
                                    INDUSTRY RESOURCES
                                </Typography>
                            </Button>
                            <Button
                                onClick={faqClick}
                                sx={{
                                    color: 'white',
                                    backgroundColor: 'transparent',
                                    height: 40,
                                    border: 'none',
                                    margin: '0 0px',
                                    fontSize: 16,
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#FFFFFF20', // Lighter background color on hover
                                    },
                                    display: { xs: 'none', md: 'inline' },
                                }}
                            >
                                <Typography sx={{ fontSize: { sm: 14, md: 14 } }} fontWeight={600}>   {/* dark blue */}
                                    FAQs
                                </Typography>
                            </Button>
                            <Button
                                onClick={learningClick}
                                sx={{
                                    color: 'white',
                                    backgroundColor: 'transparent',
                                    height: 40,
                                    border: 'none',
                                    margin: '0 0px',
                                    fontSize: 16,
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#FFFFFF20', // Lighter background color on hover
                                    },
                                    display: { xs: 'none', md: 'inline' },
                                }}
                            >
                                <Typography variant="h6" sx={{ fontSize: { sm: 14, md: 14 } }} fontWeight={600}>   {/* dark blue */}
                                    Learning Center
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid container direction="row" width={'auto'} ml={'10px'} sx={{ zIndex: 2000 }}>
                            <Button
                                onClick={homeClick}
                                sx={{
                                    display: { xs: 'flex', sm: 'none' },
                                    color: 'white',
                                    fontWeight: 700,
                                    '&:hover': {
                                        backgroundColor: alpha(theme.palette.primary.main, 0.2), // 20% opacity
                                    },
                                    paddingX: { xs: 0.5, sm: 1 },
                                    minHeight: 0,
                                    minWidth: 0,
                                }}>
                                <Typography sx={{ fontSize: { xs: 11, md: 14 } }} fontWeight={700}>   {/* dark blue */}
                                    Home
                                </Typography>
                            </Button>
                            <Button href='https://thenewsouthbank.com/about'
                                className='AboutButtonHref'
                                sx={{
                                    display: { xs: 'flex', sm: 'none' },
                                    color: 'white',
                                    fontWeight: 700,
                                    '&:hover': {
                                        backgroundColor: alpha(theme.palette.primary.main, 0.2), // 20% opacity
                                    },
                                    paddingX: { xs: 0.5, sm: 1 },
                                    minHeight: 0,
                                    minWidth: 0,
                                }}>
                                <Typography sx={{ fontSize: { xs: 11, md: 14 } }} fontWeight={700}>   {/* dark blue */}
                                    About
                                </Typography>
                            </Button>
                            <Button onClick={contactClick}
                                sx={{
                                    display: { xs: 'flex', sm: 'none' },
                                    color: 'white',
                                    fontWeight: 700,
                                    '&:hover': {
                                        backgroundColor: alpha(theme.palette.primary.main, 0.2), // 20% opacity
                                    },
                                    paddingX: { xs: 0.5, sm: 1 },
                                    minHeight: 0,
                                    minWidth: 0,
                                }}>
                                <Typography sx={{ fontSize: { xs: 11, md: 14 } }} fontWeight={700}>   {/* dark blue */}
                                    Contact
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {/*  </motion.div> */}

            </Box>
        </div>
    );
}

NavBar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default NavBar;





