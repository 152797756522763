import * as React from 'react';
import { Stack, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function LoanUsesNewDetail({ icon, descr }) {
    const theme = useTheme();


    return (


        <Grid item xs={5} sm={3.5} md={2.5} lg={2} margin={1}>
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {icon}
                <Typography color={theme.palette.secondary.light} align="center" fontWeight={500}
                    sx={{ fontSize: { xs: 16, sm: 20, md: 24, lg: 24 } }}
                >
                    {descr}
                </Typography>
            </Stack>
        </Grid>
    )
}