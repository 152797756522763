import { Stack, Typography, Grid } from "@mui/material";

function SubmitReturn({ data }) {
    return (
        <div>
            <Grid container
                direction="row"
                justifyContent="center"
            >
                <Grid item xs={10} sm={8} md={5} >
                    <br />
                    <Stack mt={0}>
                        <Stack alignItems="center"  >
                            <Typography variant="h4" align="center" sx={{ fontWeight: 700, color: data.colorstep }}>
                                Thank you!
                            </Typography>
                            <br />
                            <br />
                            <Typography variant="h6" align="center">
                                We have received your submission.
                            </Typography>
                            <br />
                            <Typography variant="h6" align="center">
                                Our team will review your submission in detail and provide you with next steps within <b>3 business days</b>.
                            </Typography>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </div>
    )
}

export default SubmitReturn