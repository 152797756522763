
import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

export default function BasicButtons(props) {

  return (
    <Stack spacing={2} direction="row" alignItems={'center'} justifyContent={'center'}>
      <Button variant="contained"
        sx={{
          backgroundColor: props.color, color: props.textcolor,
          '&:hover': {
            backgroundColor: props.hoverColor, // Add hover color here
          },
        }}
        onClick={props.onClick}
      >{props.text}</Button>
    </Stack>
  );
}