import * as React from 'react';
import { Box, Typography, Link } from '@mui/material';
import PropTypes from 'prop-types';
import ButtonComp from './ButtonComp'
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import { css } from '@emotion/react';


export default function HoverCard({ title, point1, point2, point3, point4, descr, onClick, linkClick, buttonText, breakLine }) {

    const theme = useTheme();

    function Item(props) {

        const { sx, ...other } = props;
        return (
            <Box
                sx={{
                    bgcolor: 'white',
                    //border: 2,
                    //borderColor: '#08256C',
                    //boxShadow: isHovered ? '6px 6px 12px rgba(0, 0, 0, 0.4)' : '4px 4px 8px rgba(0, 0, 0, 0.2)',
                    boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2)',
                    borderRadius: 2,
                    p: 2,
                    paddingBottom: 3,
                    m: 1,
                    //fontSize: '0.875rem',
                    //fontWeight: '700',
                    ...sx,
                }}
                {...other}
                //onMouseEnter={handleMouseEnter}
                //onMouseLeave={handleMouseLeave}
            />
        );
    }

    Item.propTypes = {
        /**
         * The system prop that allows defining system overrides as well as additional CSS styles.
         */
        sx: PropTypes.oneOfType([
            PropTypes.arrayOf(
                PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
            ),
            PropTypes.func,
            PropTypes.object,
        ]),
    };


    /* const [isHovered, setIsHovered] = React.useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    }; */

    return (
        <Item>
            <Typography fontSize={30} fontWeight={650} color={theme.palette.secondary.main}>   {/* red */}
                {title}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', margin: 2 }}>
                <TaskAltIcon sx={{ marginRight: '10px', color: theme.palette.primary.main }} /> {/* Adjust margin as needed */}
                <Box sx={{
                    width: 300,
                    borderBottom: 1,
                    borderColor: alpha(theme.palette.secondary.light, 0.8)  //grey
                }}>
                    <Typography fontSize={16} fontWeight={550} color={theme.palette.secondary.light} align='left' >
                        {point1}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', margin: 2 }}>
                <TaskAltIcon sx={{ marginRight: '10px', color: theme.palette.primary.main }} /> {/* Adjust margin as needed */}
                <Box sx={{
                    width: 300,
                    borderBottom: 1,
                    borderColor: alpha(theme.palette.secondary.light, 0.8)  //grey
                }}>
                    <Typography fontSize={16} fontWeight={550} color={theme.palette.secondary.light} align='left' >
                        {point2}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', margin: 2 }}>
                <TaskAltIcon sx={{ marginRight: '10px', color: theme.palette.primary.main }} /> {/* Adjust margin as needed */}
                <Box sx={{
                    width: 300,
                    borderBottom: 1,
                    borderColor: alpha(theme.palette.secondary.light, 0.8)  //grey
                }}>
                    <Typography fontSize={16} fontWeight={550} color={theme.palette.secondary.light} align='left' >
                        {point3}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', margin: 2 }}>
                <TaskAltIcon sx={{ marginRight: '10px', color: theme.palette.primary.main }} /> {/* Adjust margin as needed */}
                <Box sx={{
                    width: 300,
                    borderBottom: 1,
                    borderColor: alpha(theme.palette.secondary.light, 0.8)  //grey
                }}>
                    <Typography fontSize={16} fontWeight={550} color={theme.palette.secondary.light} align='left' >
                        {point4}
                    </Typography>
                </Box>
            </Box>
            {breakLine == 1 ? <hr style={{
          border: 'none',
          //borderTop: '1px solid gray', // Customize thickness and color here
          margin: '18px 0', // Adjust margin as needed
        }} /> : <></>}
            <Link href={linkClick} underline="hover" sx={{ cursor: 'pointer' }} >
                <Typography fontSize={16} fontWeight={550} color={theme.palette.secondary.light} margin={'20px'} sx={{ textAlign: 'left' }}>   
                <span style={{ color: theme.palette.primary.main }}>LEARN MORE</span> {descr}
                </Typography>
            </Link>
            <ButtonComp color={theme.palette.secondary.main} textcolor='white' text={buttonText} onClick={onClick} 
                        hoverColor={alpha(theme.palette.secondary.main,0.5)}/>
        </Item>
    );
}