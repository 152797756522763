import React, { useState, useEffect } from 'react';
import { Typography, Stack, Card, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Timeline from './Timeline';
import imagelarge from '../images/image7alarge2.png'
import imagemedium from '../images/image7a.png'
import imagesmall from '../images/image7asmall.png'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Helmet } from 'react-helmet-async'

function SBA7a() {
    const [value, setValue] = React.useState(0);
    const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const theme = useTheme();

    const [imageSrc, setImageSrc] = useState('defaultImage.jpg');

    useEffect(() => {
        const updateImage = () => {
            if (window.matchMedia('(max-width: 700px)').matches) {
                setImageSrc(imagesmall);
            } else if (window.matchMedia('(max-width: 900px)').matches) {
                setImageSrc(imagemedium);
            } else {
                setImageSrc(imagelarge);
            }
        };

        updateImage(); // Set initial image

        window.addEventListener('resize', updateImage);
        return () => window.removeEventListener('resize', updateImage);
    }, []);

    return (
        <div>
            <Helmet>
                <title>CapTec USA | SBA 7(a)</title>
                <meta name="description" content="Unlock the full SBA benefits with an SBA 7(a) loan. With loans up to $5 million offering the most flexibility, 7(a) loans are the SBA's primary business loan program." />
                <link rel="canonical" href="https://thenewsouthbank.com/sba7a" />
            </Helmet>
            <div style={{ marginTop: '40px', marginLeft: '50px' }} className="Expect">
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                >

                    <Box sx={{
                        width: '70vw',
                        height: '5rem',
                        borderBottom: 0,
                        textAlign: 'left',
                        m: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                    }}>
                        <Typography fontSize={40} fontWeight={600}
                            sx={{ color: theme => theme.palette.primary.main }}
                        >
                            SBA 7(a)
                        </Typography>
                    </Box>
                </Stack>
                <br />
            </div>
            <Card style={{
                color: 'white',
                backgroundColor: theme.palette.primary.main,
                boxShadow: 'none',
                padding: 0,
                marginTop: 0,
                height: 'auto',
                borderRadius: 0,
                position: 'relative', // Ensure relative positioning for absolute children
                overflow: 'hidden', // Clip overflowing content (like the image)
            }}>
                <div style={{ marginTop: '30px', marginLeft: '50px' }} className="WhySBA">

                    {/* Content within the card */}
                    <div style={{
                        position: 'relative', zIndex: 1, textAlign: 'center', padding: '20px',

                    }}>
                        <Card sx={{
                            boxShadow: 'none',
                            bgcolor: 'transparent',
                            marginLeft: { xs: '-30px', sm: '0px' }
                        }}>
                            {/* <Stack direction="row" justifyContent="center" alignItems="center" spacing={3} marginBottom={10} marginTop={5} > */}
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                                sx={{ width: { xs: '40vw', sm: '40vw', md: '50vw' } }}
                            >
                                {/* <Box sx={{
                                    width: '40vw',
                                    height: 'auto',
                                    borderBottom: 1,
                                    borderColor: 'white',
                                    textAlign: 'left',
                                    m: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end',
                                    marginLeft: '-30rem'
                                }}>
                                    <Typography fontSize={40} fontWeight={600} color={'white'}    //blue
                                    >
                                        Why SBA
                                    </Typography>
                                </Box> 
                                <br /> */}
                                <Typography sx={{
                                    fontSize: { xs: 16, sm: 16, md: 18, lg: 20 }, textAlign: 'left',
                                    lineHeight: 1.5
                                    //paddingRight: { xs: '50px', sm: '80px', md: '100px', lg: '300px' }
                                }}
                                    fontWeight={500}
                                    color={'white'}
                                >
                                    <CheckCircleOutlineIcon /> <i>Need more than $500,000?</i>
                                </Typography>
                                <Typography sx={{
                                    fontSize: { xs: 16, sm: 16, md: 18, lg: 20 },
                                    textAlign: 'left',
                                    lineHeight: 1.5,
                                    paddingLeft: { xs: 0, sm: '16px' }
                                }}
                                    fontWeight={500}
                                    color={'white'}
                                >
                                    <CheckCircleOutlineIcon /> <i>Buying commercial real estate?</i>
                                </Typography>
                                <Typography sx={{
                                    fontSize: { xs: 16, sm: 16, md: 18, lg: 20 },
                                    textAlign: 'left',
                                    lineHeight: 1.5,
                                    paddingLeft: { xs: 0, sm: '32px' }
                                }}
                                    fontWeight={500}
                                    color={'white'}
                                >
                                    <i>Unlock the full SBA benefits with an SBA 7(a) loan. The 7(a) loan program is SBA's primary business loan program for providing financial assistance to small businesses.</i>
                                </Typography>
                                <br />
                            </Stack>
                        </Card>
                    </div>

                    {/* Absolute positioned image */}
                    <img
                        src={imageSrc}
                        alt='flag'
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            width: 'auto',
                            height: '100%',
                            objectFit: 'contain',
                            zIndex: 0, // Ensure the image is behind other content
                            maskImage: 'linear-gradient(to left, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)', // Gradient mask
                            WebkitMaskImage: 'linear-gradient(to left, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)', // For Safari
                        }}
                    />
                </div>
            </Card>
            <br />
            <Timeline amtdescr='$100,000 - $5 million'
                termdescr='Terms start at 10 years and extend all the way to 25 years for real estate loans.'
                usedescr='7(a) loans can be used for all approved SBA loan uses, including commercial real estate'
                fundingdescr='The full 7(a) program typically sees funding within weeks, due to the robust nature of the loan.'
            />
            <br />
        </div>

    );
}

export default SBA7a;
