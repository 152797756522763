import { Box, Stack, Typography, Divider, IconButton, Button, useMediaQuery } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import capteclogo from '../images/capteclogo-white.png'
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useRef } from 'react';

export default function Footer({ footerHeight, setFooterHeight }) {
    const theme = useTheme();
    const navigate = useNavigate()
    const privacyClick = () => {
        window.open('https://captecusa.com/privacy-policy/', '_blank');
    };

    const formsClick = () => {
        navigate('/forms')
    };

    const referralClick = () => {
        navigate('/referral')
    };

    const handleLinkedinClick = () => {
        window.open('https://www.linkedin.com/company/captec-usa/', '_blank');
    };

    const isXs = useMediaQuery(theme.breakpoints.only('xs'))


    ////////////////////// set fooerHeight
    const footerRef = useRef(null)

    useEffect(() => {
        const updateFooterHeight = () => {
            if (footerRef.current) {
                setFooterHeight(footerRef.current.offsetHeight);
            }
        };

        updateFooterHeight();

        // Optionally update height on window resize
        window.addEventListener('resize', updateFooterHeight);
        return () => window.removeEventListener('resize', updateFooterHeight);
    }, []);

    return (
        <Box ref={footerRef} sx={{
            width: '100%',
            bgcolor: theme.palette.primary.dark,
            marginTop: 'auto',
            padding: 1,
            zIndex: 5
        }}>
            <Stack                      //most outside layer spaced top to bottom for copyright on bottom with divider
                direction="column"
                //justifyContent="center"
                //alignItems="center"
                spacing={2}
                sx={{ marginLeft: { xs: 1, sm: 2, md: 3 }, marginRight: { xs: 2, sm: 3, md: 4 }, marginTop: 1 }}
                divider={<Divider
                    orientation="horizontal"
                    flexItem
                    sx={{
                        borderColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent color
                        borderBottomWidth: '0.5px', // Set border bottom width directly
                        borderBottomStyle: 'solid', // Ensure border style is set
                        borderTopWidth: '0px', // Hide top border if not needed
                    }}
                />}
            >

                <Stack                      //outside layer spaced opposite ends of bar
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ marginLeft: { xs: 1, sm: 2, md: 3 }, marginRight: { xs: 2, sm: 3, md: 4 } }}
                >

                    <Stack                  //left side of bar, ordered as columns
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={.5}
                    >

                        <Stack              //first column ordered as row for logo next to links 
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={5}
                        /* divider={<Divider orientation="vertical" flexItem
                            sx={{ borderColor: 'white' }} // Set color for Divider
                        />} */
                        >
                            <img src={capteclogo} height='70px'></img>
                            <Stack              //first column ordered as columns 
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={0}
                            /* divider={<Divider orientation="vertical" flexItem
                                sx={{ borderColor: 'white' }} // Set color for Divider
                            />} */
                            >
                                <Button onClick={privacyClick}
                                    sx={{
                                        color: 'transparent',
                                        padding: 0,
                                        minWidth: 'auto', // Set minimum width to auto to avoid unnecessary width
                                        '&:hover': {
                                            backgroundColor: 'transparent', // Lighter background color on hover
                                            padding: 0,
                                        },
                                    }}>
                                    <Typography color={'white'} sx={{ fontSize: { xs: 11, sm: 12, md: 14 } }} style={{ textTransform: 'capitalize' }} >Privacy Policy</Typography>
                                </Button>
                                <Button onClick={formsClick}
                                    sx={{
                                        color: 'transparent',
                                        padding: 0,
                                        minWidth: 'auto', // Set minimum width to auto to avoid unnecessary width
                                        '&:hover': {
                                            backgroundColor: 'transparent', // Lighter background color on hover
                                            padding: 0,
                                        },
                                    }}>
                                    <Typography color={'white'} sx={{ fontSize: { xs: 11, sm: 12, md: 14 } }} style={{ textTransform: 'capitalize' }} >Forms</Typography>
                                </Button>
                                <Button onClick={referralClick}
                                    sx={{
                                        color: 'transparent',
                                        padding: 0,
                                        minWidth: 'auto', // Set minimum width to auto to avoid unnecessary width
                                        '&:hover': {
                                            backgroundColor: 'transparent', // Lighter background color on hover
                                            padding: 0,
                                        },
                                    }}>
                                    <Typography color={'white'} sx={{ fontSize: { xs: 11, sm: 12, md: 14 } }} style={{ textTransform: 'capitalize' }} >Referral Partners</Typography>
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack                  //right side of bar, ordered as rows
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        sx={{
                            spacing: {
                                xs: 0, // Spacing for extra small screens
                                md: 2, // Spacing for small screens
                            },
                        }}
                    >
                        {/* <IconButton aria-label="facebook" onClick={handleFacebookClick}
                        sx={{
                            padding: 0,
                        }}>
                        <FacebookIcon sx={{ color: 'white' }} />
                    </IconButton> */}
                        <IconButton aria-label="LinkedIn" onClick={handleLinkedinClick}
                            sx={{
                                paddingY: 0,
                                paddingX: {
                                    xs: 0.5, // Spacing for extra small screens
                                    sm: 2, // Spacing for small screens
                                },
                            }}>
                            <LinkedInIcon sx={{ color: 'white' }} fontSize='large' />
                        </IconButton>
                        {/* <img src={FLabalogo}
                        alt="FL ABA Logo" // Always include alt text for accessibility
                        style={{ maxWidth: '100%', height: '45px' }} // Adjust width and height as needed
                    /> */}
                    </Stack>
                </Stack>
                <Typography color={'white'} sx={{ fontSize: { xs: 11, sm: 12, md: 14 } }} >
                    © 2024 CapTec USA. All Rights Reserved.
                    {/* {isXs && <br />} {/* Add line break only on xs screens */}

                </Typography>
            </Stack>
        </Box>
    );
}