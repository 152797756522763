import { Card, CardContent, Stack, Typography, Grid, Box } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import InputDropdown from './InputDropdown'
import InputTextFull from './InputTextFull'
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import InputTextPhone from './InputTextPhone';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import NextButton from "./NextButton";
import OwnerCard from "./OwnerCard";


function Step2({ contactinfo, setContactInfo, data, setData, pixel, handleBack, handleNext, qstepdata, setQStepData, steps, setSteps }) {
    function formatName(value) {
        if (!value) return value;
        const newName = value.replace(/[^\w\s,.\'\-]/g, '');
        const newNameLength = newName.length;
        if (newNameLength <= 20) {
            return newName;
        }
        return `${newName.slice(0, 20)}`;
    }

    function formatPercent(value) {
        if (!value) return value;
        const percentNumber = value.replace(/[^\d]/g, '');
        const percentNumberLength = percentNumber.length;
        if (percentNumber <= 100) {
            return `${percentNumber}%`;
        }
        return "100%";
    }

    //console.log('qstepdata.legalBusinessName : ', qstepdata.legalBusinessName)
    const handleOwner1FirstName = e => {
        const formattedName = formatName(e.target.value)
        setQStepData({
            ...qstepdata
            , owner1FirstName: formattedName
        })
    }

    const handleOwner1LastName = e => {
        const formattedName = formatName(e.target.value)
        setQStepData({
            ...qstepdata
            , owner1LastName: formattedName
        })
    }

    const handleOwner1Perc = e => {
        const formattedPercent = formatPercent(e.target.value)
        setQStepData({
            ...qstepdata
            , owner1Perc: formattedPercent
        })
    }

    function formatEmail(value) {
        if (!value) return value;
        const newEmail = value.replace(/[^\w\s,.@\'\-]/g, '').trim();
        const newEmailLength = newEmail.length;
        if (newEmailLength <= 35) {
            return newEmail;
        }
        return `${newEmail.slice(0, 35)}`;
    }

    const handleOwner1Email = (e) => {

        const formattedEmail = formatEmail(e.target.value)
        setQStepData({
            ...qstepdata
            , owner1Email: formattedEmail
        })
    }



    const handleOwner1SSN = e => {
        const formattedSSN = formatSSN(e.target.value)
        setQStepData({
            ...qstepdata
            , owner1SSN: formattedSSN
        })
    }

    function formatSSN(value) {
        if (!value) return value;
        const socialsn = value.replace(/[^\d]/g, '');
        const socialsnLength = socialsn.length;
        if (socialsnLength < 4) return socialsn;
        if (socialsnLength < 6) {
            return `${socialsn.slice(0, 3)}-${socialsn.slice(3)}`;
        }
        return `${socialsn.slice(0, 3)}-${socialsn.slice(3, 5,)}-${socialsn.slice(5, 9)}`;
    }

    const handleOwner1Phone = e => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value)
        setQStepData({
            ...qstepdata
            , owner1Phone: formattedPhoneNumber
        })
    }

    function formatPhoneNumber(value) {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6,)}-${phoneNumber.slice(6, 10)}`;
    }

    function formatStreetAddress(value) {
        if (!value) return value;
        const Address1 = value.replace(/[^\w\s,#.\'\-]/g, '');
        const Address1Length = Address1.length;
        if (Address1Length <= 40) {
            return Address1;
        }
        return `${Address1.slice(0, 40)}`;
    }

    function formatCity(value) {
        if (!value) return value;
        const Address2 = value.replace(/[^\w\s,#.\'\-]/g, '');
        const Address2Length = Address2.length;
        if (Address2Length <= 20) {
            return Address2;
        }
        return `${Address2.slice(0, 20)}`;
    }

    function formatZip(value) {
        if (!value) return value;
        const Zip = value.replace(/[^\d]/g, '');
        const ZipLength = Zip.length;
        if (ZipLength <= 5) {
            return Zip;
        }
        return `${Zip.slice(0, 5)}`;
    }

    const handleowner1HomeAddress1 = e => {
        const formattedAddress1 = formatStreetAddress(e.target.value)
        setQStepData({
            ...qstepdata
            , owner1HomeAddress1: formattedAddress1
        })
    }

    const handleowner1HomeAddress2 = e => {
        const formattedAddress2 = formatStreetAddress(e.target.value)
        setQStepData({
            ...qstepdata
            , owner1HomeAddress2: formattedAddress2
        })
    }

    const handleOwner1City = e => {
        const formattedCity = formatCity(e.target.value)
        setQStepData({
            ...qstepdata
            , owner1City: formattedCity
        })
    }

    const handleOwner1Zip = e => {
        const formattedZip = formatZip(e.target.value)
        setQStepData({
            ...qstepdata
            , owner1Zip: formattedZip
        })
    }

    useEffect(() => {
        //console.log('step1 useEffect qstepdata : ', qstepdata)
        if (qstepdata.owner1FirstName != ""
            && qstepdata.owner1LastName != ""
            && qstepdata.owner1SSN.length == "11"
            && qstepdata.owner1Perc.length >= "2"
            && qstepdata.owner1HomeAddress1 != ""
            && qstepdata.owner1City != ""
            && qstepdata.owner1State != ""
            && qstepdata.owner1Zip.length == "5"
            && qstepdata.owner1Email.includes("@")
            && qstepdata.owner1Email.includes(".")
            && qstepdata.owner1Phone.length == 14
            && (
                qstepdata.ownernum == "1"
                ||
                (qstepdata.ownernum == "2"
                    && qstepdata.owner2FirstName != ""
                    && qstepdata.owner2LastName != ""
                    && qstepdata.owner2Perc.length >= "2"
                    && qstepdata.owner2Email.includes("@")
                    && qstepdata.owner2Email.includes(".")
                    && qstepdata.owner2Phone.length == 14
                )
                ||
                (qstepdata.ownernum == "3"
                    && qstepdata.owner2FirstName != ""
                    && qstepdata.owner2LastName != ""
                    && qstepdata.owner2Perc.length >= "2"
                    && qstepdata.owner2Email.includes("@")
                    && qstepdata.owner2Email.includes(".")
                    && qstepdata.owner2Phone.length == 14

                    && qstepdata.owner3FirstName != ""
                    && qstepdata.owner3LastName != ""
                    && qstepdata.owner3Perc.length >= "2"
                    && qstepdata.owner3Email.includes("@")
                    && qstepdata.owner3Email.includes(".")
                    && qstepdata.owner3Phone.length == 14
                )
                ||
                (qstepdata.ownernum == "4"
                    && qstepdata.owner2FirstName != ""
                    && qstepdata.owner2LastName != ""
                    && qstepdata.owner2Perc.length >= "2"
                    && qstepdata.owner2Email.includes("@")
                    && qstepdata.owner2Email.includes(".")
                    && qstepdata.owner2Phone.length == 14

                    && qstepdata.owner3FirstName != ""
                    && qstepdata.owner3LastName != ""
                    && qstepdata.owner3Perc.length >= "2"
                    && qstepdata.owner3Email.includes("@")
                    && qstepdata.owner3Email.includes(".")
                    && qstepdata.owner3Phone.length == 14

                    && qstepdata.owner4FirstName != ""
                    && qstepdata.owner4LastName != ""
                    && qstepdata.owner4Perc.length >= "2"
                    && qstepdata.owner4Email.includes("@")
                    && qstepdata.owner4Email.includes(".")
                    && qstepdata.owner4Phone.length == 14
                )
                ||
                (qstepdata.ownernum == "5"
                    && qstepdata.owner2FirstName != ""
                    && qstepdata.owner2LastName != ""
                    && qstepdata.owner2Perc.length >= "2"
                    && qstepdata.owner2Email.includes("@")
                    && qstepdata.owner2Email.includes(".")
                    && qstepdata.owner2Phone.length == 14

                    && qstepdata.owner3FirstName != ""
                    && qstepdata.owner3LastName != ""
                    && qstepdata.owner3Perc.length >= "2"
                    && qstepdata.owner3Email.includes("@")
                    && qstepdata.owner3Email.includes(".")
                    && qstepdata.owner3Phone.length == 14

                    && qstepdata.owner4FirstName != ""
                    && qstepdata.owner4LastName != ""
                    && qstepdata.owner4Perc.length >= "2"
                    && qstepdata.owner4Email.includes("@")
                    && qstepdata.owner4Email.includes(".")
                    && qstepdata.owner4Phone.length == 14

                    && qstepdata.owner5FirstName != ""
                    && qstepdata.owner5LastName != ""
                    && qstepdata.owner5Perc.length >= "2"
                    && qstepdata.owner5Email.includes("@")
                    && qstepdata.owner5Email.includes(".")
                    && qstepdata.owner5Phone.length == 14
                )
            )
        ) {
            setSteps(steps => ({ ...steps, 1: true }));
            //console.log('step6 complete: ', driverslicense.size, year1bustax.size, year2bustax.size, personaltax.size)
        } else {
            setSteps(steps => ({ ...steps, 1: false }));
            //console.log('step6 incomplete: ', driverslicense.size, year1bustax.size, year2bustax.size, personaltax.size)
        }
    }, [qstepdata.ownernum, qstepdata.owner1FirstName, qstepdata.owner1LastName
        , qstepdata.owner1SSN, qstepdata.owner1Perc, qstepdata.owner1HomeAddress1, qstepdata.owner1City
        , qstepdata.owner1State, qstepdata.owner1Zip, qstepdata.owner1Email, qstepdata.owner1Phone
        , qstepdata.owner2FirstName, qstepdata.owner2LastName, qstepdata.owner2Perc, qstepdata.owner2Email, qstepdata.owner2Phone
        , qstepdata.owner3FirstName, qstepdata.owner3LastName, qstepdata.owner3Perc, qstepdata.owner3Email, qstepdata.owner3Phone
        , qstepdata.owner4FirstName, qstepdata.owner4LastName, qstepdata.owner4Perc, qstepdata.owner4Email, qstepdata.owner4Phone
        , qstepdata.owner5FirstName, qstepdata.owner5LastName, qstepdata.owner5Perc, qstepdata.owner5Email, qstepdata.owner5Phone
    ]);


    const ownerDropDown = [
        { label: '', value: '' },
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' }
    ]

    const stateDropDown = [
        { label: '', value: '' },
        { label: 'Alabama', value: 'Alabama' },
        { label: 'Alaska', value: 'Alaska' },
        { label: 'Arizona', value: 'Arizona' },
        { label: 'Arkansas', value: 'Arkansas' },
        { label: 'California', value: 'California' },
        { label: 'Colorado', value: 'Colorado' },
        { label: 'Connecticut', value: 'Connecticut' },
        { label: 'Delaware', value: 'Delaware' },
        { label: 'Florida', value: 'Florida' },
        { label: 'Georgia', value: 'Georgia' },
        { label: 'Hawaii', value: 'Hawaii' },
        { label: 'Idaho', value: 'Idaho' },
        { label: 'Illinois', value: 'Illinois' },
        { label: 'Indiana', value: 'Indiana' },
        { label: 'Iowa', value: 'Iowa' },
        { label: 'Kansas', value: 'Kansas' },
        { label: 'Kentucky', value: 'Kentucky' },
        { label: 'Louisiana', value: 'Louisiana' },
        { label: 'Maine', value: 'Maine' },
        { label: 'Maryland', value: 'Maryland' },
        { label: 'Massachusetts', value: 'Massachusetts' },
        { label: 'Michigan', value: 'Michigan' },
        { label: 'Minnesota', value: 'Minnesota' },
        { label: 'Mississippi', value: 'Mississippi' },
        { label: 'Missouri', value: 'Missouri' },
        { label: 'Montana', value: 'Montana' },
        { label: 'Nebraska', value: 'Nebraska' },
        { label: 'Nevada', value: 'Nevada' },
        { label: 'New Hampshire', value: 'New Hampshire' },
        { label: 'New Jersey', value: 'New Jersey' },
        { label: 'New Mexico', value: 'New Mexico' },
        { label: 'New York', value: 'New York' },
        { label: 'North Carolina', value: 'North Carolina' },
        { label: 'North Dakota', value: 'North Dakota' },
        { label: 'Ohio', value: 'Ohio' },
        { label: 'Oklahoma', value: 'Oklahoma' },
        { label: 'Oregon', value: 'Oregon' },
        { label: 'Pennsylvania', value: 'Pennsylvania' },
        { label: 'Rhode Island', value: 'Rhode Island' },
        { label: 'South Carolina', value: 'South Carolina' },
        { label: 'South Dakota', value: 'South Dakota' },
        { label: 'Tennessee', value: 'Tennessee' },
        { label: 'Texas', value: 'Texas' },
        { label: 'Utah', value: 'Utah' },
        { label: 'Vermont', value: 'Vermont' },
        { label: 'Virginia', value: 'Virginia' },
        { label: 'Washington', value: 'Washington' },
        { label: 'Washington, D.C.', value: 'Washington, D.C.' },
        { label: 'West Virginia', value: 'West Virginia' },
        { label: 'Wisconsin', value: 'Wisconsin' },
        { label: 'Wyoming', value: 'Wyoming' }
    ]

    //console.log('qstepdata.owner1FirstName : ', qstepdata.owner1FirstName)

    return (
        <div>
            <Stack mt={0}>

                <Stack alignItems="center" sx={{ width: { md: '100%', lg: 'calc(100% - 240px)' } }} >
                    <Typography variant="h6" sx={{ fontWeight: 700, color: data.colorstep }}>
                        Step 2:
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 700 }}>
                        Owner Information
                    </Typography>
                </Stack>
                <Stack alignItems="center" sx={{ width: { md: '100%', lg: 'calc(100% - 240px)' } }} >

                    <br />
                    <br />
                    <Grid container spacing={0} >
                        <Grid item xs={12} sm={6} md={3} lg={3}
                            sx={{
                                marginLeft: {
                                    xs: -2, // Set marginLeft to -10 for xs breakpoint
                                    sm: -3, // Set marginLeft to -15 for md breakpoint
                                }
                            }}>
                            <InputDropdown
                                headingText="Number of Owners"
                                headingStyle={{ color: "red" }}
                                headingSuffix=" *"
                                subheading="How many 20% or more owners?"
                                //type="text"
                                required={true}
                                value={qstepdata.ownernum}
                                defaultValue={qstepdata.ownernum}
                                error={qstepdata.ownernumError}
                                color={data.colorfont}
                                //autoComplete="given-name"
                                //id="given-name"
                                onChange={e => {
                                    setQStepData({
                                        ...qstepdata
                                        , ownernum: (e.target.value)
                                        , ownernumError: e.target.value == '' ? true : false
                                    })
                                }}
                                dropdown={ownerDropDown}

                                //autoComplete="address-level1"
                                //id="address-level1"
                                propsError={qstepdata.busStateError}
                            //onBlur={() => { setQStepData({ ...qstepdata, ownernumError: qstepdata.ownernum == '' ? true : false })}}
                            />
                        </Grid>
                    </Grid>

                    <br />
                    <br />
                    <Grid container spacing={2} justifyContent="center" xs={12} lg={11}>
                        <Card>
                            <CardContent sx={{ p: 0, }}>

                                {/* Left column */}
                                <Grid item xs={12} sx={{ backgroundColor: data.colorbackground, textAlign: 'center', paddingBottom: 2, paddingTop: 2 }} >
                                    <Typography variant="h6" sx={{ fontWeight: 700, color: data.colorstep }} align='left' marginLeft={2}>
                                        Primary Owner
                                    </Typography>
                                </Grid>
                                <br />
                                <Grid item xs={12}
                                    sx={{
                                        '& .MuiContainer-root.css-ur2jdm-MuiContainer-root': { paddingLeft: 1 }
                                    }}>
                                    <Grid container spacing={0} paddingRight={2}>
                                        <Grid item xs={12} sm={6} md={4} >
                                            <InputTextFull
                                                headingText="First Name"
                                                headingStyle={{ color: "red" }}
                                                headingSuffix=" *"
                                                //subheading=". "
                                                //marginTop={34}
                                                type="text"
                                                required={true}
                                                value={qstepdata.owner1FirstName}
                                                defaultValue={qstepdata.owner1FirstName}
                                                error={qstepdata.owner1FirstNameError}
                                                color={data.colorfont}
                                                autoComplete="given-name"
                                                id="given-name"
                                                onChange={e => handleOwner1FirstName(e)}
                                                onBlur={() => { setQStepData({ ...qstepdata, owner1FirstNameError: qstepdata.owner1FirstName == '' ? true : false }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} >
                                            <InputTextFull //label="DBA Name (if applicable)"
                                                headingText="Last Name"
                                                headingStyle={{ color: "red" }}
                                                headingSuffix=" *"
                                                //subheading=". "
                                                //marginTop={34}
                                                type="text"
                                                required={true}
                                                value={qstepdata.owner1LastName}
                                                defaultValue={qstepdata.owner1LastName}
                                                error={qstepdata.owner1LastNameError}
                                                color={data.colorfont}
                                                autoComplete="family-name"
                                                id="family-name"
                                                onChange={e => handleOwner1LastName(e)}
                                                onBlur={() => { setQStepData({ ...qstepdata, owner1LastNameError: qstepdata.owner1LastName == '' ? true : false }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} >
                                            <InputTextFull //label="DBA Name (if applicable)"
                                                headingText="Ownership %"
                                                headingStyle={{ color: "red" }}
                                                headingSuffix=" *"
                                                //subheading="Must be at least 20%"
                                                type="text"
                                                required={true}
                                                value={qstepdata.owner1Perc}
                                                defaultValue={qstepdata.owner1Perc}
                                                error={qstepdata.owner1PercError}
                                                color={data.colorfont}
                                                //autoComplete="given-name"
                                                //id="given-name"
                                                onChange={e => handleOwner1Perc(e)}
                                                onBlur={() => { setQStepData({ ...qstepdata, owner1PercError: qstepdata.owner1Perc == '' || parseInt(qstepdata.owner1Perc) < 1 ? true : false }) }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0} paddingRight={2}>
                                        <Grid item xs={12} sm={6} md={4} >
                                            <InputTextFull
                                                headingText="SSN"
                                                headingStyle={{ color: "red" }}
                                                headingSuffix=" *"
                                                //subheading=". "
                                                //marginTop={34}
                                                type="text"
                                                required={true}
                                                value={qstepdata.owner1SSN}
                                                defaultValue={qstepdata.owner1SSN}
                                                error={qstepdata.owner1SSNError}
                                                color={data.colorfont}
                                                //autoComplete="given-name"
                                                //id="given-name"
                                                onChange={e => handleOwner1SSN(e)}
                                                onBlur={() => { setQStepData({ ...qstepdata, owner1SSNError: qstepdata.owner1SSN.length == "11" ? false : true }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} >
                                            <InputTextFull //label="DBA Name (if applicable)"
                                                headingText="Email"
                                                headingStyle={{ color: "red" }}
                                                headingSuffix=" *"
                                                //subheading=". "
                                                //marginTop={34}
                                                type="text"
                                                required={true}
                                                value={qstepdata.owner1Email}
                                                defaultValue={qstepdata.owner1Email}
                                                error={qstepdata.owner1EmailError}
                                                color={data.colorfont}
                                                autoComplete="email"
                                                id="email"
                                                onChange={e => handleOwner1Email(e)}
                                                onBlur={() => { setQStepData({ ...qstepdata, owner1EmailError: qstepdata.owner1Email.includes("@") && qstepdata.owner1Email.includes(".") ? false : true }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} >
                                            <InputTextFull //label="DBA Name (if applicable)"
                                                headingText="Phone"
                                                headingStyle={{ color: "red" }}
                                                headingSuffix=" *"
                                                //subheading="Must be at least 20%"
                                                type="text"
                                                required={true}
                                                value={qstepdata.owner1Phone}
                                                defaultValue={qstepdata.owner1Phone}
                                                error={qstepdata.owner1PhoneError}
                                                color={data.colorfont}
                                                autoComplete="tel"
                                                id="tel"
                                                onChange={e => handleOwner1Phone(e)}
                                                onBlur={() => { setQStepData({ ...qstepdata, owner1PhoneError: qstepdata.owner1Phone.length == 14 ? false : true }) }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0} paddingRight={2}>
                                        <Grid item xs={12} sm={6} md={4} >
                                            <InputTextFull
                                                headingText="Home Address"
                                                headingStyle={{ color: "red" }}
                                                headingSuffix=" *"
                                                //subheading=". "
                                                //marginTop={34}
                                                type="text"
                                                required={true}
                                                value={qstepdata.owner1HomeAddress1}
                                                defaultValue={qstepdata.owner1HomeAddress1}
                                                error={qstepdata.owner1HomeAddress1Error}
                                                color={data.colorfont}
                                                autoComplete="address-line1"
                                                id="address-line1"
                                                onChange={e => handleowner1HomeAddress1(e)}
                                                onBlur={() => { setQStepData({ ...qstepdata, owner1HomeAddress1Error: qstepdata.owner1HomeAddress1 == "" ? true : false }) }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4} >
                                            <InputTextFull //label="DBA Name (if applicable)"
                                                headingText="Apt/Suite/Unit"
                                                //headingStyle={{ color: "red" }}
                                                //headingSuffix=" *"
                                                //subheading=". "
                                                //marginTop={34}
                                                type="text"
                                                required={true}
                                                value={qstepdata.owner1HomeAddress2}
                                                defaultValue={qstepdata.owner1HomeAddress2}
                                                //error={qstepdata.owner1HomeAddress2Error}
                                                color={data.colorfont}
                                                autoComplete="address-line2"
                                                id="address-line2"
                                                onChange={e => handleowner1HomeAddress2(e)}
                                            //onBlur={() => { setQStepData({ ...qstepdata, owner1HomeAddress2Error: qstepdata.owner1HomeAddress2 == "" ? true : false }) }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={0} paddingRight={2}>
                                        <Grid item xs={12} sm={6} md={4} >
                                            <InputTextFull
                                                headingText="City"
                                                headingStyle={{ color: "red" }}
                                                headingSuffix=" *"
                                                //subheading=". "
                                                //marginTop={34}
                                                type="text"
                                                required={true}
                                                value={qstepdata.owner1City}
                                                defaultValue={qstepdata.owner1City}
                                                error={qstepdata.owner1CityError}
                                                color={data.colorfont}
                                                autoComplete="address-level2"
                                                id="address-level2"
                                                onChange={e => handleOwner1City(e)}
                                                onBlur={() => { setQStepData({ ...qstepdata, owner1CityError: qstepdata.owner1City == "" ? true : false }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} >
                                            <InputDropdown
                                                headingText="State"
                                                headingStyle={{ color: "red" }}
                                                headingSuffix=" *"
                                                //subheading="How many 20% or more owners?"
                                                //type="text"
                                                required={true}
                                                value={qstepdata.owner1State}
                                                defaultValue={qstepdata.owner1State}
                                                error={qstepdata.owner1StateError}
                                                color={data.colorfont}
                                                autoComplete="address-level1"
                                                id="address-level1"
                                                onChange={e => {
                                                    setQStepData({
                                                        ...qstepdata
                                                        , owner1State: (e.target.value)
                                                        , owner1StateError: e.target.value == '' ? true : false
                                                    })
                                                }}
                                                dropdown={stateDropDown}

                                                //autoComplete="address-level1"
                                                //id="address-level1"
                                                propsError={qstepdata.owner1StateError}
                                            //onBlur={() => { setQStepData({ ...qstepdata, ownernumError: qstepdata.ownernum == '' ? true : false })}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} >
                                            <InputTextFull //label="DBA Name (if applicable)"
                                                headingText="Zip"
                                                headingStyle={{ color: "red" }}
                                                headingSuffix=" *"
                                                //subheading="Must be at least 20%"
                                                type="text"
                                                required={true}
                                                value={qstepdata.owner1Zip}
                                                defaultValue={qstepdata.owner1Zip}
                                                error={qstepdata.owner1ZipError}
                                                color={data.colorfont}
                                                autoComplete="postal-code"
                                                id="postal-code"
                                                onChange={e => handleOwner1Zip(e)}
                                                onBlur={() => { setQStepData({ ...qstepdata, owner1ZipError: qstepdata.owner1Zip.length == 5 ? false : true }) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <br />
                    {qstepdata.ownernum >= 2 ?
                        (
                            <>
                                <br />
                                <br />
                                <OwnerCard label="2nd Owner"
                                    data={data}
                                    FirstName={qstepdata.owner2FirstName}
                                    LastName={qstepdata.owner2LastName}
                                    Perc={qstepdata.owner2Perc}
                                    SSN={qstepdata.owner2SSN}
                                    Email={qstepdata.owner2Email}
                                    Phone={qstepdata.owner2Phone}
                                    FirstNameError={qstepdata.owner2FirstNameError}
                                    LastNameError={qstepdata.owner2LastNameError}
                                    PercError={qstepdata.owner2PercError}
                                    //SSNError={qstepdata.owner2SSNError}
                                    EmailError={qstepdata.owner2EmailError}
                                    PhoneError={qstepdata.owner2PhoneError}
                                    handleFirstName={e => {
                                        const formattedName = formatName(e.target.value)
                                        setQStepData({ ...qstepdata, owner2FirstName: formattedName })
                                    }}
                                    handleLastName={e => {
                                        const formattedName = formatName(e.target.value)
                                        setQStepData({ ...qstepdata, owner2LastName: formattedName })
                                    }}
                                    handlePerc={e => {
                                        const formattedPercent = formatPercent(e.target.value)
                                        setQStepData({ ...qstepdata, owner2Perc: formattedPercent })
                                    }}
                                    handleSSN={e => {
                                        const formattedSSN = formatSSN(e.target.value)
                                        setQStepData({ ...qstepdata, owner2SSN: formattedSSN })
                                    }}
                                    handleEmail={e => {
                                        const formattedEmail = formatEmail(e.target.value)
                                        setQStepData({ ...qstepdata, owner2Email: formattedEmail })
                                    }}
                                    handlePhone={e => {
                                        const formattedPhoneNumber = formatPhoneNumber(e.target.value)
                                        setQStepData({ ...qstepdata, owner2Phone: formattedPhoneNumber })
                                    }}
                                    onBlurFirstName={() => { setQStepData({ ...qstepdata, owner2FirstNameError: qstepdata.owner2FirstName == '' ? true : false }) }}
                                    onBlurLastName={() => { setQStepData({ ...qstepdata, owner2LastNameError: qstepdata.owner2LastName == '' ? true : false }) }}
                                    onBlurPerc={() => { setQStepData({ ...qstepdata, owner2PercError: qstepdata.owner2Perc == '' || parseInt(qstepdata.owner2Perc) < 1 ? true : false }) }}
                                    //onBlurSSN={() => { setQStepData({ ...qstepdata, owner2SSNError: qstepdata.owner2SSN.length == "11" ? false : true }) }}
                                    onBlurEmail={() => { setQStepData({ ...qstepdata, owner2EmailError: qstepdata.owner2Email.includes("@") && qstepdata.owner2Email.includes(".") ? false : true }) }}
                                    onBlurPhone={() => { setQStepData({ ...qstepdata, owner2PhoneError: qstepdata.owner2Phone.length == 14 ? false : true }) }}
                                />
                                <br />
                            </>)
                        : <></>
                    }
                    {qstepdata.ownernum >= 3 ?
                        (
                            <>
                                <br />
                                <br />
                                <OwnerCard label="3rd Owner"
                                    data={data}
                                    FirstName={qstepdata.owner3FirstName}
                                    LastName={qstepdata.owner3LastName}
                                    Perc={qstepdata.owner3Perc}
                                    SSN={qstepdata.owner3SSN}
                                    Email={qstepdata.owner3Email}
                                    Phone={qstepdata.owner3Phone}
                                    FirstNameError={qstepdata.owner3FirstNameError}
                                    LastNameError={qstepdata.owner3LastNameError}
                                    PercError={qstepdata.owner3PercError}
                                    //SSNError={qstepdata.owner3SSNError}
                                    EmailError={qstepdata.owner3EmailError}
                                    PhoneError={qstepdata.owner3PhoneError}
                                    handleFirstName={e => {
                                        const formattedName = formatName(e.target.value)
                                        setQStepData({ ...qstepdata, owner3FirstName: formattedName })
                                    }}
                                    handleLastName={e => {
                                        const formattedName = formatName(e.target.value)
                                        setQStepData({ ...qstepdata, owner3LastName: formattedName })
                                    }}
                                    handlePerc={e => {
                                        const formattedPercent = formatPercent(e.target.value)
                                        setQStepData({ ...qstepdata, owner3Perc: formattedPercent })
                                    }}
                                    handleSSN={e => {
                                        const formattedSSN = formatSSN(e.target.value)
                                        setQStepData({ ...qstepdata, owner3SSN: formattedSSN })
                                    }}
                                    handleEmail={e => {
                                        const formattedEmail = formatEmail(e.target.value)
                                        setQStepData({ ...qstepdata, owner3Email: formattedEmail })
                                    }}
                                    handlePhone={e => {
                                        const formattedPhoneNumber = formatPhoneNumber(e.target.value)
                                        setQStepData({ ...qstepdata, owner3Phone: formattedPhoneNumber })
                                    }}
                                    onBlurFirstName={() => { setQStepData({ ...qstepdata, owner3FirstNameError: qstepdata.owner3FirstName == '' ? true : false }) }}
                                    onBlurLastName={() => { setQStepData({ ...qstepdata, owner3LastNameError: qstepdata.owner3LastName == '' ? true : false }) }}
                                    onBlurPerc={() => { setQStepData({ ...qstepdata, owner3PercError: qstepdata.owner3Perc == '' || parseInt(qstepdata.owner3Perc) < 1 ? true : false }) }}
                                    //onBlurSSN={() => { setQStepData({ ...qstepdata, owner3SSNError: qstepdata.owner3SSN.length == "11" ? false : true }) }}
                                    onBlurEmail={() => { setQStepData({ ...qstepdata, owner3EmailError: qstepdata.owner3Email.includes("@") && qstepdata.owner3Email.includes(".") ? false : true }) }}
                                    onBlurPhone={() => { setQStepData({ ...qstepdata, owner3PhoneError: qstepdata.owner3Phone.length == 14 ? false : true }) }}
                                />
                                <br />
                            </>)
                        : <></>
                    }
                    {qstepdata.ownernum >= 4 ?
                        (
                            <>
                                <br />
                                <br />
                                <OwnerCard label="4th Owner"
                                    data={data}
                                    FirstName={qstepdata.owner4FirstName}
                                    LastName={qstepdata.owner4LastName}
                                    Perc={qstepdata.owner4Perc}
                                    SSN={qstepdata.owner4SSN}
                                    Email={qstepdata.owner4Email}
                                    Phone={qstepdata.owner4Phone}
                                    FirstNameError={qstepdata.owner4FirstNameError}
                                    LastNameError={qstepdata.owner4LastNameError}
                                    PercError={qstepdata.owner4PercError}
                                    //SSNError={qstepdata.owner4SSNError}
                                    EmailError={qstepdata.owner4EmailError}
                                    PhoneError={qstepdata.owner4PhoneError}
                                    handleFirstName={e => {
                                        const formattedName = formatName(e.target.value)
                                        setQStepData({ ...qstepdata, owner4FirstName: formattedName })
                                    }}
                                    handleLastName={e => {
                                        const formattedName = formatName(e.target.value)
                                        setQStepData({ ...qstepdata, owner4LastName: formattedName })
                                    }}
                                    handlePerc={e => {
                                        const formattedPercent = formatPercent(e.target.value)
                                        setQStepData({ ...qstepdata, owner4Perc: formattedPercent })
                                    }}
                                    handleSSN={e => {
                                        const formattedSSN = formatSSN(e.target.value)
                                        setQStepData({ ...qstepdata, owner4SSN: formattedSSN })
                                    }}
                                    handleEmail={e => {
                                        const formattedEmail = formatEmail(e.target.value)
                                        setQStepData({ ...qstepdata, owner4Email: formattedEmail })
                                    }}
                                    handlePhone={e => {
                                        const formattedPhoneNumber = formatPhoneNumber(e.target.value)
                                        setQStepData({ ...qstepdata, owner4Phone: formattedPhoneNumber })
                                    }}
                                    onBlurFirstName={() => { setQStepData({ ...qstepdata, owner4FirstNameError: qstepdata.owner4FirstName == '' ? true : false }) }}
                                    onBlurLastName={() => { setQStepData({ ...qstepdata, owner4LastNameError: qstepdata.owner4LastName == '' ? true : false }) }}
                                    onBlurPerc={() => { setQStepData({ ...qstepdata, owner4PercError: qstepdata.owner4Perc == '' || parseInt(qstepdata.owner4Perc) < 1 ? true : false }) }}
                                    //onBlurSSN={() => { setQStepData({ ...qstepdata, owner4SSNError: qstepdata.owner4SSN.length == "11" ? false : true }) }}
                                    onBlurEmail={() => { setQStepData({ ...qstepdata, owner4EmailError: qstepdata.owner4Email.includes("@") && qstepdata.owner4Email.includes(".") ? false : true }) }}
                                    onBlurPhone={() => { setQStepData({ ...qstepdata, owner4PhoneError: qstepdata.owner4Phone.length == 14 ? false : true }) }}
                                />
                                <br />
                            </>)
                        : <></>
                    }
                    {qstepdata.ownernum >= 5 ?
                        (
                            <>
                                <br />
                                <br />
                                <OwnerCard label="5th Owner"
                                    data={data}
                                    FirstName={qstepdata.owner5FirstName}
                                    LastName={qstepdata.owner5LastName}
                                    Perc={qstepdata.owner5Perc}
                                    SSN={qstepdata.owner5SSN}
                                    Email={qstepdata.owner5Email}
                                    Phone={qstepdata.owner5Phone}
                                    FirstNameError={qstepdata.owner5FirstNameError}
                                    LastNameError={qstepdata.owner5LastNameError}
                                    PercError={qstepdata.owner5PercError}
                                    //SSNError={qstepdata.owner5SSNError}
                                    EmailError={qstepdata.owner5EmailError}
                                    PhoneError={qstepdata.owner5PhoneError}
                                    handleFirstName={e => {
                                        const formattedName = formatName(e.target.value)
                                        setQStepData({ ...qstepdata, owner5FirstName: formattedName })
                                    }}
                                    handleLastName={e => {
                                        const formattedName = formatName(e.target.value)
                                        setQStepData({ ...qstepdata, owner5LastName: formattedName })
                                    }}
                                    handlePerc={e => {
                                        const formattedPercent = formatPercent(e.target.value)
                                        setQStepData({ ...qstepdata, owner5Perc: formattedPercent })
                                    }}
                                    handleSSN={e => {
                                        const formattedSSN = formatSSN(e.target.value)
                                        setQStepData({ ...qstepdata, owner5SSN: formattedSSN })
                                    }}
                                    handleEmail={e => {
                                        const formattedEmail = formatEmail(e.target.value)
                                        setQStepData({ ...qstepdata, owner5Email: formattedEmail })
                                    }}
                                    handlePhone={e => {
                                        const formattedPhoneNumber = formatPhoneNumber(e.target.value)
                                        setQStepData({ ...qstepdata, owner5Phone: formattedPhoneNumber })
                                    }}
                                    onBlurFirstName={() => { setQStepData({ ...qstepdata, owner5FirstNameError: qstepdata.owner5FirstName == '' ? true : false }) }}
                                    onBlurLastName={() => { setQStepData({ ...qstepdata, owner5LastNameError: qstepdata.owner5LastName == '' ? true : false }) }}
                                    onBlurPerc={() => { setQStepData({ ...qstepdata, owner5PercError: qstepdata.owner5Perc == '' || parseInt(qstepdata.owner5Perc) < 1 ? true : false }) }}
                                    //onBlurSSN={() => { setQStepData({ ...qstepdata, owner5SSNError: qstepdata.owner5SSN.length == "11" ? false : true }) }}
                                    onBlurEmail={() => { setQStepData({ ...qstepdata, owner5EmailError: qstepdata.owner5Email.includes("@") && qstepdata.owner5Email.includes(".") ? false : true }) }}
                                    onBlurPhone={() => { setQStepData({ ...qstepdata, owner5PhoneError: qstepdata.owner5Phone.length == 14 ? false : true }) }}
                                />
                                <br />
                            </>)
                        : <></>
                    }
                </Stack>
                <br />
                <NextButton handleBack={handleBack} handleNext={handleNext} data={data} />
            </Stack>
        </div >
    )
}

export default Step2