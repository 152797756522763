
import {
    FormGroup, FormControlLabel, Stack, Typography, Slider
    , Card, CardContent, TextField, Grid, Box, useMediaQuery
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Fab from '@mui/material/Fab';
import { useCallback, useEffect } from "react";
import DropDownIndustry from './DropDownIndustry';
import DropDownLoanUse from './DropDownLoanUse';
import DropDownRealEstate from './DropDownRealEstate'
import { useTheme } from "@emotion/react";



function SliderSelect({ data, setData, industry, setIndustry, industryError, setIndustryError
    , loanUse, setLoanUse, loanUseError, setLoanUseError, pricingTable
    , realEstate, setRealEstate, realEstateError, setRealEstateError
    , express, showRealEstate }) {

    //const [formatAmountState, setFormatAmountState] = useState(formatAmount(data.amount))
    //console.log(formatAmountState)

    const theme = useTheme();

    const isXSmallScreen = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        const intcalc = data.interest / 12
        const termcalc = data.term * 12
        const calc1 = (1 + intcalc) ** termcalc
        //const pmtcalc = data.amount * (intcalc * calc1) / (calc1 - 1)
        const pmtcalc = data.amount * ((data.interest / 12) * ((1 + (data.interest / 12)) ** (data.term * 12))) / (((1 + (data.interest / 12)) ** (data.term * 12)) - 1)

        //console.log('intcalc: ',intcalc)
        //console.log('termcalc: ',termcalc)
        //console.log('pmtcalc: ',pmtcalc)
        setData({
            ...data
            , amount: data.amount
            , payment: pmtcalc
            , amountformatted: formatAmount(data.amount)
        })
        //setFormatAmountState(formatAmount(data.amount))
        //console.log('use effect', data.amount, ' ', data.payment, ' ', formatAmount(data.amount))
    }, [data.amount])

    const handleInput = (e) => {

        const intcalc = data.interest / 12
        const termcalc = data.term * 12
        const calc1 = (1 + intcalc) ** termcalc
        const pmtcalc = data.amount * (intcalc * calc1) / (calc1 - 1)

        const formattedAmount = formatAmount(e.target.value);

        setData({
            ...data
            , amount: e.target.value
            , amountformatted: formattedAmount
            , payment: pmtcalc
            , amtInteract: true
        })

    }

    const handleTypedInput = (e) => {

        const intcalc = data.interest / 12
        const termcalc = data.term * 12
        const calc1 = (1 + intcalc) ** termcalc
        const pmtcalc = data.amount * (intcalc * calc1) / (calc1 - 1)

        const formattedAmount = formatAmount(Number(e.target.value.replace(/\D/g, '')));

        //console.log('handleTypedInput test')
        //console.log('handleTypedInput formattedAmount only numeric: ', e.target.value.replace(/\D/g, ''))
        //console.log('handleTypedInput formattedAmount: ', formattedAmount)
        setData({
            ...data
            , amount: limitAmount(Number(e.target.value.replace(/\D/g, '')))
            , amountformatted: formattedAmount
            , payment: pmtcalc
        })
        //console.log(data.amount)
        //setFormatAmountState(e.target.value)

    }

    const handleTypedBlur = (e) => {
        const intcalc = data.interest / 12
        const termcalc = data.term * 12
        const calc1 = (1 + intcalc) ** termcalc
        const pmtcalc = data.amount * (intcalc * calc1) / (calc1 - 1)

        switch (true) {
            case (Number(e.target.value.replace(/\D/g, '')) < data.minamount):

                setData({
                    ...data
                    , amount: data.minamount
                    , amountformatted: formatAmount(data.minamount)
                    , payment: pmtcalc
                });
                break;
            case (Number(e.target.value.replace(/\D/g, '')) > data.maxamount):

                setData({
                    ...data
                    , amount: data.maxamount
                    , amountformatted: formatAmount(data.maxamount)
                    , payment: pmtcalc
                })
                break;
            case (Number(e.target.value.replace(/\D/g, '')) >= data.minamount && Number(e.target.value.replace(/\D/g, '')) <= data.maxamount):

                setData({
                    ...data
                    , amount: Number(e.target.value.replace(/\D/g, ''))
                    , amountformatted: e.target.value
                    , payment: pmtcalc
                })
                break;
        }
    }

    const handleIncrement = () => {
        const intcalc = data.interest / 12
        const termcalc = data.term * 12
        const calc1 = (1 + intcalc) ** termcalc
        const pmtcalc = data.amount * (intcalc * calc1) / (calc1 - 1)

        const formattedAmount = formatAmount(data.amount + data.stepamount);

        switch (true) {
            case (data.amount + data.stepamount < data.minamount):

                setData({
                    ...data
                    , amount: data.minamount
                    , amountformatted: formatAmount(data.minamount)
                    , payment: pmtcalc
                });
                break;
            case (data.amount + data.stepamount > data.maxamount):

                setData({
                    ...data
                    , amount: data.maxamount
                    , amountformatted: formatAmount(data.maxamount)
                    , payment: pmtcalc
                })
                break;
            case (data.amount + data.stepamount >= data.minamount && data.amount + data.stepamount <= data.maxamount):

                setData({
                    ...data
                    , amountformatted: formattedAmount
                    , amount: data.amount + data.stepamount
                    , payment: pmtcalc
                })
                break;
        }
    }

    const handleDecrement = () => {

        const intcalc = data.interest / 12
        const termcalc = data.term * 12
        const calc1 = (1 + intcalc) ** termcalc
        const pmtcalc = data.amount * (intcalc * calc1) / (calc1 - 1)

        //console.log(data.amount)
        const formattedAmount = formatAmount(data.amount - data.stepamount);
        //setData({
        //    ...data
        //    , amount: data.amount - data.stepamount
        //    , amountformatted: formattedAmount
        //    , payment: pmtcalc
        //})

        switch (true) {
            case (data.amount - data.stepamount < data.minamount):

                setData({
                    ...data
                    , amount: data.minamount
                    , amountformatted: formatAmount(data.minamount)
                    , payment: pmtcalc
                    , amtInteract: true
                });
                break;
            case (data.amount + data.stepamount > data.maxamount):

                setData({
                    ...data
                    , amount: data.maxamount
                    , amountformatted: formatAmount(data.maxamount)
                    , payment: pmtcalc
                    , amtInteract: true
                })
                break;
            case (data.amount - data.stepamount >= data.minamount && data.amount + data.stepamount <= data.maxamount):

                setData({
                    ...data
                    , amountformatted: formattedAmount
                    , amount: data.amount - data.stepamount
                    , payment: pmtcalc
                    , amtInteract: true
                })
                break;
        }
    }

    console.log('realEstate: ', realEstate)

    function formatAmount(value) {
        if (!value) return value;
        //console.log(value)
        const amountValue = value.toString().replace(/\D/g, '');
        //console.log('amountValue: ',amountValue)
        const amountValueTrim = amountValue.length >= 8 ? amountValue.substring(0, 7) : amountValue;
        const amountValueTrimLength = amountValueTrim.length;
        if (amountValueTrimLength < 4) return `$${amountValueTrim}`;
        if (amountValueTrimLength < 7) return `$${amountValueTrim.substring(0, amountValueTrim.length - 3)},${amountValueTrim.substring(amountValueTrim.length - 3, amountValueTrim.length)}`;
        if (amountValueTrimLength < 8) return `$${amountValueTrim.substring(0, amountValueTrim.length - 6)},${amountValueTrim.substring(amountValueTrim.length - 6, amountValueTrim.length - 3)},${amountValueTrim.substring(amountValueTrim.length - 3, amountValueTrim.length)}`;
        //console.log('amountValueTrim: ',amountValueTrim)
    }

    function limitAmount(value) {
        if (!value) return value;
        //console.log(value)
        const limitValue = value.toString().replace(/\D/g, '');
        //console.log('limitValue: ',limitValue)
        const limitValueTrim = limitValue.length >= 8 ? limitValue.substring(0, 7) : limitValue;
        return `${limitValueTrim}`;
        //console.log('limitValueTrim: ',limitValueTrim)
    }

    /*const Item = styled('div')(({ theme }) => ({
        //backgroundColor: theme.palette.mode === 'dark' ? '#262B32' : '#fff',
        padding: theme.spacing(1),
        textAlign: 'center',
        borderRadius: 4,
    }));
    */

    return (
        <div>
            <Stack mt={4} >
                <Typography variant="h6" sx={{ fontWeight: 700, color: theme.palette.primary.main }}>
                    Step 1:
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                    Tell us about your funding needs:
                </Typography>
                <br />
                <Stack alignItems="center" >
                    <Box
                        sx={{
                            width: { xs: '100%' },
                            display: 'flex',
                            justifyContent: 'center', // Center the Grid horizontally
                            overflow: 'hidden' // Ensure overflow doesn't affect centering
                        }}
                    >
                        <Grid container spacing={1} sx={{ maxWidth: '100%' }}> {/* Ensure Grid takes full width of its parent */}
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <DropDownIndustry industry={industry} setIndustry={setIndustry}
                                    industryError={industryError} setIndustryError={setIndustryError}
                                    pricingTable={pricingTable} realEstate={realEstate}
                                    loanUse={loanUse} express={express}
                                    data={data} setData={setData} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                {/* <Stack
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                sx={{ width: '100%' }} // Ensure Stack takes full width of Grid item
                            > */}
                                <DropDownLoanUse loanUse={loanUse} setLoanUse={setLoanUse}
                                    loanUseError={loanUseError} setLoanUseError={setLoanUseError}
                                    pricingTable={pricingTable} 
                                    realEstateError={realEstateError} setRealEstateError={setRealEstateError}
                                    realEstate={realEstate} setRealEstate={setRealEstate}
                                    industry={industry} express={express}
                                    data={data} setData={setData} />
                                {/* </Stack> */}
                            </Grid>
                            {showRealEstate
                                ? (
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <DropDownRealEstate realEstate={realEstate} setRealEstate={setRealEstate}
                                            realEstateError={realEstateError} setRealEstateError={setRealEstateError}
                                            pricingTable={pricingTable} loanUse={loanUse}
                                            industry={industry} express={express}
                                            data={data} setData={setData} />
                                    </Grid>)
                                : <></>
                            }
                        </Grid>
                    </Box>
                </Stack>
                <br />
                <br />
                <Stack alignItems="center" >


                    <Stack direction="row" spacing={2} alignItems="center">

                        <Fab color="primary" aria-label="add" display="inline" size="small"
                            sx={{ backgroundColor: 'primary.dark', zIndex: 0 }}
                            onClick={() => handleDecrement()}>
                            <RemoveIcon />
                        </Fab>


                        <Card sx={{ width: { xs: 157, sm: 157, md: 200, lg: 200 } }} alignItems="center">
                            <CardContent alignItems="center">
                                {/*
                                    <Typography color="#06508e" sx={{ fontSize: { xs: '25px', sm: '25px', md: '30px', lg: '30px' , fontWeight: 550} }} alignItems="center">
                                        {formatAmount(data.amount)}
                                    </Typography>
                                    */}
                                <TextField
                                    //sx={{ input: { color: "#06508e" } }}
                                    variant="standard"
                                    value={data.amountformatted}
                                    sx={{
                                        input: { color: 'primary.dark' },
                                        '.MuiInputBase-input': { fontSize: { xs: '22px', sm: '25px', md: '30px', lg: '30px', fontWeight: 550 } },
                                    }}
                                    InputProps={{
                                        disableUnderline: true, // <== added this
                                        inputProps: {
                                            style: { textAlign: "center" },
                                        }
                                    }}
                                    //style={{ width: 200 }}
                                    fullWidth
                                    onChange={e => handleTypedInput(e)}
                                    onBlur={e => handleTypedBlur(e)}
                                >
                                </TextField >
                            </CardContent>
                        </Card>


                        <Fab color="primary" aria-label="add" display="inline" size="small"
                            sx={{ backgroundColor: 'primary.dark', zIndex: 0 }}
                            onClick={() => handleIncrement()}
                        >
                            <AddIcon />
                        </Fab>

                    </Stack>
                </Stack>
            </Stack>
            <Slider defaultValue={data.amount}
                min={data.minamount}
                max={data.maxamount}
                aria-label="Default"
                step={data.stepamount}
                onChange={e => handleInput(e)}
                value={data.amount}
                valueLabelDisplay="off"
                sx={{ color: 'primary.dark', marginTop: 4, padding: 0 }}
            />
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" color="text.secondary"> {formatAmount(data.minamount)} </Typography>
                <Typography variant="subtitle2" color="text.secondary"> {formatAmount(data.maxamount)} </Typography>
            </Stack>
        </div>
    )
}

export default SliderSelect