import * as React from 'react';
import { Grid, Box, Card, Typography, Stack } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Grow from '@mui/material/Grow';
import LearnMoreDetail from './LearnMoreDetail';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import learnmore from '../images/learnmore.png'



export default function LearnMore() {

    const theme = useTheme();
    const theme2 = createTheme();
    const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
        <div style={{ marginTop: '0rem', height: '100%' }}>
            <Card
                style={{
                    color: 'white',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    padding: 0,
                    marginTop: 0,
                    marginBottom: '-25px',
                    height: '100%',
                    borderRadius: 0,
                    position: 'relative', // Ensure relative positioning for absolute children
                    overflow: 'hidden', // Clip overflowing content (like the image)
                }}
            >
                <div style={{ position: 'relative', zIndex: 1, textAlign: 'center', padding: '20px' }}>
                    <Card
                        sx={{
                            boxShadow: 'none',
                            bgcolor: 'transparent',
                            height: '100%' // Ensure the card takes up full height
                        }}
                    >
                        <Stack
                            direction={{ xs: 'column', md: 'row' }}
                            justifyContent="center"
                            alignItems="stretch" // Ensure items stretch to equal height
                            spacing={0}
                            sx={{ margin: { xs: 0, md: 2 } }}
                        >
                            <Grid container spacing={2} justifyContent="center" alignItems="stretch">
                                <Grid item xs={12} sm={12} md={6} lg={6}
                                    sx={{
                                        display: 'flex', flexDirection: 'column', height: '100%',
                                        order: isMediumScreen ? 2 : 1
                                    }}>
                                    <LearnMoreDetail />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    //justifyContent: 'space-between', // Distribute space between elements
                                    height: '100%', // Ensure the grid item takes full height
                                    order: isMediumScreen ? 1 : 2
                                }}>
                                    <br />
                                    <Typography
                                        sx={{
                                            fontSize: { xs: 50, sm: 54, md: 54, lg: 64 },
                                            textAlign: { xs: 'center', sm: 'center', md: 'left', lg: 'left'},
                                            position: 'relative',
                                            zIndex: 10,
                                            lineHeight: 1.1,
                                        }}
                                        fontWeight={500}
                                        color={theme.palette.primary.dark}
                                    >
                                        Ready to Learn More?
                                    </Typography>
                                    {!isMediumScreen && (
                                        <div style={{
                                            width: '80%',
                                            height: '300px', // Adjust the height as needed
                                            position: 'relative',
                                            overflow: 'hidden', // Ensures content does not overflow and respects rounded corners
                                            marginBottom: 'auto', // Pushes image to the bottom of the container
                                            marginTop: 50,
                                            borderRadius: '20px', // Apply border-radius here
                                        }}>
                                            
                                            <img
                                                src={learnmore}
                                                alt="Learn More"
                                                style={{
                                                    position: 'absolute',
                                                    top: '0', // Align image to the top
                                                    left: '0',
                                                    width: '100%',
                                                    height: '100%', // Make sure it covers the container
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </Stack>
                    </Card>
                    <br/>
                    <br/>
                </div>
            </Card>
        </div>
    )
}