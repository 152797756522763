import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography, Stack } from '@mui/material';
import Download from './DownloadForm';
import Form1919 from '../images/Form1919.pdf'
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async'


function Forms() {
    const theme = useTheme();

    return (
        <div style={{ marginTop: '40px', marginLeft: '50px' }} className="Forms">
            <Helmet>
                <title>CapTec USA | Download Forms</title>
                <meta name="description" content="All your SBA forms, conveniently in one place. Download anytime at your convenience." />
                <link rel="canonical" href="https://thenewsouthbank.com/forms" />
            </Helmet>
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >
                <Box sx={{
                    width: '70vw',
                    height: '5rem',
                    borderBottom: 1,
                    textAlign: 'left',
                    m: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    marginLeft: '-30rem'
                }}>
                    <Typography fontSize={30} fontWeight={500} color={theme.palette.primary.main}    //blue
                    //style={{ paddingBottom: '1rem' }} {/* Adjust padding to control distance from bottom */}
                    >
                        Forms
                    </Typography>
                </Box>
                <br />
                <Typography sx={{fontSize: {xs: 18, sm: 20, md: 22, lg: 24}, 
                                paddingRight: { xs: '50px', sm: '80px', md: '100px', lg: '300px' } }} 
                            fontWeight={500} 
                            color={'secondary.light'}    //blue
                >
                    Below, you'll find downloadable forms for your convenience. Simply click on the buttons to download the forms you need.
                </Typography>
                <br />
                <Download file={Form1919} buttonName='SBA Form 1919' fileName='Form1919' />
            </Stack>
        </div>
    );
}

export default Forms;
