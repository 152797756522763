import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Stack, Typography } from '@mui/material';
import { useCallback } from 'react';


        

function RealEstate({ data, setData }) {

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        
        //const intcalc = event.target.checked ? data.marginRE_yes / 12 : data.marginRE_no / 12
        //const termcalc = event.target.checked ? data.termRE_yes * 12 : data.termRE_no * 12
        //const calc1 = (1+intcalc)**termcalc
        //const pmtcalc=data.amount*(intcalc*calc1)/(calc1-1)
        //const pmtcalc = props.data.amount * ((data.inerest / 12) * ((1 + (data.inerest / 12)) ** (data.term * 12))) / (((1 + (data.inerest / 12)) ** (data.term * 12)) - 1)
        //const pmtcalc=data.amount*(intcalc*((1+intcalc)**termcalc))/(((1+intcalc)**termcalc)-1)
        
        setData({...data,
                    interest: event.target.checked ? data.marginRE_yes + data.prime : data.marginRE_no + data.prime, 
                    margin: event.target.checked ? data.marginRE_yes : data.marginRE_no, 
                    term: event.target.checked ? data.termRE_yes : data.termRE_no,
                    realestate: event.target.checked,
                    payment: event.target.checked 
                                ? data.amount * (((data.marginRE_yes + data.prime) / 12) * ((1+((data.marginRE_yes + data.prime) / 12))**(data.termRE_yes * 12))) / (((1+((data.marginRE_yes + data.prime) / 12))**(data.termRE_yes * 12))-1)
                                : data.amount * (((data.marginRE_no + data.prime) / 12) * ((1+((data.marginRE_no + data.prime) / 12))**(data.termRE_no * 12))) / (((1+((data.marginRE_no + data.prime) / 12))**(data.termRE_no * 12))-1)})
      });

    return (
        <Stack direction="row" justifyContent={'center'}>
            <FormGroup>
                <FormControlLabel 
                value={data.realestate}
                control={<Checkbox checked={data.realestate}
                        onChange={handleChange}
                        color="default" />}
                label={<Typography variant="h7" sx={{fontWeight: 550}}>Is real estate being financed?</Typography>} 
                />

            </FormGroup>
        </Stack>
    )
}

export default RealEstate