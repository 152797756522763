import React, {useEffect, useState, useRef } from 'react';
import Card from '@mui/material/Card';
import { Link, Stack } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import { keyframes } from '@mui/system';

const popOutIn = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05); /* Slightly scale up */
  }
  100% {
    transform: scale(1);
  }
`;  

export default function LoanUsesWorkingCapitalCard({ width, height, checked, text, keyName, descr, linkClick, buttonText, icon }) {
    const theme = useTheme();
    const [animate, setAnimate] = useState(false);

    const prevCheckedRef = useRef(checked);

  useEffect(() => {
    // Check if the checked prop has changed
    if (prevCheckedRef.current !== checked) {
      // Trigger animation on checked change
      setAnimate(true);
      const timer = setTimeout(() => {
        setAnimate(false);
      }, 500); // Duration of the animation

      // Update ref to current checked value
      prevCheckedRef.current = checked;

      // Cleanup the timeout if the component unmounts or checked changes again
      return () => clearTimeout(timer);
    }
  }, [checked]);

    



    return (
        <div style={{ display: 'flex', justifyContent: 'center' }} >
            <Card sx={{
                display: 'flex', width: width, height: height,
                alignItems: 'center',
                backgroundColor: alpha(theme.palette.common.white, 0.6),
                transition: 'background-color 0.3s',
                border: `1px solid ${theme.palette.primary.bright}`,
                boxShadow: 'none',
                animation: animate ? `${popOutIn} 0.5s` : 'none',

            }}>
                {/*  <CardMedia
                    sx={{
                        maxWidth: { xs: '30%', md: '40%' }, // Set the width of the image container
                        height: '100%', // Set the width of the image container
                        objectFit: 'cover', // Ensure the image covers the container
                    }}
                    component="img"
                    image={image}
                    alt={imagename}
                /> */}
                <CardContent sx={{
                    display: 'flex',          // Use flexbox
                    flexDirection: 'column',  // Align children in a column
                    justifyContent: 'center', // Center items vertically
                    alignItems: 'flex-start',     // Center items horizontally
                    height: '100%',
                    width: '100%',
                    padding: 2
                }} >
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={{ xs: 1, sm: 2 }}

                    >
                        {React.cloneElement(icon, { sx: { color: theme => theme.palette.primary.red } })}
                        <Typography variant="h4" fontWeight={550}
                            sx={{
                                fontSize: {
                                    xs: '16px', // Adjust font size for extra-small screens (phones)
                                    sm: '16px', // Adjust font size for small screens (tablets)
                                    md: '16px', // Adjust font size for medium screens (small desktops)
                                    lg: '16px', // Adjust font size for large screens (desktops)
                                    xl: '16px', // Adjust font size for extra-large screens (large desktops and above)
                                },
                                color: theme.palette.primary.dark, textAlign: 'flex-start', width: '100%', lineHeight: 1.5,
                            }}>
                            {text}
                        </Typography>
                    </Stack>
                    <Typography
                        sx={{
                            fontSize: {
                                xs: '16px', // Adjust font size for extra-small screens (phones)
                                sm: '16px', // Adjust font size for small screens (tablets)
                                md: '16px', // Adjust font size for medium screens (small desktops)
                                lg: '16px', // Adjust font size for large screens (desktops)
                                xl: '16px', // Adjust font size for extra-large screens (large desktops and above)
                            },
                            color: 'secondary.light', textAlign: 'left', width: '100%',
                        }}>
                        {descr}
                    </Typography>

                </CardContent>
            </Card>
        </div>
    );
}
