import { Stack, Typography, useMediaQuery } from "@mui/material"

export default function VerticalTabs({ image, timing, descr }) {
    const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
        >
            {isMediumScreen ?
                <></>
                :
                <img src={image}
                    alt='Small Business Lending'
                    style={{
                        width: '50%',
                        maxHeight: '350px',
                        minHeight: '250px',
                        objectFit: 'cover',
                        objectPosition: 'center 0%'  // Adjust the vertical position here
                    }}
                >
                </img>
            }
            <div style={{ width: isMediumScreen ? '100%' : '50%', }}>
                <Typography fontSize={12} fontWeight={600} color='secondary.main'   //blue
                >
                    {timing}
                </Typography>
                <br />
                <Typography fontSize={16} fontWeight={600} color='secondary.light'    //blue
                >
                    {descr}
                </Typography>
            </div>

        </Stack>
    )
}                        