import * as React from 'react';
import { Stack, Grid, Card, CardMedia, CardContent, Box, Typography } from '@mui/material';
import mainstreet from '../images/mainstreet.png'
import LoanUsesSubNew from './LoanUsesSubNew';
import { useTheme } from '@mui/material/styles';

export default function LoanUsesNew() {
    const theme = useTheme();

    return (
        <div style={{ marginTop: '0rem', height: '100%' }}>

            <Card style={{
                color: 'white',
                backgroundColor: theme.palette.primary.main,
                boxShadow: 'none',
                padding: 0,
                marginTop: 0,
                marginBottom: '-25px',
                height: '100%',
                borderRadius: 0,
                position: 'relative', // Ensure relative positioning for absolute children
                overflow: 'hidden', // Clip overflowing content (like the image)
            }}>
                {/* Content within the card */}
                <div style={{ position: 'relative', zIndex: 1, textAlign: 'center', padding: '20px' }}>
                    <Card sx={{
                        boxShadow: 'none',
                        bgcolor: 'transparent',
                    }}>
                        <br />
                        {/* <Stack direction="row" justifyContent="center" alignItems="center" spacing={3} marginBottom={10} marginTop={5} > */}
                        <Stack
                            direction={{ xs: 'column', md: 'row' }}
                            justifyContent="center"
                            alignItems="center"
                            spacing={0}
                            sx={{ margin: { xs: 0, md: 2 } }}
                        >
                            <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
                                {/* <Grid item xs={12} md={4} > */}
                                    {/* <Grid container spacing={2} > */}
                                    <Grid item xs={12} sm={12} md={5} sx={{ order: 1 }}>
                                    <Typography color='white' sx={{ fontSize: {xs: 35, sm: 40, md: 45, lg: 48} }} fontWeight={700}>   {/* { xs: 20, sm: 22, md: 26, lg: 26 } */}
                                            {/* SBA loans offer a wide range{<br />}of options in how you can use the funds.{<br />}From purchasing a new building to{<br />}plain old working capital,{<br />}we've got you covered. */}
                                            Flexible Financing for All Your Business Needs
                                        </Typography>
                                        <br/>
                                        <Typography color='white' sx={{ fontStyle: 'italic', fontSize: {xs: 18, sm: 20, md: 24, lg: 26} }}>   {/* { xs: 20, sm: 22, md: 26, lg: 26 } */}
                                            {/* SBA loans offer a wide range{<br />}of options in how you can use the funds.{<br />}From purchasing a new building to{<br />}plain old working capital,{<br />}we've got you covered. */}
                                            From acquisitions to expansions, and everything in between, the SBA 7(a) program offers flexible financing options tailored to meet the needs of any business goal.
                                            <br />
                                            <br />
                                        </Typography>
                                    {/* </Grid> */}
                                </Grid>
                                <Grid item xs={12} sm={12} md={7} sx={{ order: 2 }}>
                                    <LoanUsesSubNew/>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Card>
                </div>

                {/* Absolute positioned image */}
                <img
                    src={mainstreet}
                    alt='flag'
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        zIndex: 0, // Ensure the image is behind other content
                        maskImage: 'linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%)', // Gradient mask
                        WebkitMaskImage: 'linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%)', // For Safari
                    }}
                />
            </Card >
            <br />
        </div >
    );
}

