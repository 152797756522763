import * as React from 'react';
import NextButtonFirst from './NextButtonFirst';
import { Stack, Typography, Grid } from "@mui/material";
import InputTextFull from './InputTextFull'
import { useEffect } from 'react';
import InputDropdown from './InputDropdown';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import InputDate from './InputDate'


function Step1({ handleBack, handleNext, data, setData, contactInfo, setContactInfo, steps, setSteps, qstepdata, setQStepData}) {

    const url = window.location.href
    data.customurl == '' ? setData({ ...data, customurl: url }) : <></>

    useEffect(() => {
        //console.log('step1 useEffect qstepdata : ', qstepdata)
        if (qstepdata.legalBusinessName != ""
            && qstepdata.entityType != ""
            && qstepdata.employees != ""
            && qstepdata.TIN.length == "11"
            && qstepdata.dateBusinessEst != ""
            && qstepdata.busStreet1 != ""
            && qstepdata.busCity != ""
            && qstepdata.busState != ""
            && qstepdata.busZip.length == "5"
        ) {
            setSteps(steps => ({ ...steps, 0: true }));
            //console.log('step6 complete: ', driverslicense.size, year1bustax.size, year2bustax.size, personaltax.size)
        } else {
            setSteps(steps => ({ ...steps, 0: false }));
            //console.log('step6 incomplete: ', driverslicense.size, year1bustax.size, year2bustax.size, personaltax.size)
        }
    }, [qstepdata.legalBusinessName, qstepdata.entityType, qstepdata.employees
        , qstepdata.TIN, qstepdata.dateBusinessEst, qstepdata.busStreet1, qstepdata.busCity
        , qstepdata.busState, qstepdata.busZip
    ]);

    function formatAmount(value) {
        if (!value) return value;
        //console.log(value)
        const amountValue = value.toString().replace(/\D/g, '');
        //console.log('amountValue: ',amountValue)
        const amountValueTrim = amountValue.length >= 8 ? amountValue.substring(0, 7) : amountValue;
        const amountValueTrimLength = amountValueTrim.length;
        if (amountValueTrimLength < 4) return `$${amountValueTrim}`;
        if (amountValueTrimLength < 7) return `$${amountValueTrim.substring(0, amountValueTrim.length - 3)},${amountValueTrim.substring(amountValueTrim.length - 3, amountValueTrim.length)}`;
        if (amountValueTrimLength < 8) return `$${amountValueTrim.substring(0, amountValueTrim.length - 6)},${amountValueTrim.substring(amountValueTrim.length - 6, amountValueTrim.length - 3)},${amountValueTrim.substring(amountValueTrim.length - 3, amountValueTrim.length)}`;
        //console.log('amountValueTrim: ',amountValueTrim)
    }

    function limitAmount(value) {
        if (!value) return value;
        //console.log(value)
        const limitValue = value.toString().replace(/\D/g, '');
        //console.log('limitValue: ',limitValue)
        const limitValueTrim = limitValue.length >= 8 ? limitValue.substring(0, 7) : limitValue;
        return `${limitValueTrim}`;
        //console.log('limitValueTrim: ',limitValueTrim)
    }

    const handleTypedInput = (e) => {

        const intcalc = data.interest / 12
        const termcalc = data.term * 12
        const calc1 = (1 + intcalc) ** termcalc
        const pmtcalc = data.amount * (intcalc * calc1) / (calc1 - 1)

        const formattedAmount = formatAmount(Number(e.target.value.replace(/\D/g, '')));

        //console.log('handleTypedInput test')
        //console.log('handleTypedInput formattedAmount only numeric: ', e.target.value.replace(/\D/g, ''))
        //console.log('handleTypedInput formattedAmount: ', formattedAmount)
        setData({
            ...data
            , amount: limitAmount(Number(e.target.value.replace(/\D/g, '')))
            , amountformatted: formattedAmount
            , payment: pmtcalc
        })
        //console.log(data.amount)
        //setFormatAmountState(e.target.value)

        setQStepData({
            ...qstepdata
            , amountformatted: formattedAmount
        })

    }

    const handleTypedBlur = (e) => {
        const intcalc = data.interest / 12
        const termcalc = data.term * 12
        const calc1 = (1 + intcalc) ** termcalc
        const pmtcalc = data.amount * (intcalc * calc1) / (calc1 - 1)

        switch (true) {
            case (Number(e.target.value.replace(/\D/g, '')) < data.minamount):

                setData({
                    ...data
                    , amount: data.minamount
                    , amountformatted: formatAmount(data.minamount)
                    , payment: pmtcalc
                });
                setQStepData({
                    ...qstepdata
                    , amountformatted: formatAmount(data.minamount)
                })
                break;
            case (Number(e.target.value.replace(/\D/g, '')) > data.maxamount):

                setData({
                    ...data
                    , amount: data.maxamount
                    , amountformatted: formatAmount(data.maxamount)
                    , payment: pmtcalc
                })
                setQStepData({
                    ...qstepdata
                    , amountformatted: formatAmount(data.maxamount)
                })
                break;
            case (Number(e.target.value.replace(/\D/g, '')) >= data.minamount && Number(e.target.value.replace(/\D/g, '')) <= data.maxamount):

                setData({
                    ...data
                    , amount: Number(e.target.value.replace(/\D/g, ''))
                    , amountformatted: e.target.value
                    , payment: pmtcalc
                })
                setQStepData({
                    ...qstepdata
                    , amountformatted: e.target.value
                })
                break;
        }
    }

    function formatName(value) {
        if (!value) return value;
        const newName = value.replace(/[^\w\s,.\'\-]/g, '');
        const newNameLength = newName.length;
        if (newNameLength <= 20) {
            return newName;
        }
        return `${newName.slice(0, 20)}`;
    }

    function formatBusinessName(value) {
        if (!value) return value;
        const newBusinessName = value.replace(/[^\w\s,.@()&\'\-]/g, '');
        const newBusinessNameLength = newBusinessName.length;
        if (newBusinessNameLength <= 40) {
            return newBusinessName;
        }
        return `${newBusinessName.slice(0, 40)}`;
    }



    function formatSSN(value) {
        if (!value) return value;
        const socialsn = value.replace(/[^\d]/g, '');
        const socialsnLength = socialsn.length;
        if (socialsnLength < 4) return socialsn;
        if (socialsnLength < 6) {
            return `${socialsn.slice(0, 3)}-${socialsn.slice(3)}`;
        }
        return `${socialsn.slice(0, 3)}-${socialsn.slice(3, 5,)}-${socialsn.slice(5, 9)}`;
    }

    const handleLegalBusinessName = e => {
        const formattedName = formatBusinessName(e.target.value)
        setQStepData({
            ...qstepdata
            , legalBusinessName: formattedName
        })
        setContactInfo({
            ...contactInfo
            , businessname: formattedName
        })
    }

    const handleWebsite = e => {
        setQStepData({
            ...qstepdata
            , website: e.target.value
        })
    }

    const handleTIN = e => {
        const formattedSSN = formatSSN(e.target.value)
        setQStepData({
            ...qstepdata
            , TIN: formattedSSN
        })
        //console.log(contactInfo.dob)
    }



    const handleBusStreet1 = e => {
        const formattedName = formatBusinessName(e.target.value)
        setQStepData({
            ...qstepdata
            , busStreet1: formattedName
        })
    }

    const handleBusStreet2 = e => {
        const formattedName = formatBusinessName(e.target.value)
        setQStepData({
            ...qstepdata
            , busStreet2: formattedName
        })
    }

    const handleBusCity = e => {
        const formattedName = formatBusinessName(e.target.value)
        setQStepData({
            ...qstepdata
            , busCity: formattedName
        })
    }

    const handleBusState = e => {
        const formattedName = formatBusinessName(e.target.value)
        setQStepData({
            ...qstepdata
            , busState: formattedName
        })
    }

    function formatZip(value) {
        if (!value) return value;
        const Zip = value.replace(/[^\d]/g, '');
        const ZipLength = Zip.length;
        if (ZipLength <= 5) {
            return Zip;
        }
        return `${Zip.slice(0, 5)}`;
    }

    const handleBusZip = e => {
        const formattedZip = formatZip(e.target.value)
        setQStepData({
            ...qstepdata
            , busZip: formattedZip
        })
    }

    function formatEmployees(value) {
        if (!value) return value;
        const Employees = value.replace(/[^\d]/g, '');
        const EmployeesLength = Employees.length;
        if (EmployeesLength <= 5) {
            return parseInt(Employees).toLocaleString();
        }
        return parseInt(Employees.slice(0, 5)).toLocaleString();
    }


    const handleEmployees = e => {
        const formattedEmployees = formatEmployees(e.target.value)
        setQStepData({
            ...qstepdata
            , employees: formattedEmployees
        })
    }

    const handleDateFieldBlur = () => {
        setQStepData({ ...qstepdata, dateBusinessEstError: qstepdata.dateBusinessEst == "" ? true : false })
    };

    let cnt = 0
    useEffect(() => {
        cnt++
        //console.log(cnt)
        cnt > 2 ? handleDateFieldBlur() : <></>//console.log('useeffect pass')
        //console.log('useeffect')
        //console.log(data.amount, data.payment)
    }, [qstepdata.dateBusinessEst])

    const stateDropDown = [
        { label: '', value: '' },
        { label: 'Alabama', value: 'Alabama' },
        { label: 'Alaska', value: 'Alaska' },
        { label: 'Arizona', value: 'Arizona' },
        { label: 'Arkansas', value: 'Arkansas' },
        { label: 'California', value: 'California' },
        { label: 'Colorado', value: 'Colorado' },
        { label: 'Connecticut', value: 'Connecticut' },
        { label: 'Delaware', value: 'Delaware' },
        { label: 'Florida', value: 'Florida' },
        { label: 'Georgia', value: 'Georgia' },
        { label: 'Hawaii', value: 'Hawaii' },
        { label: 'Idaho', value: 'Idaho' },
        { label: 'Illinois', value: 'Illinois' },
        { label: 'Indiana', value: 'Indiana' },
        { label: 'Iowa', value: 'Iowa' },
        { label: 'Kansas', value: 'Kansas' },
        { label: 'Kentucky', value: 'Kentucky' },
        { label: 'Louisiana', value: 'Louisiana' },
        { label: 'Maine', value: 'Maine' },
        { label: 'Maryland', value: 'Maryland' },
        { label: 'Massachusetts', value: 'Massachusetts' },
        { label: 'Michigan', value: 'Michigan' },
        { label: 'Minnesota', value: 'Minnesota' },
        { label: 'Mississippi', value: 'Mississippi' },
        { label: 'Missouri', value: 'Missouri' },
        { label: 'Montana', value: 'Montana' },
        { label: 'Nebraska', value: 'Nebraska' },
        { label: 'Nevada', value: 'Nevada' },
        { label: 'New Hampshire', value: 'New Hampshire' },
        { label: 'New Jersey', value: 'New Jersey' },
        { label: 'New Mexico', value: 'New Mexico' },
        { label: 'New York', value: 'New York' },
        { label: 'North Carolina', value: 'North Carolina' },
        { label: 'North Dakota', value: 'North Dakota' },
        { label: 'Ohio', value: 'Ohio' },
        { label: 'Oklahoma', value: 'Oklahoma' },
        { label: 'Oregon', value: 'Oregon' },
        { label: 'Pennsylvania', value: 'Pennsylvania' },
        { label: 'Rhode Island', value: 'Rhode Island' },
        { label: 'South Carolina', value: 'South Carolina' },
        { label: 'South Dakota', value: 'South Dakota' },
        { label: 'Tennessee', value: 'Tennessee' },
        { label: 'Texas', value: 'Texas' },
        { label: 'Utah', value: 'Utah' },
        { label: 'Vermont', value: 'Vermont' },
        { label: 'Virginia', value: 'Virginia' },
        { label: 'Washington', value: 'Washington' },
        { label: 'Washington, D.C.', value: 'Washington, D.C.' },
        { label: 'West Virginia', value: 'West Virginia' },
        { label: 'Wisconsin', value: 'Wisconsin' },
        { label: 'Wyoming', value: 'Wyoming' }
    ]

    const typeDropDown = [
        { label: '', value: '' },
        { label: 'Sole Proprietor', value: 'Sole Proprietor' },
        { label: 'Partnership', value: 'Partnership' },
        { label: 'C-Corp', value: 'C-Corp' },
        { label: 'S-Corp', value: 'S-Corp' },
        { label: 'LLC', value: 'LLC' },
        { label: 'Other', value: 'Other' }
    ]

    //console.log('step1 qstepdata : ', qstepdata)

    return (
        <div>
            <Stack mt={0}>
                <Stack alignItems="center" sx={{ width: { md: '100%', lg: 'calc(100% - 240px)' } }} >
                    <Typography variant="h6" sx={{ fontWeight: 700, color: data.colorstep }}>
                        Step 1:
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 700 }}>
                        Business Information
                    </Typography>
                </Stack>
                <Stack alignItems="center" sx={{ width: { md: '100%', lg: 'calc(100% - 240px)' } }} >

                    <br />
                    <Grid container spacing={0} >
                        <Grid item xs={12} sm={12} md={8} lg={4}>
                            <InputTextFull
                                headingText="Loan Amount"
                                headingStyle={{ color: "red" }}
                                headingSuffix=" *"
                                subheading={`Enter a value between ${data.minamount.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })} and ${data.maxamount.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })}`}
                                type="text"
                                required={true}
                                value={qstepdata.amountformatted}
                                defaultValue={qstepdata.amountformatted}
                                error={data.amountError}
                                color={data.colorfont}
                                //autoComplete="given-name"
                                //id="given-name"
                                onChange={e => handleTypedInput(e)}
                                onBlur={e => handleTypedBlur(e)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <InputTextFull
                                headingText="Legal Business Name"
                                headingStyle={{ color: "red" }}
                                headingSuffix=" *"
                                //subheading="If you operate under a business name that differs from the legal business name, enter the name here."
                                type="text"
                                required={true}
                                value={qstepdata.legalBusinessName}
                                defaultValue={qstepdata.legalBusinessName}
                                error={qstepdata.legalBusinessNameError}
                                color={data.colorfont}
                                //autoComplete="given-name"
                                //id="given-name"
                                onChange={e => handleLegalBusinessName(e)}
                                onBlur={() => { setQStepData({ ...qstepdata, legalBusinessNameError: qstepdata.legalBusinessName == '' ? true : false }) }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} >
                            <InputTextFull
                                headingText="Website"
                                //headingStyle={{ color: "red" }}
                                //headingSuffix=" *"
                                //subheading="If you operate under a business name that differs from the legal business name, enter the name here."
                                type="text"
                                //required={true}
                                value={qstepdata.website}
                                defaultValue={qstepdata.website}
                                //error={qstepdata.legalBusinessNameError}
                                color={data.colorfont}
                                //autoComplete="given-name"
                                //id="given-name"
                                onChange={e => handleWebsite(e)}
                            //onBlur={() => { setQStepData({ ...qstepdata, legalBusinessNameError: qstepdata.legalBusinessName == '' ? true : false })}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <InputTextFull
                                headingText="TIN"
                                headingStyle={{ color: "red" }}
                                headingSuffix=" *"
                                //subheading="If you operate under a business name that differs from the legal business name, enter the name here."
                                type="text"
                                required={true}
                                value={qstepdata.TIN}
                                defaultValue={qstepdata.TIN}
                                error={qstepdata.TINError}
                                color={data.colorfont}
                                //autoComplete="given-name"
                                //id="given-name"
                                onChange={e => handleTIN(e)}
                                onBlur={() => { setQStepData({ ...qstepdata, TINError: qstepdata.TIN.length != '11' ? true : false }) }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} md={4} >
                            <InputTextFull //label="Business Street Address 1"
                                headingText="Business Street Address 1"
                                headingStyle={{ color: "red" }}
                                headingSuffix=" *"
                                subheading="Enter street address"
                                type="text"
                                required={true}
                                value={qstepdata.busStreet1}
                                defaultValue={qstepdata.busStreet1}
                                error={qstepdata.busStreet1Error}
                                color={data.colorfont}
                                //autoComplete="given-name"
                                //id="given-name"
                                onChange={e => handleBusStreet1(e)}
                                onBlur={() => { setQStepData({ ...qstepdata, busStreet1Error: qstepdata.busStreet1 == '' ? true : false }) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} >
                            <InputTextFull //label="Business Street Address 2"
                                headingText="Business Street Address 2"
                                //headingStyle={{ color: "red" }}
                                //headingSuffix=" *"
                                subheading="Enter suite, apartment or unit #"
                                type="text"
                                required={true}
                                value={qstepdata.busStreet2}
                                defaultValue={qstepdata.busStreet2}
                                //error={contactInfo.firstNameError}
                                color={data.colorfont}
                                //autoComplete="given-name"
                                //id="given-name"
                                onChange={e => handleBusStreet2(e)}
                            //onBlur={() => { setContactInfo({ ...contactInfo, firstNameError: contactInfo.firstname == '' ? true : false }) }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} >
                            <InputTextFull //label="Business City"
                                headingText="Business City"
                                headingStyle={{ color: "red" }}
                                headingSuffix=" *"
                                //subheading="Enter suite, apartment or unit #"
                                type="text"
                                required={true}
                                value={qstepdata.busCity}
                                defaultValue={qstepdata.busCity}
                                error={qstepdata.busCityError}
                                color={data.colorfont}
                                //autoComplete="given-name"
                                //id="given-name"
                                onChange={e => handleBusCity(e)}
                                onBlur={() => { setQStepData({ ...qstepdata, busCityError: qstepdata.busCity == '' ? true : false }) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} >
                            <InputDropdown
                                headingText="Business State"
                                headingStyle={{ color: "red" }}
                                headingSuffix=" *"
                                //subheading="Enter suite, apartment or unit #"
                                //type="text"
                                required={true}
                                value={qstepdata.busState}
                                defaultValue={qstepdata.busState}
                                error={qstepdata.busStateError}
                                color={data.colorfont}
                                //autoComplete="given-name"
                                //id="given-name"
                                onChange={e => {
                                    setQStepData({
                                        ...qstepdata
                                        , busState: (e.target.value)
                                    })
                                }}
                                dropdown={stateDropDown}

                                autoComplete="address-level1"
                                id="address-level1"
                                propsError={qstepdata.busStateError}
                                onBlur={() => { setQStepData({ ...qstepdata, busStateError: qstepdata.busState == '' ? true : false }) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} >
                            <InputTextFull //label="Business Zip Code"
                                headingText="Business Zip Code"
                                headingStyle={{ color: "red" }}
                                headingSuffix=" *"
                                //subheading="Enter suite, apartment or unit #"
                                type="text"
                                required={true}
                                value={qstepdata.busZip}
                                defaultValue={qstepdata.busZip}
                                error={qstepdata.busZipError}
                                color={data.colorfont}
                                //autoComplete="given-name"
                                //id="given-name"
                                onChange={e => handleBusZip(e)}
                                onBlur={() => { setQStepData({ ...qstepdata, busZipError: qstepdata.busZip.length != '5' ? true : false }) }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} md={4} >
                            <InputDate //label="Business Zip Code"
                                headingText="Established Date"
                                headingStyle={{ color: "red" }}
                                headingSuffix=" *"
                                //subheading="Enter suite, apartment or unit #"
                                type="text"
                                required={true}
                                value={qstepdata.dateBusinessEst}
                                defaultValue={qstepdata.dateBusinessEstString}
                                error={qstepdata.dateBusinessEstError}
                                color={data.colorfont}
                                //autoComplete="given-name"
                                //id="given-name"
                                onChange={(newValue) => {
                                    setQStepData({ ...qstepdata, dateBusinessEst: (newValue) })
                                    console.log('qstepdata.dateBusinessEst : ', qstepdata.dateBusinessEst)
                                }}
                                onBlur={handleDateFieldBlur}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} >
                            <InputDropdown
                                headingText="Business Type"
                                headingStyle={{ color: "red" }}
                                headingSuffix=" *"
                                //subheading="Enter suite, apartment or unit #"
                                //type="text"
                                required={true}
                                value={qstepdata.entityType}
                                defaultValue={qstepdata.entityType}
                                error={qstepdata.entityTypeError}
                                color={data.colorfont}
                                //autoComplete="given-name"
                                //id="given-name"
                                onChange={e => {
                                    setQStepData({ ...qstepdata, entityType: (e.target.value) })
                                }}
                                dropdown={typeDropDown}

                                //autoComplete="address-level1"
                                //id="address-level1"
                                propsError={qstepdata.entityTypeError}
                                onBlur={() => {
                                    setQStepData({ ...qstepdata, entityTypeError: qstepdata.entityType == "" ? true : false })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} >
                            <InputTextFull
                                headingText="Number of Employees"
                                headingStyle={{ color: "red" }}
                                headingSuffix=" *"
                                //subheading="Enter suite, apartment or unit #"
                                type="text"
                                required={true}
                                value={qstepdata.employees}
                                defaultValue={qstepdata.employees}
                                error={qstepdata.employeesError}
                                color={data.colorfont}
                                //autoComplete="given-name"
                                //id="given-name"
                                onChange={e => handleEmployees(e)}
                                onBlur={() => {
                                    setQStepData({ ...qstepdata, employeesError: parseInt(qstepdata.employees) == 0 || qstepdata.employees == "" ? true : false })
                                }}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
            <br /><br /><br />
            <NextButtonFirst handleNext={handleNext} data={data} />

        </div>
    )
}

export default Step1