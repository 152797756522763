import * as React from 'react';
import { Typography, Stack, Box, Card, CardMedia, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async'


function Contact() {

    const theme = useTheme();
    return (
        <div style={{ marginTop: '40px', marginLeft: '50px' }} className="Contact">
            <Helmet>
                <title>Contact CapTec USA</title>
                <meta name="description" content="Contact CapTec USA. Have questions? We have answers." />
                <link rel="canonical" href="https://thenewsouthbank.com/contact" />
            </Helmet>
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >
                <Box sx={{
                    width: '70vw',
                    height: '5rem',
                    borderBottom: 1,
                    textAlign: 'left',
                    m: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    marginLeft: '-30rem'
                }}>
                    <Typography fontSize={30} fontWeight={500} color={theme.palette.primary.main}    //blue
                    //style={{ paddingBottom: '1rem' }} {/* Adjust padding to control distance from bottom */}
                    >
                        Contact Us
                    </Typography>
                </Box>
            </Stack>
            {/* <Grid container spacing={0} sx={{ width: { xs: '80vw', md: '70vw' } }}>
                <Grid item xs={12} sm={12} md={5} lg={5}
                    sx={{
                        margin: {
                            xs: '24px 24px 24px -16px',   // (top right bottom left)
                            sm: '24px 24px 24px 8px',
                            md: '24px 24px 24px 24px',
                        },
                    }}>
                    <Typography sx={{ fontSize: { xs: 18, sm: 20, md: 20, lg: 20 } }} fontWeight={600} color='primary.main' gutterBottom>
                        United Trust Bank
                    </Typography>
                    <Typography sx={{ fontSize: { xs: 15, sm: 15, lg: 16 }, marginBottom: 0 }} color="text.secondary" paragraph   >
                        12330 S. Harlem Ave., Palos Heights, IL 60463
                        <br />
                        Office: (708) 728-9900
                        <br />
                        Fax: (708) 728-9270
                        <br />
                        24 Hour Telebank: (866)778-3990
                    </Typography>
                    <br />
                    <Typography sx={{ fontSize: { xs: 16, sm: 18, md: 18, lg: 18 } }} fontWeight={600} color='primary.main' gutterBottom>
                        Lobby Hours
                    </Typography>
                    <Typography sx={{ fontSize: { xs: 15, sm: 15, lg: 16 }, marginBottom: 0 }} color="text.secondary" paragraph   >
                        Monday - Thursday 9:00 am - 5:00 pm
                        <br />
                        Friday 9:00 am - 5:00 pm
                        <br />
                        Saturday CLOSED
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={5} lg={5}
                    sx={{
                        margin: {
                            xs: '24px 24px 24px -16px',   // (top right bottom left)
                            sm: '24px 24px 24px 8px',
                            md: '24px 24px 24px 24px',
                        },
                    }}>
                    <Card sx={{ display: 'flex', width: '100%', height: '100%' }}>
                        <CardMedia
                            component="img"
                            sx={{ width: '100%' }}
                            image={map}
                            alt='United Trust Bank Map'
                        />
                    </Card>
                </Grid>
            </Grid> */}

        </div>
    );
}

export default Contact;
