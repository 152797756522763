import ImgHomePage from "./ImgHomePage2"
import TaglineBorder from "./TaglineBorder"
import AboutHomePage from "./AboutHomePage"
import LoanType from "./LoanType1"
import LoanIndustry from "./LoanIndustry"
import LoanEligibility from "./LoanEligibility"
import LoanUses1 from "./LoanUses1"
import LoanUsesNew from "./LoanUsesNew"
import LoanBenefitsNew from "./LoanBenefitsNew"
import ByTheNumbers from "./ByTheNumbers"
import { Helmet } from 'react-helmet-async'

import ReactGA from "react-ga4"
import LearnMore from "./LearnMore"

function Home() {

  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "Home",
  })


  return (
    <div className="Home">
      <Helmet>
        <title>CapTec USA</title>
        <meta name="description" content="SBA Experts - Business Loans after Business Hours. Welcome to modern business lending." />
        <link rel="canonical" href="https://thenewsouthbank.com/" />
      </Helmet>
      <ImgHomePage />
      <AboutHomePage />
      {/* <Tagline /> */}
      {/* <TaglineBorder /> */}
      <LoanType />
      <ByTheNumbers />
      <LoanBenefitsNew />
      <LoanUsesNew />
      <LoanEligibility />
      <LearnMore />
      {/* <LoanIndustry /> */}
      {/* <LoanBenefits /> */}
    </div>
  );
}

export default Home;
