import PaymentComponent from './PaymentComponent'
import { useTheme } from '@mui/material/styles';

function PaymentSelect({data,setData}) {
    const theme = useTheme();

    return(
        <div>
            <PaymentComponent 
            payment={data.payment}
            interest={data.interest}
            term={data.term}
            colorfont={theme.palette.primary.dark}
            colorstep={theme.palette.primary.dark}
            colorbackground={theme.palette.secondary.contrastText}
            qualifiedrate={data.qualifiedrate}
            />
        </div>
    )
}

export default PaymentSelect