import React, { useState, useEffect, useRef } from 'react';
import { alpha } from '@mui/material/styles';
import { FormGroup, FormControlLabel, Grid, Box, Card, CardContent, Stack, Typography, useMediaQuery, CardMedia } from '@mui/material';
import { theme } from '../theme';
import LoanUsesWorkingCapitalButton from './LoanUsesWorkingCapitalButton'
import LoanUsesWorkingCapitalButtonSmall from './LoanUsesWorkingCapitalButtonSmall'
import LoanUsesWorkingCapitalCard from './LoanUsesWorkingCapitalCard'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import PercentIcon from '@mui/icons-material/Percent';
import { keyframes } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';

import toggleCard from '../images/toggleCard5.png'


const popOutIn = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05); /* Slightly scale up */
  }
  100% {
    transform: scale(1);
  }
`;


export default function ToggleCard() {
    const [checked, setChecked] = useState(false);

    // Handler for switch state change
    const handleChange = (event) => {
        setChecked(event.target.checked);
    }

    const isXSmallScreen = useMediaQuery('(max-width:600px)');
    const isSmallScreen = useMediaQuery('(max-width:900px)');
    const isMediumScreen = useMediaQuery('(max-width:1200px)');

    const [animate, setAnimate] = useState(false);

    const prevCheckedRef = useRef(checked);

  useEffect(() => {
    // Check if the checked prop has changed
    if (prevCheckedRef.current !== checked) {
      // Trigger animation on checked change
      setAnimate(true);
      const timer = setTimeout(() => {
        setAnimate(false);
      }, 500); // Duration of the animation

      // Update ref to current checked value
      prevCheckedRef.current = checked;

      // Cleanup the timeout if the component unmounts or checked changes again
      return () => clearTimeout(timer);
    }
  }, [checked]);

   

    return (




        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'auto', // Full viewport height
                bgcolor: 'background.paper', // Optional background color
            }}
        >
            <Card
                sx={{
                    width: isXSmallScreen ? '100vw' : '80vw',
                    //padding: { xs: 1, sm: 2 },
                    backgroundImage: `url(${toggleCard})`, // Set the background image
                    backgroundSize: 'cover', // Ensure the image covers the card
                    backgroundPosition: 'center', // Center the image
                    backgroundColor: checked ? alpha(theme.palette.primary.contrastText, 0.4) : alpha(theme.palette.primary.bright, 0.4),
                    backgroundBlendMode: 'overlay', // Blend the background color with the image
                    transition: 'background-color 0.5s ease', // Smooth transition for color change
                    marginLeft: isXSmallScreen ? '-32px' : 0
                }}>
                <CardContent sx={{ margin: { xs: 1, sm: 2 } }}>
                    <Stack direction="column"
                        spacing={2}
                        sx={{
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                        <Typography
                            sx={{
                                fontSize: { xs: 30, sm: 38, md: 40, lg: 54 },
                                lineHeight: 1.1
                            }}
                            fontWeight={500}
                            color={theme.palette.primary.dark}
                            align='center'
                        >
                            Two Convenient Options
                        </Typography>
                        <FormGroup style={{ margin: 0 }}>
                            <FormControlLabel style={{ margin: 0 }}
                                control={isXSmallScreen
                                    ? <LoanUsesWorkingCapitalButtonSmall defaultChecked={false} checked={checked} onChange={handleChange} sx={{ marginTop: 4 }} />
                                    : <LoanUsesWorkingCapitalButton defaultChecked={true} checked={checked} onChange={handleChange} sx={{ marginTop: 4 }} />}
                            />
                        </FormGroup>
                        <Grid container spacing={{ xs: 0, md: 3 }}>
                            {/* First Sub-Container: Right part */}
                            <Grid item xs={12} sm={12} md={12} lg={8}>
                                <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 4, sm: 8, md: 8 }}>
                                    <Grid item xs={12} sm={12} md={12} key={1}>

                                        <LoanUsesWorkingCapitalCard width={{ xs: 300, sm: 425, md: 400, lg: 400 }}
                                            height={{ xs: 120, sm: 120, md: 130, lg: 130 }}
                                            text={isXSmallScreen ? 
                                                    !checked ? <span>Quick Access<br/>to $150,000</span> : <span>Flexible Capital<br/>Up to $5 Million</span>
                                                    :
                                                    !checked ? <span>Quick Access to $150,000</span> : <span>Flexible Capital Up to $5 Million</span>}
                                            descr={isXSmallScreen 
                                                    ? 
                                                        !checked
                                                        ? "Rapid funding in under 10 days."
                                                        : "Secure funding for operations or unexpected expenses."
                                                    : 
                                                        !checked
                                                        ? "Get the capital you need quickly with our streamlined SBA Express Lane 7(a) loan, perfect for covering immediate business expenses."
                                                        : "Get the funding you need to manage day-to-day operations or cover unexpected business expenses."
                                            }
                                            //buttonText='GET ANSWERS'
                                            icon={!checked ? <AccessTimeIcon /> : <AttachMoneyIcon />}
                                            checked={checked}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} key={2}>
                                    <LoanUsesWorkingCapitalCard width={{ xs: 300, sm: 425, md: 400, lg: 400 }}
                                            height={{ xs: 120, sm: 120, md: 130, lg: 130 }}
                                            text={isXSmallScreen ? 
                                                    !checked ? <span>Easy Application,<br/>Less Paperwork</span> : <span>Extended<br/>Loan Term</span>
                                                    : 
                                                    !checked ? <span>Easy Application, Less Paperwork</span> : <span>Extended Loan Term</span>}
                                            descr={isXSmallScreen 
                                                ? 
                                                    !checked
                                                    ? "Streamlined application for faster approval."
                                                    : "Repayment term of 10 years."
                                                : 
                                                    !checked
                                                    ? "Our Express Lane loans minimize documentation, cutting out the hassle and speeding up the approval process."
                                                    : "Repayment terms up to 10 years help you manage cash flow while focusing on growth and stability."
                                            }
                                            //buttonText='GET ANSWERS'
                                            icon={!checked ? <ThumbUpIcon /> : <CalendarMonthIcon />}
                                            checked={checked}
                                        />

                                    </Grid>


                                    <Grid item xs={12} sm={12} md={12} key={3}>
                                    <LoanUsesWorkingCapitalCard width={{ xs: 300, sm: 425, md: 400, lg: 400 }}
                                            height={{ xs: 120, sm: 120, md: 130, lg: 130 }}
                                            text={isXSmallScreen ? 
                                                        !checked ? <span>Low Interest,<br/>Affordable Payments</span> : <span>Low Interest,<br/>Affordable Payments</span>
                                                        :
                                                        !checked ? <span>Low Interest, Affordable Payments</span> : <span>Low Interest, Affordable Payments</span>
                                                    }
                                            descr={isXSmallScreen 
                                                ? 
                                                    !checked
                                                    ? <span>Estimate your payment in under 5 minutes. <a href="../prequalify/express">Prequalify</a>.</span>
                                                    : <span>Estimate your payment in under 5 minutes. <a href="../prequalify/standard">Prequalify</a>.</span>
                                                : 
                                                    !checked
                                                ? <span>Get your personal interest rate and monthly payment estimate in under 5 minutes with our hassle-free <a href="../prequalify/express">prequalification</a>.</span>
                                                : <span>Get your personal interest rate and monthly payment estimate in under 5 minutes with our hassle-free <a href="../prequalify/standard">prequalification</a>.</span>
                                            }
                                            //buttonText='GET ANSWERS'
                                            icon={<PercentIcon />}
                                            checked={checked}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} key={4}>
                                    <LoanUsesWorkingCapitalCard width={{ xs: 300, sm: 425, md: 400, lg: 400 }}
                                            height={{ xs: 120, sm: 120, md: 130, lg: 130 }}
                                            text={isXSmallScreen ? 
                                                    !checked ? <span>Great for<br/>Short-Term Needs</span> : <span>Ideal for Established Businesses</span>
                                                    :
                                                    !checked ? <span>Great for Short-Term Needs</span> : <span>Ideal for Established Businesses</span>}
                                            descr={isXSmallScreen 
                                                ? 
                                                    !checked
                                                    ? "Ideal for short-term items like payroll, inventory."
                                                    : "Flexible eligibility to meet working capital needs."
                                                : 
                                                    !checked
                                                ? "Perfect for short-term working capital like payroll, inventory, or seasonal expenses, giving your business the boost it needs to thrive."
                                                : "Designed with flexible eligibility to meet your working capital needs."
                                            }
                                            //buttonText='GET ANSWERS'
                                            icon={!checked ? <HourglassBottomIcon /> : <AddBusinessIcon />}
                                            checked={checked}
                                        />
                                    </Grid>

                                </Grid>
                            </Grid>
                            {/* Second Sub-Container: bottom part */}
                            <Grid item xs={12} sm={12} md={12} lg={4}
                                sx={{
                                    marginTop: 2,
                                    marginLeft: {xs: 1, sm: 0, md: 3, lg: 0},
                                    animation: animate ? `${popOutIn} 0.5s` : 'none',
                                    //backgroundColor: alpha(theme.palette.common.white, 0.5)
                                }}>
                                <Typography variant="h4" fontWeight={550}
                                    sx={{
                                        fontSize: {
                                            xs: '16px', // Adjust font size for extra-small screens (phones)
                                            sm: '16px', // Adjust font size for small screens (tablets)
                                            md: '16px', // Adjust font size for medium screens (small desktops)
                                            lg: '18px', // Adjust font size for large screens (desktops)
                                            xl: '18px', // Adjust font size for extra-large screens (large desktops and above)
                                        },
                                        color: theme.palette.primary.dark, textAlign: 'left', width: '100%', lineHeight: 1.5,
                                        marginBottom: 2
                                    }}>
                                    <span><u>General Requirements</u></span>
                                </Typography>
                                <Grid container spacing={{ xs: 0 }} >
                                    <Grid item xs={12} sm={6} md={12} lg={12}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', }}>
                                            <Stack direction="row" spacing={1} marginBottom={1}
                                                sx={{ justifyContent: "flex-start", alignItems: "center", }} >
                                                <CheckIcon sx={{ fontSize: '18px' }} />
                                                <Typography sx={{ fontSize: { xs: 16, sm: 18 }, marginBottom: 2, }}
                                                    fontWeight={500} color={theme.palette.primary.dark} align='left' >
                                                    Business is Profitable
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1} marginBottom={1}
                                                sx={{ justifyContent: "flex-start", alignItems: "center", }} >
                                                <CheckIcon sx={{ fontSize: '18px' }} />
                                                <Typography sx={{ fontSize: { xs: 16, sm: 18 }, marginBottom: 2, }}
                                                    fontWeight={500} color={theme.palette.primary.dark} align='left' >
                                                    Strong Credit History
                                                </Typography>
                                            </Stack>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={12} lg={12}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 2 }}>
                                            <Stack direction="row" spacing={1} marginBottom={1}
                                                sx={{ justifyContent: "flex-start", 
                                                            alignItems: { xs: "center", sm: "flex-start", md: "center", lg: "center"}, }} >
                                                <CheckIcon sx={{ fontSize: '18px' }} />
                                                <Typography sx={{ fontSize: { xs: 16, sm: 18 }, marginBottom: 2, }}
                                                    fontWeight={500} color={theme.palette.primary.dark} align='left' >
                                                    No missed payments in the last 6 months
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1} marginBottom={1}
                                                sx={{ justifyContent: "flex-start", 
                                                    alignItems: { xs: "center", sm: "flex-start", md: "center", lg: "center"}, }} >
                                                <CheckIcon sx={{ fontSize: '18px' }} />
                                                <Typography sx={{ fontSize: { xs: 16, sm: 18 }, marginBottom: 2, }}
                                                    fontWeight={500} color={theme.palette.primary.dark} align='left' >
                                                    {!checked ? '2 years in business' : 'Additional Standard SBA underwriting requirements'}
                                                </Typography>
                                            </Stack>
                                        </Box>
                                    </Grid>

                                </Grid>

                            </Grid>
                        </Grid>
                    </Stack>
                </CardContent>
            </Card>
        </Box>

    )
}