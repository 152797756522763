import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Typography, Stack, Grid } from '@mui/material';
import SliderSelect from './SliderSelect'
import PaymentSelect from './PaymentSelect'
import ContactForm from './ContactForm'
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async'

function Prequalify({ data, setData, industry, setIndustry, industryError, setIndustryError
                    , loanUse, setLoanUse, loanUseError, setLoanUseError
                    , realEstate, setRealEstate, realEstateError, setRealEstateError
                    , pricingTable, contactInfo, setContactInfo
                    , express, setExpress }) {


    const theme = useTheme();

    function formatAmount(value) {
        if (!value) return value;
        //console.log(value)
        const amountValue = value.toString().replace(/[^\d]/g, '');
        let amountValueTrim = amountValue.length >= 8 ? amountValue.substring(0, 7) : amountValue;
        const amountValueTrimLength = amountValueTrim.length;
        if (amountValueTrimLength < 4) return `$${amountValueTrim}`;
        if (amountValueTrimLength < 7) return `$${amountValueTrim.substring(0, amountValueTrim.length - 3)},${amountValueTrim.substring(amountValueTrim.length - 3, amountValueTrim.length)}`;
        if (amountValueTrimLength < 8) return `$${amountValueTrim.substring(0, amountValueTrim.length - 6)},${amountValueTrim.substring(amountValueTrim.length - 6, amountValueTrim.length - 3)},${amountValueTrim.substring(amountValueTrim.length - 3, amountValueTrim.length)}`;
    }

    const roundToNearest = (number, roundingBase) => {
        return Math.round(number / roundingBase) * roundingBase;
    };

    const findItemByKey = (key) => {
        return pricingTable.find(item => item.ReactKey === key);
    };

    useEffect(() => {
        setExpress(true)
        setContactInfo({
            ...contactInfo,
            firstNameError: false,
            lastNameError: false,
            emailError: false,
            phoneError: false,
            businessError: false
        });
        setLoanUseError(false);
        setIndustryError(false);
        setRealEstate('3')

        const combinedKey = industry + '_' + loanUse + '_' + '3';
        //console.log('combinedKey : ', combinedKey)
        //console.log('amountformatted: ', data.amountformatted)
        setData((s) => ({
            ...s,
            reactKey: combinedKey
        }))
        const foundItem = findItemByKey(combinedKey);
        if (!foundItem) {
            console.warn('Item not found');
            return; // Exit early if no item is found
        }

        // Calculate values
        const intcalc = (foundItem.Prime + foundItem.Margin) / 12;
        const termcalc = foundItem.Term * 12;
        const calc1 = (1 + intcalc) ** termcalc;
        const amt = data.amtInteract ?
            data.amount < foundItem.MinVal ?
                foundItem.MinVal :
                data.amount > foundItem.MaxVal ?
                    foundItem.MaxVal :
                    data.amount
            : roundToNearest((foundItem.MinVal + foundItem.MaxVal) / 2, 100000);
        const pmtcalc = amt * (intcalc * calc1) / (calc1 - 1);

        setData((s) => ({
            ...s,
            amount: amt,
            amountformatted: formatAmount(amt),
            interest: foundItem.Prime + foundItem.Margin,
            prime: foundItem.Prime,
            margin: foundItem.Margin,
            term: foundItem.Term,
            payment: pmtcalc,
            minamount: foundItem.MinVal,
            maxamount: foundItem.MaxVal,
            minCredit: foundItem.MinCredit,
        }))
    }, []);

    return (
        <div style={{ marginTop: '150px', marginLeft: '50px' }} className="Prequalify">
            <Helmet>
                <title>CapTec USA | Prequalify</title>
                <meta name="description" content="5 minute SBA prequalification 24/7. Fully online process on your time. Our initial prequalification is the first step in securing your small business funding." />
                <link rel="canonical" href="https://thenewsouthbank.com/prequalify" />
            </Helmet>
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{ mt: {xs: -4, sm: -3, md: -3, lg: -1 },
                        mb: {xs: -5, sm: -4, md: -4, lg: -2 },
                        mr: '50px' }}
            >
                    <Typography sx={{ fontSize: { xs: 30, sm: 40, md: 50, lg: 60 } }} 
                        fontWeight={600} color={theme.palette.primary.dark}
                        textAlign='center'    
                        lineHeight={1.2}
                    //style={{ paddingBottom: '1rem' }} {/* Adjust padding to control distance from bottom 
                    >
                        Express 7(a)
                        <br/>
                        Prequalification
                    </Typography>

                
                {/* <Typography sx={{
                    fontSize: { xs: 18, sm: 18, md: 20, lg: 22 },
                    paddingRight: { xs: '50px', sm: '80px', md: '100px', lg: '300px' }
                }}
                    fontWeight={550}
                    color={'secondary.light'}    //blue

                //style={{ paddingBottom: '1rem' }} {/* Adjust padding to control distance from bottom 
                >
                    Interested in business financing? This is the place to start! This initial prequalification step should only take about 5 minutes. We'll ask for your social security number but our soft credit pull will not impact your credit.
                </Typography>
                <br /> */}

            </Stack>
            <br />
            <br />
            <Grid container
                width={'100vw'}
                marginLeft='-50px'
                direction="row"
                sx={{ backgroundColor: 'secondary.contrastText' }}
                justifyContent="center"

            >
                <Grid container
                    width={'80vw'}
                    direction="row"
                    sx={{ backgroundColor: 'secondary.contrastText' }}
                    justifyContent="center"
                >
                    <Grid item xs={10} sm={8} md={6} >
                        <SliderSelect data={data} setData={setData}
                            industry={industry} setIndustry={setIndustry}
                            industryError={industryError} setIndustryError={setIndustryError}
                            loanUse={loanUse} setLoanUse={setLoanUse}
                            loanUseError={loanUseError} setLoanUseError={setLoanUseError}
                            realEstate={realEstate} setRealEstate={setRealEstate}
                            realEstateError={realEstateError} setRealEstateError={setRealEstateError}
                            pricingTable={pricingTable}
                            express={express} setExpress={setExpress} 
                            showRealEstate={false}
                            />
                    </Grid>
                </Grid>

                <Grid container
                    width={'80vw'}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ backgroundColor: 'secondary.contrastText' }}>
                    <Grid item width='40ch' >
                        <br />
                        <PaymentSelect data={data} setData={setData} />
                        <br />
                        <br />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container
                width={'100vw'}
                marginLeft='-50px'
                direction="row"
                sx={{ backgroundColor: 'white' }}
                justifyContent="center"

            >
                <Grid container
                    width={'80vw'}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ backgroundColor: 'white' }}
                >
                    <Grid item xs={10} sm={8} md={6} >
                        <ContactForm contactInfo={contactInfo} setContactInfo={setContactInfo} data={data} setData={setData}
                            industry={industry} loanUse={loanUse} realEstate={realEstate} />
                    </Grid>
                </Grid>
            </Grid>
            <br />
            <br />
            <br />
        </div >
    );
}

export default Prequalify;
