import * as React from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

export default function CustomHoverBox({ icon, text, borderBottom }) {
  const theme = useTheme();
  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };


  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        margin: 2,
        borderRadius: 2,
        backgroundColor: '#ffff', // Background color changes on hover
        /* backgroungImage: 'linear-gradient(to left, #ffff, #E4EEF1)',
        backgroundSize: '100% 100%',
        transition: 'background-position 0.3s',
        '&:hover': {
          backgroundImage: 'linear-gradient(to right, #ffff, #E4EEF1)',
          backgroundPosition: 'right center',
        }, */
      }}
      /* onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave} */
    >
      {icon && <Box sx={{ marginRight: '10px' }}>{icon}</Box>}
      <Box
        sx={{
          width: 300,
          borderBottom: borderBottom,
          borderColor: alpha(theme.palette.secondary.light, 0.8), // Grey border
        }}
      >
        <Typography
          fontSize={16}
          fontWeight={550}
          align='left'
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
}
