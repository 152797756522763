import * as React from 'react';
import NextButtonFinal from './NextButtonFinal';
import { Stack, Typography, Grid } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

function formatDateTime(date) {

    // Get individual date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

    // Concatenate components into desired format
    const formattedDateTime = `${year}${month}${day}-${hours}.${minutes}.${seconds}.${milliseconds}`;

    return formattedDateTime;
}

function Step5({ handleBack, data, contactInfo, setContactInfo, qstepdata, setQStepData
                , year1bustax, year2bustax, driverslicense, personaltax
                , qualifyProfit, qualifyProfitable, qualifyTaxes, qualifyDelq
                , qualifyDefault, qualifyBankrupt, qualifyIncarc, qualifyCitizen, qualifyIndustry }) {
    //const [completed, setCompleted] = React.useState({});
    //const newCompleted = completed;
    //const activeStep = 1;
    //newCompleted[activeStep] = true;
    //setCompleted(newCompleted);
    //console.log('qstepdata : ', qstepdata)
    //console.log('qstepdata.owner1HomeAddress2 : ', qstepdata.owner1HomeAddress2)

    let creditresult = 4
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);

    // API Gateway url to invoke function to generate presigned url
    const API_ENDPOINT = "https://b5fjiiu2wl.execute-api.us-east-1.amazonaws.com/v1";

    //const [selectedYr1BusTax, setSelectedYr1BusTax] = useState(null);
    //const [selectedYr2BusTax, setSelectedYr2BusTax] = useState(null);

    // Function to generate the presigned url
    const getPresignedUrl = async (filetype, filename, bucket) => {
        // GET request: presigned URL
        //console.log('starting getPresignedUrl')
        try {
            const response = await axios({
                method: 'POST',
                url: API_ENDPOINT,
                data: {
                    filetype1: filetype,
                    filename1: filename.replace(/\s/g, ""),
                    bucket1: bucket,
                }
            });
            //console.log('Response:', response.data);
            //console.log('Response:', response.data.body);
            //console.log('Response:', JSON.parse(response.data.body));
            //console.log('Response:', JSON.parse(response.data.body).presignedUrl);
            const presignedUrl = JSON.parse(response.data.body).presignedUrl
            //console.log('presignedUrl: ', presignedUrl);
            return presignedUrl;
        } catch (error) {
            console.error('Error:', error);
        }

    };



    // Function to upload the selected file using the generated presigned url
    const uploadToPresignedUrl = async (presignedUrl, file, filetype) => {
        // Upload file to pre-signed URL
        const uploadResponse = await axios.put(presignedUrl, file, {
            headers: {
                "Content-Type": filetype,
                "Access-Control-Allow-Origin": "*",
            },
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                //setUploadProgress(percentCompleted);
                console.log(`Upload Progress: ${percentCompleted}%`);
            },
        });
        console.log(uploadResponse);
    };

    // Function to orchestrate the upload process
    const handleUpload = async (file, filetype, filename, bucket) => {
        try {
            // Ensure a file is selected
            if (!file) {
                console.error("No file selected.");
                return;
            }

            const presignedUrl = await getPresignedUrl(filetype, filename, bucket);
            uploadToPresignedUrl(presignedUrl, file, filetype);
        } catch (error) {
            // Handle error
            console.error("Error uploading file:", error);
        }
    };

    //useEffect(() => {
    //   if (year1bustax && year1bustax.file !== selectedYr1BusTax) {
    //        setSelectedYr1BusTax(year1bustax.file);
    //        console.log('year1bustax.file:', year1bustax.file, 'year1bustax.type:', year1bustax.type);
    //        let filetype = year1bustax.type;
    //        let filename = year1bustax.name;
    //        handleUpload(selectedYr1BusTax, filetype, filename);
    //    } else {
    //        console.log('no change in year1bustax.file');
    //    }
    //}, [year1bustax]);


    //useEffect(() => {
    //    if (year2bustax && year2bustax.file !== selectedYr2BusTax) {
    //        setSelectedYr1BusTax(year2bustax.file);
    //        console.log('year2bustax.file:', year2bustax.file, 'year2bustax.type:', year2bustax.type);
    //        let filetype = year2bustax.type;
    //        let filename = year2bustax.name;
    //        handleUpload(selectedYr2BusTax, filetype, filename);
    //    } else {
    //        console.log('no change in year2bustax.file');
    //    }
    //}, [year2bustax]);

    let CapTecFileName;
    const fileDate = formatDateTime(data.datetimesession)
    //console.log('fileDate : ', fileDate)

    const handleSubmit = () => {
        setLoading(true)

        let DriversLicenseName = qstepdata.qDriversLicenseName
        let BusTax1Name = qstepdata.qBusTax1Name
        let BusTax2Name = qstepdata.qBusTax2Name
        let PersonalTaxName = qstepdata.qPersonalTaxName

        let DriversLicenseFile = 0
        let BusTax1File = 0
        let BusTax2File = 0
        let PersonalTaxFile = 0

        const ApplicantID_Prior = qstepdata.applicantID

        if (driverslicense.file != null) {
            DriversLicenseName = driverslicense.name.replace(/_/g, "");
            DriversLicenseFile = 1
        } else {
            console.log('no year1bustax');
        }

        if (year1bustax.file != null) {
            console.log(year1bustax.file, year1bustax.type, year1bustax.name);
            const CapTecFileName = contactInfo.firstname.replace(/_/g, "") + '_' + contactInfo.lastname.replace(/_/g, "") + '_' + fileDate + '_BusTax1_' + year1bustax.name.replace(/_/g, "");
            BusTax1Name = year1bustax.name.replace(/_/g, "")
            BusTax1File = 1
            handleUpload(year1bustax.file, year1bustax.type, CapTecFileName, 'presigned-url-bucket-bustaxyr1');
        } else {
            console.log('no year1bustax');
        }
        
        if (year2bustax.file != null) {
            console.log(year2bustax.file, year2bustax.type, year2bustax.name)
            const CapTecFileName2 = contactInfo.firstname.replace(/_/g, "") + '_' + contactInfo.lastname.replace(/_/g, "") + '_' + fileDate + '_BusTax2_' + year2bustax.name.replace(/_/g, "")
            BusTax2Name = year2bustax.name.replace(/_/g, "")
            BusTax2File = 1
            handleUpload(year2bustax.file, year2bustax.type, CapTecFileName2, 'presigned-url-bucket-bustaxyr2')
        } else {
            console.log('no year2bustax')
        }

        if (personaltax.file != null) {
            PersonalTaxName = personaltax.name.replace(/_/g, "");
            PersonalTaxFile = 1
        } else {
            console.log('no personaltax');
        }

        console.log('real_estate: ',data.real_estate)
        fetch('https://lfowuo4m54.execute-api.us-east-1.amazonaws.com/v1', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                first_name: qstepdata.owner1FirstName.replace(/ +(?= )/g, '').trim(),
                last_name: qstepdata.owner1LastName.replace(/ +(?= )/g, '').trim(),
                address: qstepdata.owner1HomeAddress1.replace(/ +(?= )/g, '').trim(),
                city: qstepdata.owner1City.replace(/ +(?= )/g, '').trim(),
                state: qstepdata.owner1State,
                zip: qstepdata.owner1Zip,
                ssn: qstepdata.owner1SSN.replaceAll("-", ""),
                date_of_birth: contactInfo.dob,
                dateTime: data.datetimesession,
                email: qstepdata.owner1Email,
                business_name: contactInfo.businessname.replace(/ +(?= )/g, '').trim(),
                amount: data.amount,
                interest: data.interest,
                margin: data.margin,
                term: data.term,
                phone: qstepdata.owner1Phone,
                real_estate: data.real_estate,
                payment: data.payment,
                reactKey: data.reactKey,
                appdemo: 1,
                BusTax1: year1bustax.file ? 1 : 0,
                BusTax2: year2bustax.file ? 1 : 0,
                ApplicantID: contactInfo.firstname + '_' + contactInfo.lastname + '_' + fileDate,
                customurl: data.customurl,
                ApplicantID_Prior: ApplicantID_Prior,

                q_amountformatted: parseInt(qstepdata.amountformatted.replace(/\D/g, '')),
                q_legalBusinessName: qstepdata.legalBusinessName.replace(/ +(?= )/g, '').trim(),
                q_entityType: qstepdata.entityType,
                q_employees: qstepdata.employees,
                q_TIN: qstepdata.TIN,
                q_dateBusinessEst: qstepdata.dateBusinessEst,
                q_busStreet1: qstepdata.busStreet1,
                q_busStreet2: qstepdata.busStreet2,
                q_busCity: qstepdata.busCity,
                q_busState: qstepdata.busState,
                q_busZip: qstepdata.busZip,
                q_ownernum: qstepdata.ownernum,
                q_website: qstepdata.website,

                q_owner1FirstName: qstepdata.owner1FirstName.replace(/ +(?= )/g, '').trim(),
                q_owner1LastName: qstepdata.owner1LastName.replace(/ +(?= )/g, '').trim(),
                q_owner1Perc: qstepdata.owner1Perc,
                q_owner1HomeAddress1: qstepdata.owner1HomeAddress1.replace(/ +(?= )/g, '').trim(),
                q_owner1HomeAddress2: qstepdata.owner1HomeAddress2.replace(/ +(?= )/g, '').trim(),
                q_owner1City: qstepdata.owner1City.replace(/ +(?= )/g, '').trim(),
                q_owner1State: qstepdata.owner1State,
                q_owner1Zip: qstepdata.owner1Zip,
                q_owner1Email: qstepdata.owner1Email,
                q_owner1Phone: qstepdata.owner1Phone,
                q_owner1SSN: qstepdata.owner1SSN.includes('*') ? qstepdata.owner1SSNoriginal.replaceAll("-", "") : qstepdata.owner1SSN.replaceAll("-", ""),

                q_owner2FirstName: qstepdata.owner2FirstName.replace(/ +(?= )/g, '').trim(),
                q_owner2LastName: qstepdata.owner2LastName.replace(/ +(?= )/g, '').trim(),
                q_owner2Perc: qstepdata.owner2Perc,
                q_owner2Email: qstepdata.owner2Email,
                q_owner2Phone: qstepdata.owner2Phone,
                //q_owner2SSN: qstepdata.owner2SSN.replaceAll("-", ""),

                q_owner3FirstName: qstepdata.owner3FirstName.replace(/ +(?= )/g, '').trim(),
                q_owner3LastName: qstepdata.owner3LastName.replace(/ +(?= )/g, '').trim(),
                q_owner3Perc: qstepdata.owner3Perc,
                q_owner3Email: qstepdata.owner3Email,
                q_owner3Phone: qstepdata.owner3Phone,
                //q_owner3SSN: qstepdata.owner3SSN.replaceAll("-", ""),

                q_owner4FirstName: qstepdata.owner4FirstName.replace(/ +(?= )/g, '').trim(),
                q_owner4LastName: qstepdata.owner4LastName.replace(/ +(?= )/g, '').trim(),
                q_owner4Perc: qstepdata.owner4Perc,
                q_owner4Email: qstepdata.owner4Email,
                q_owner4Phone: qstepdata.owner4Phone,
                //q_owner4SSN: qstepdata.owner4SSN.replaceAll("-", ""),

                q_owner5FirstName: qstepdata.owner5FirstName.replace(/ +(?= )/g, '').trim(),
                q_owner5LastName: qstepdata.owner5LastName.replace(/ +(?= )/g, '').trim(),
                q_owner5Perc: qstepdata.owner5Perc,
                q_owner5Email: qstepdata.owner5Email,
                q_owner5Phone: qstepdata.owner5Phone,
                //q_owner5SSN: qstepdata.owner5SSN.replaceAll("-", ""),

                q_qualifyProfit: qualifyProfit,
                q_qualifyProfitable: qualifyProfitable,
                q_qualifyCitizen: qualifyCitizen,
                q_qualifyTaxes: qualifyTaxes,
                q_qualifyDelq: qualifyDelq,
                q_qualifyDefault: qualifyDefault,
                q_qualifyBankrupt: qualifyBankrupt,
                q_qualifyIncarc: qualifyIncarc,
                q_qualifyIndustry: qualifyIndustry,

                DriversLicenseName: DriversLicenseName,
                BusTax1Name: BusTax1Name,
                BusTax2Name: BusTax2Name,
                PersonalTaxName: PersonalTaxName,

                DriversLicenseFiie: DriversLicenseFile,
                BusTax1File: BusTax1File,
                BusTax2File: BusTax2File,
                PersonalTaxFile: PersonalTaxFile,

                q_DriversLicenseName: driverslicense.name,
                q_BusTax1Name: year1bustax.name,
                q_BusTax2Name: year2bustax.name,
                q_PersonalTaxName: personaltax.name,
                
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                console.log('response: ', response);
                return response.json();
            })
            .then(data => {
                // Update state with the API response data
                console.log('data: ', data)
                //console.log('data.result: ', data.result)
                //console.log('data.name: ', data.name)
                data.creditpass == 1 ? creditresult = 1 :
                    data.frozen == 1 ? creditresult = 2 :
                        data.nomatch == 1 || data.noscore == 1 ? creditresult = 3 :
                            data.lowscore == 1 ? creditresult = 5 : creditresult = 0;
            })
            .catch(error => {
                // Handle errors
                console.error('There was a problem with the request:', error);
            })
            .then(() => {
                //console.log('creditresult: ', creditresult)
                //navigate('/submit', { replace: true })
                navigate('/questionnaire/submit')
            }
            )

    }

    console.log(qstepdata.owner1FirstName, qstepdata.owner1LastName, data.amount)
    return (
        <div>
            <Stack mt={0}>
                <Stack alignItems="center" sx={{ width: { md: '100%', lg: 'calc(100% - 240px)' } }} >
                    <Typography variant="h6" sx={{ fontWeight: 700, color: data.colorstep }}>
                        Step 5:
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 700 }}>
                        Submission
                    </Typography>
                    <br /><br />
                </Stack>
                <br /><br />
                <NextButtonFinal handleBack={handleBack} handleSubmit={handleSubmit} data={data} loading={loading}
                    disabled={qstepdata.owner1FirstName && qstepdata.owner1LastName && data.amount > 0}
                    contactInfo={contactInfo} />
            </Stack>
        </div>
    )
}

export default Step5

/*
<div>
                    <Stack mt={4}>
                        <Stack direction="row" justifyContent={'center'} alignItems="center" sx={{ width: { md: '100%', lg: 'calc(100% - 240px)' } }}>
                            <Stack direction="row" justifyContent={'center'} alignItems="center" sx={{ width: 500 }}>
                                <FormGroup>
                                    <FormControlLabel
                                        value={contactInfo.contactconsent}
                                        control={<Checkbox checked={contactInfo.contactconsent}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setContactInfo({
                                                    ...contactInfo,
                                                    contactconsent: event.target.checked
                                                })
                                            }}
                                            color="default" />}
                                        label=""
                                    />
                                </FormGroup>
                                <Typography>
                                    I consent to future email, phone, and text communications to contact me at the phone number and email provided.
                                </Typography>
                            </Stack>
                        </Stack>
                        <br />
                        <Stack direction="row" justifyContent={'center'} alignItems="center" sx={{ width: { md: '100%', lg: 'calc(100% - 240px)' } }}>
                            <Stack direction="row" justifyContent={'center'} alignItems="center" sx={{ width: 500 }}>
                                <FormGroup>
                                    <FormControlLabel
                                        value={contactInfo.privacyconsent}
                                        control={<Checkbox checked={contactInfo.privacyconsent}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setContactInfo({
                                                    ...contactInfo,
                                                    privacyconsent: event.target.checked
                                                })
                                            }}
                                            color="default" />}
                                    />
                                </FormGroup>
                                <Typography>
                                    I have read and consent to abide by the <a href={data.privacypolicylink} target="_blank">{data.privacypolicytitle}</a>.
                                </Typography>
                            </Stack>
                        </Stack>
                        <br />
                        <Stack direction="row" justifyContent={'center'} alignItems="center" sx={{ width: { md: '100%', lg: 'calc(100% - 240px)' } }}>
                            <Stack direction="row" justifyContent={'center'} alignItems="center" sx={{ width: 500 }}>
                            <FormGroup>
                                    <FormControlLabel
                                        value={contactInfo.ssnconsent}
                                        control={<Checkbox checked={contactInfo.ssnconsent}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setContactInfo({
                                                    ...contactInfo,
                                                    ssnconsent: event.target.checked
                                                })
                                            }}
                                            color="default" />}
                                        label=""
                                    />
                                </FormGroup>
                                <Typography>
                                    I understand that by clicking the "Run Credit Check" button,
                                    I am providing "written instruction" under the FCRA authorizing {data.clientname} to 
                                    obtain personal credit & other information from iSoftpull, Experian, TransUnion, and/or Equifax
                                    solely for credit pre-qualification. This process will not affect my credit score.
                                    //I also give permission for my contact information to be given to a third party lender, if applicable.
                                    </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </div>
*/





