import * as React from 'react';
import Card from '@mui/material/Card';
import { Link, Stack } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export default function LearnMoreMediaCard({ width, image, imagename, text, keyName, descr, linkClick, buttonText, icon }) {
    const theme = useTheme();
    const [isHovered, setIsHovered] = React.useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }} >
            <a href={linkClick} style={{ textDecoration: 'none' }}>
            <Card sx={{
                display: 'flex', width: width, height: 150,
                alignItems: 'center',
                backgroundColor: 'transparent',
                transition: 'background-color 0.3s',
                border: `1px solid ${theme.palette.primary.bright}`,
                '&:hover': {
                    backgroundColor: theme.palette.secondary.contrastText,
                    boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2)',
                    opacity: '0.8',
                    cursor: 'pointer',
                },
            }}>
               {/*  <CardMedia
                    sx={{
                        maxWidth: { xs: '30%', md: '40%' }, // Set the width of the image container
                        height: '100%', // Set the width of the image container
                        objectFit: 'cover', // Ensure the image covers the container
                    }}
                    component="img"
                    image={image}
                    alt={imagename}
                /> */}
                <CardContent sx={{
                    display: 'flex',          // Use flexbox
                    flexDirection: 'column',  // Align children in a column
                    justifyContent: 'center', // Center items vertically
                    alignItems: 'flex-start',     // Center items horizontally
                    height: '100%',
                    width: '100%',
                    padding: 2
                }} >
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flext-start"
                        spacing={2}
                        
                    >
                    {React.cloneElement(icon, { sx: { color: theme => theme.palette.primary.red, marginTop: 1 } })}
                    <Typography variant="h4" fontWeight={550}
                        sx={{
                            fontSize: {
                                xs: '20px', // Adjust font size for extra-small screens (phones)
                                sm: '20px', // Adjust font size for small screens (tablets)
                                md: '17px', // Adjust font size for medium screens (small desktops)
                                lg: '22px', // Adjust font size for large screens (desktops)
                                xl: '24px', // Adjust font size for extra-large screens (large desktops and above)
                            },
                            color: theme.palette.primary.dark, textAlign: 'center', width: '100%', lineHeight: 1.5,
                        }}>
                        {text}
                    </Typography>
                    </Stack>
                    <Typography
                        sx={{
                            fontSize: {
                                xs: '14px', // Adjust font size for extra-small screens (phones)
                                sm: '14px', // Adjust font size for small screens (tablets)
                                md: '14px', // Adjust font size for medium screens (small desktops)
                                lg: '16px', // Adjust font size for large screens (desktops)
                                xl: '16px', // Adjust font size for extra-large screens (large desktops and above)
                            },
                            color: 'secondary.light', textAlign: 'left', width: '100%', 
                        }}>
                        {descr}
                    </Typography>
                    {/* <Link
                        href={linkClick}
                        underline="none" // Ensures the link is always underlined
                        sx={{
                            cursor: 'pointer',
                        }}
                    > */}
                        <Typography fontSize={14} fontWeight={500} color='secondary.light' marginTop={'10px'} 
                           sx={{
                            position: 'relative',
                            display: 'inline-block',
                            textDecoration: 'none', // Remove default underline
                            '&::after': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                bottom: '-1px', // Adjust to move the underline down
                                left: 0,
                                width: '100%',
                                height: '2px', // Thickness of the underline
                                backgroundColor: theme.palette.primary.dark,
                                transition: 'background-color 0.3s ease',
                            },
                            '&:hover::after': {
                                backgroundColor: theme.palette.primary.dark, // Color change on hover
                            }
                        }}>   
                            <span style={{ color: theme.palette.primary.dark }}>{buttonText}</span><ArrowRightIcon sx={{ marginBottom: 0.5, marginLeft: -0.5, verticalAlign: 'middle', color: theme.palette.primary.dark, fontSize: '22px' }}/>
                        </Typography>
                    {/* </Link> */}
                </CardContent>
            </Card>
            </a>
        </div>
    );
}
