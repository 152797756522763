import * as React from 'react';
import { Stack, Typography, Box } from "@mui/material";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  width: '100%',
}));


function Payment(props) {
  const int = props.interest
  const dynamic_color = props.colorfont
  return (
    <div>
      <Stack spacing={1}>
        <Stack sx={{ borderRadius: '16px', boxShadow: 3 }}>
          <Item>
            <Stack direction="row" color="white" backgroundColor='primary.dark' justifyContent={'center'}>
              <Typography variant="h6" color="white" backgroundColor='primary.dark' > Loan Estimates<sup style={{ fontSize: '0.60rem' }}>1</sup></Typography>
            </Stack>
            <Stack direction="row" justifyContent={'space-between'} spacing={2}>
              <Box sx={{ display: 'flex', alignItems: 'center', }}>
                <Typography color="text.secondary" align='left' sx={{ fontWeight: 600, fontSize: { xs: 18, md: 22 } }}> Monthly Payment</Typography>
              </Box>
              <Typography variant="h6" color='primary.dark' sx={{ fontWeight: 600 }}> {props.unit}{props.payment.toLocaleString('en', {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })} </Typography>
            </Stack>
            <Stack direction="row" justifyContent={'space-between'} spacing={2}>
              <Box sx={{ display: 'flex', alignItems: 'center', }}>
                <Typography color="text.secondary" align='left' sx={{ fontWeight: 600, fontSize: { xs: 18, md: 22 } }}> Loan Term</Typography>
              </Box>
              <Typography variant="h6" color='primary.dark' sx={{ fontWeight: 600 }}> {props.term} Years </Typography>
            </Stack>
            <Stack direction="row" justifyContent={'space-between'} spacing={2}>
              <Box sx={{ display: 'flex', alignItems: 'center', }}>
                <Typography color="text.secondary" align='left' sx={{ fontWeight: 600, fontSize: { xs: 18, md: 22 } }}> Interest Rate</Typography>
              </Box>
              {/* {props.qualifiedrate == true ?
                (<Typography variant="h6" color="text.secondary" align='left' sx={{ fontWeight: 600 }}> Interest Rate<sup>1</sup></Typography>)
                : (<Typography variant="h6" color="text.secondary" align='left' sx={{ fontWeight: 600 }}> Interest Rate</Typography>)
              } */}
              <Typography variant="h6" color='primary.dark' sx={{ fontWeight: 600 }}> {Number(int).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })} </Typography>
            </Stack>
          </Item>
        </Stack>
        {/* {props.qualifiedrate == true ?
          (<Typography variant="caption" align='center'><sup>1</sup>Highly qualified borrowers may qualify for lower interest rates.</Typography>)
          : (<div></div>)
        } */}
        <Typography variant="caption" align='center'><sup style={{ fontSize: '0.55srem' }}>1</sup>Estimates may change based on underwriting results and SBA requirements.</Typography>
      </Stack>
    </div>
  );
}

export default Payment