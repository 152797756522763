import { Card, CardContent, Typography, Stack, Button } from "@mui/material"
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import FastForwardIcon from '@mui/icons-material/FastForward';
import WavingHandIcon from '@mui/icons-material/WavingHand';

export default function PrequalifyCard() {

    const theme = useTheme();

    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/prequalify')
    };
    return (
        <Card sx={{
            boxShadow: 'none', marginLeft: { xs: '0px', sm: '0px' }
            //, backgroundColor: alpha(theme.palette.primary.contrastText, 0.5), borderRadius: 6
            , border: 1
            , borderColor: theme.palette.primary.bright
        }}>
            <CardContent>
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Stack
                        direction="column"
                        spacing={0.5}
                        width="100%"
                        sx={{ display: 'flex' }}
                    >
                        <Stack direction="row" spacing={1} sx={{ justifyContent: "center", alignItems: "center", }}>
                            <Typography sx={{
                                fontSize: { xs: 20, sm: 20, md: 22, lg: 24 }, color: 'primary.dark', fontWeight: 600
                            }}>
                                Why Choose Us?
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} sx={{ justifyContent: "center", alignItems: "center", paddingBottom: 2}}>
                            <Typography sx={{
                                fontSize: { xs: 18, sm: 18, md: 20, lg: 20 }, color: 'secondary.light', fontWeight: 500, lineHeight: 1.2
                            }}>
                                We specialize in providing customized SBA 7(a) loans with competitive rates, flexible terms, and a seamless process designed for small business owners.
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack
                        direction="column"
                        spacing={0.5}
                        width="100%"
                        sx={{ display: 'flex' }}
                    >
                        <Stack direction="row" spacing={1} sx={{ justifyContent: "flex-start", alignItems: "center", }}>
                            <AccessTimeIcon sx={{ color: theme => theme.palette.primary.main }} />
                            <Typography sx={{ fontSize: { xs: 16, sm: 16, md: 16, lg: 18 }, color: 'secondary.light', fontWeight: 600 }}>
                                Less than 5 minutes
                            </Typography>
                        </Stack>
                        <Typography sx={{
                            fontSize: { xs: 16, sm: 16, md: 16, lg: 16 }, color: 'secondary.light', fontWeight: 500, lineHeight: 1
                            , paddingLeft: 6
                        }}>
                            Get instant results 24/7 with our online prequalification.
                        </Typography>
                    </Stack>
                    <Stack
                        direction="column"
                        spacing={0.5}
                        width="100%"
                        sx={{ display: 'flex' }}
                    >
                        <Stack direction="row" spacing={1} sx={{ justifyContent: "flex-start", alignItems: "center", }}>
                            <LockOpenIcon sx={{ color: theme => theme.palette.primary.main }} />
                            <Typography sx={{ fontSize: { xs: 16, sm: 16, md: 16, lg: 18 }, color: 'secondary.light', fontWeight: 600, lineHeight: 1 }}>
                                Know your interest rate and payment estimate
                            </Typography>
                        </Stack>
                        <Typography sx={{
                            fontSize: { xs: 16, sm: 16, md: 16, lg: 16 }, color: 'secondary.light', fontWeight: 500, lineHeight: 1
                            , paddingLeft: 6
                        }}>
                            Instantly receive a personalized loan estimate with clear details on rates, terms, fees, and funding timelines. No delays. No hidden costs.
                        </Typography>
                    </Stack>
                    <Stack
                        direction="column"
                        spacing={0.5}
                        width="100%"
                        sx={{ display: 'flex' }}
                    >
                        <Stack direction="row" spacing={1} sx={{ justifyContent: "flex-start", alignItems: "center", }}>
                            <FastForwardIcon sx={{ color: theme => theme.palette.primary.main }} />
                            <Typography sx={{ fontSize: { xs: 16, sm: 16, md: 16, lg: 18 }, color: 'secondary.light', fontWeight: 600, lineHeight: 1 }}>
                                Efficient Process
                            </Typography>
                        </Stack>
                        <Typography sx={{
                            fontSize: { xs: 16, sm: 16, md: 16, lg: 16 }, color: 'secondary.light', fontWeight: 500, lineHeight: 1
                            , paddingLeft: 6
                        }}>
                            Once you're prequalified, we ensure every step is handled smoothly and quickly, making the lending experience seamless.
                        </Typography>
                    </Stack>
                    <Stack
                        direction="column"
                        spacing={0.5}
                        width="100%"
                        sx={{ display: 'flex' }}
                    >
                        <Stack direction="row" spacing={1} sx={{ justifyContent: "flex-start", alignItems: "center", }}>
                            <WavingHandIcon sx={{ color: theme => theme.palette.primary.main }} />
                            <Typography sx={{ fontSize: { xs: 16, sm: 16, md: 16, lg: 18 }, color: 'secondary.light', fontWeight: 600, lineHeight: 1 }}>
                                Expert Guidance
                            </Typography>
                        </Stack>
                        <Typography sx={{
                            fontSize: { xs: 16, sm: 16, md: 16, lg: 16 }, color: 'secondary.light', fontWeight: 500, lineHeight: 1
                            , paddingLeft: 6
                        }}>
                            Receive dedicated support throughout the process, ensuring clarity and ease every step of the way.
                        </Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        width='100%'
                        spacing={2}
                        paddingTop={2}
                    >
                        {/* {React.cloneElement(icon, { sx: { color: theme => theme.palette.secondary.light, marginTop: 1 } })} */}
                        <Button onClick={handleClick}
                            sx={{
                                color: 'white',
                                backgroundColor: theme.palette.secondary.main,
                                height: 35,
                                border: 'none',
                                margin: '0 8px 0 8px',
                                paddingRight: '8px',
                                //fontSize: { xs: 12, sm: 14, md: 16 },
                                fontWeight: 500,
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: alpha(theme.palette.secondary.main, 0.5), // Lighter background color on hover
                                },
                                display: { xs: 'inline-flex', md: 'inline-flex' },
                                alignItems: 'center', // Align items vertically in flexbox
                            }}>
                            <Typography sx={{ fontSize: { xs: 18, sm: 18, md: 18, lg: 24 }, color: 'white', fontWeight: 700 }}>
                                Get Prequalified
                            </Typography>
                        </Button>
                    </Stack>

                </Stack>
            </CardContent>
        </Card>
    )
}

