import { useState } from 'react';
import Box from '@mui/material/Box';
import { Typography, Stack, Grid, Button } from '@mui/material';
import InputTextFull from './InputTextFull'
//import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from 'react-helmet-async'


function Referral() {

    const [capVal, setCapVal] = useState(null)

    return (
        <div style={{ marginTop: '40px', marginLeft: '50px' }} className="Referral">
            <Helmet>
                <title>CapTec USA | Referral Partners</title>
                <meta name="description" content="Partner with us to connect small businesses with financial support. Discover the rewards of joining our team." />
                <link rel="canonical" href="https://thenewsouthbank.com/referral" />
            </Helmet>
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >
                <Box sx={{
                    width: '70vw',
                    height: '5rem',
                    borderBottom: 1,
                    textAlign: 'left',
                    m: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    marginLeft: '-30rem'
                }}>
                    <Typography fontSize={30} fontWeight={500} color={'#08256C'}    //blue
                    //style={{ paddingBottom: '1rem' }} {/* Adjust padding to control distance from bottom */}
                    >
                        Referral Partners
                    </Typography>
                </Box>
                <br />
                <Typography sx={{
                    fontSize: { xs: 18, sm: 18, md: 20, lg: 22 },
                    paddingRight: { xs: '50px', sm: '80px', md: '100px', lg: '300px' }
                }}
                    fontWeight={500}
                    color={'secondary.light'}    //blue

                //style={{ paddingBottom: '1rem' }} {/* Adjust padding to control distance from bottom 
                >
                    Join our network as a referral partner.
                    <br />
                    Empower small businesses with the financial support they need to thrive.
                </Typography>
                <br />
                <br />

            </Stack>
            <Stack mt={0} ml={'-40px'}>
                <Stack alignItems="center" sx={{ width: { xs: '90%', sm: '80%' } }} >
                    <Typography variant="h6" sx={{ fontWeight: 700, color: 'primary.main' }}>
                        Referral Sign Up
                    </Typography>
                    <Stack alignItems="center" sx={{ width: { xs: '90%', sm: '80%' } }} >

                        <br />
                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                                <InputTextFull //label="Business Structure"
                                    headingText="First Name"
                                    headingStyle={{ color: "red" }}
                                    headingSuffix=" *"
                                    //subheading="If you operate under a business name that differs from the legal business name, enter the name here."
                                    type="text"
                                    required={true}
                                //value={contactinfo.firstname}
                                //defaultValue={contactinfo.firstname}
                                //error={contactinfo.firstNameError}
                                //color={data.colorfont}
                                //autoComplete="given-name"
                                //id="given-name"
                                //onChange={e => handleFirstName(e)}
                                //onBlur={() => { setContactInfo({ ...contactinfo, firstNameError: contactinfo.firstname == '' ? true : false }) }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputTextFull //abel="Number of Employees"
                                    headingText="Last Name"
                                    headingStyle={{ color: "red" }}
                                    headingSuffix=" *"
                                    //subheading="If you operate under a business name that differs from the legal business name, enter the name here."
                                    type="text"
                                    required={true}
                                //value={contactinfo.lastname}
                                //defaultValue={contactinfo.lastname}
                                //error={contactinfo.firstNameError}
                                //color={data.colorfont}
                                //autoComplete="given-name"
                                //id="given-name"
                                //onChange={e => handleLastName(e)}
                                //onBlur={() => { setContactInfo({ ...contactinfo, firstNameError: contactinfo.firstname == '' ? true : false }) }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                                <InputTextFull //label="Legal Business Name"
                                    headingText="Email"
                                    headingStyle={{ color: "red" }}
                                    headingSuffix=" *"
                                    //subheading="If you operate under a business name that differs from the legal business name, enter the name here."
                                    type="text"
                                    required={true}
                                //value={contactinfo.email}
                                //defaultValue={contactinfo.email}
                                //error={contactinfo.firstNameError}
                                //color={data.colorfont}
                                //autoComplete="given-name"
                                //id="given-name"
                                //onChange={e => handleEmail(e)}
                                //onBlur={() => { setContactInfo({ ...contactinfo, firstNameError: contactinfo.firstname == '' ? true : false }) }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputTextFull //label="Legal Business Name"
                                    headingText="Phone"
                                    headingStyle={{ color: "red" }}
                                    headingSuffix=" *"
                                    //subheading="If you operate under a business name that differs from the legal business name, enter the name here."
                                    type="text"
                                    required={true}
                                //value={contactinfo.email}
                                //defaultValue={contactinfo.email}
                                //error={contactinfo.firstNameError}
                                //color={data.colorfont}
                                //autoComplete="given-name"
                                //id="given-name"
                                //onChange={e => handleEmail(e)}
                                //onBlur={() => { setContactInfo({ ...contactinfo, firstNameError: contactinfo.firstname == '' ? true : false }) }}
                                />
                            </Grid>
                        </Grid>
                        <InputTextFull //label="Legal Business Name"
                            headingText="Business Name"
                            headingStyle={{ color: "red" }}
                            headingSuffix=" *"
                            //subheading="If you operate under a business name that differs from the legal business name, enter the name here."
                            type="text"
                            required={true}
                        //value={contactinfo.email}
                        //defaultValue={contactinfo.email}
                        //error={contactinfo.firstNameError}
                        //color={data.colorfont}
                        //autoComplete="given-name"
                        //id="given-name"
                        //onChange={e => handleEmail(e)}
                        //onBlur={() => { setContactInfo({ ...contactinfo, firstNameError: contactinfo.firstname == '' ? true : false }) }}
                        />
                    </Stack>
                    <br />
                    {/* <ReCAPTCHA
                        sitekey='6Ld98RoqAAAAAAn3hkXVS6RenDTdMQ4Kjd7bNAFq'
                        onChange={(val) => setCapVal(val)}
                    />
                    <br /> */}
                    <Button variant="contained"
                        sx={{ color: 'white' }}
                        disabled={!capVal}
                    >
                        Submit
                    </Button>
                </Stack>
                <br /><br /><br />
                {/* <NextButtonFirst handleNext={handleNext} data={data} /> */}
            </Stack>
        </div>
    );
}

export default Referral;
