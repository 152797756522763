import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export default function MediaCard({ image, imagename, title, descr, isMediaLeft, imagePosition, maxWidth, onClick }) {

    const [isHovered, setIsHovered] = React.useState(false);
    const theme = useTheme();

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }} >
            <Card sx={{
                display: 'flex'
                //, maxWidth: maxWidth
                , boxShadow: 'none', marginLeft: { xs: '0px', sm: '0px' }
                , border: 1
                , borderColor: theme.palette.primary.dark
                , height: { xs: '380px', sm: '220px', md: '200px', lg: '180px'},
                alignItems: 'flex-start',
                flexDirection: { xs: 'column', sm: isMediaLeft ? 'row' : 'row-reverse' }, // Flex direction based on mediaPosition
                //transition: 'background-color 0.3s',
                //cursor: 'pointer',
                //'&:hover': {
                //    backgroundColor: '#DFEBEF',
                //    boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2)',
                //    opacity: '0.8',
                //},
            }}
            //onClick={onClick}
            >
                <CardMedia 
                    sx={{
                        maxWidth: { xs: '100%', sm: '40%' }, // Set the width of the image container
                        height: { xs: '40%', sm: '100%' }, // Set the width of the image container
                        objectFit: 'cover', // Ensure the image covers the container
                        objectPosition: imagePosition
                    }}
                    component="img"
                    image={image}
                    alt={imagename}
                />
                <CardContent sx={{ alignItems: 'flex-start', height: '100%', width: '100%'
                            , paddingBottom: '0  !important', padding: 0,
                            margin: 1 }} >
                    <Typography variant="h4" fontWeight={550}
                        sx={{
                            fontSize: {
                                xs: '20px', // Adjust font size for extra-small screens (phones)
                                sm: '20px', // Adjust font size for small screens (tablets)
                                md: '20px', // Adjust font size for medium screens (small desktops)
                                lg: '24px', // Adjust font size for large screens (desktops)
                                xl: '24px', // Adjust font size for extra-large screens (large desktops and above)
                            },
                            color: 'primary.dark', textAlign: 'left', width: '100%',
                        }}>
                        {title}
                    </Typography>
                    <Typography variant="h4" fontWeight={500}
                        sx={{
                            fontSize: {
                                xs: '18px', // Adjust font size for extra-small screens (phones)
                                sm: '18px', // Adjust font size for small screens (tablets)
                                md: '16px', // Adjust font size for medium screens (small desktops)
                                lg: '18px', // Adjust font size for large screens (desktops)
                                xl: '18px', // Adjust font size for extra-large screens (large desktops and above)
                            },
                            color: 'secondary.light', textAlign: 'left', width: '100%',
                            marginTop: 1
                        }}>
                        {descr}
                    </Typography>
                </CardContent>
            </Card> 
        </div>
    );
}
