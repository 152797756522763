import { Stack, Typography } from "@mui/material";
import InputText from './InputText'
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import InputTextPhone from './InputTextPhone';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from '@mui/material/styles';


function ContactForm({ contactInfo, setContactInfo, data, setData, realEstate, qstepdata, setQStepData, industry, loanUse }) {
    const navigate = useNavigate()
    let newPK = 0

    const theme = useTheme();

    // Function to handle the button click and make the API call
    const handleButtonClick = () => {

        setContactInfo({ ...contactInfo, datetimeadded: data.datetimesession })

        setData({ ...data, startCredit: true})

        fetch('https://6459gkyecf.execute-api.us-east-1.amazonaws.com/v1', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email: contactInfo.email,
                firstName: contactInfo.firstname.replace(/ +(?= )/g,'').trim(),
                lastName: contactInfo.lastname.replace(/ +(?= )/g,'').trim(),
                amount: data.amount,
                phone: contactInfo.phone,
                businessName: contactInfo.businessname.replace(/ +(?= )/g,'').trim(),
                industry: industry,
                loanUse: loanUse,
                reactKey: data.reactKey,
                realEstate: realEstate == 2 ? true : false,
                payment: data.payment,
                term: data.term,
                interest: data.interest,
                consentContact: contactInfo.contactconsent,
                consentPrivacy: contactInfo.privacyconsent,
                dateTime: data.datetimesession,
                prime: data.prime,
                margin: data.interest - data.prime
            })
        })
            .then(response => {
                console.log(response)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            //.then(data => {
            // Update state with the API response data
            //    setContactInfo({...contactInfo, pk: data.body});
            //    console.log(contactInfo.pk)
            //})
            .then(data => console.log(data))
            .catch(error => {
                // Handle errors
                console.error('There was a problem with the request:', error);
            });

        //navigate('/credit', { replace: true })
        navigate('/credit')
    }

    function formatName(value) {
        if (!value) return value;
        const newName = value.replace(/[^\w\s,.\'\-]/g, '');
        const newNameLength = newName.length;
        if (newNameLength <= 20) {
            return newName;
        }
        return `${newName.slice(0, 20)}`;
    }

    function formatBusinessName(value) {
        if (!value) return value;
        const newBusinessName = value.replace(/[^\w\s,.@()&\'\-]/g, '');
        const newBusinessNameLength = newBusinessName.length;
        if (newBusinessNameLength <= 40) {
            return newBusinessName;
        }
        return `${newBusinessName.slice(0, 40)}`;
    }

    function formatEmail(value) {
        if (!value) return value;
        const newEmail = value.replace(/[^\w\s,.@\'\-]/g, '').trim();
        const newEmailLength = newEmail.length;
        if (newEmailLength <= 35) {
            return newEmail;
        }
        return `${newEmail.slice(0, 35)}`;
    }

    const handleFirstName = e => {
        const formattedName = formatName(e.target.value)
        setContactInfo({
            ...contactInfo
            , firstname: formattedName
        })
        //setQStepData({
        //    ...qstepdata
        //    , owner1FirstName: formattedName
        //})
    }

    const handleLastName = e => {
        const formattedName = formatName(e.target.value)
        setContactInfo({
            ...contactInfo
            , lastname: formattedName
        })
        //setQStepData({
        //    ...qstepdata
        //    , owner1LastName: formattedName
        //})
    }

    const handleEmail = e => {
        const formattedEmail = formatEmail(e.target.value)
        setContactInfo({
            ...contactInfo
            , email: formattedEmail
        })
        //setQStepData({
        //    ...qstepdata
        //    , owner1Email: formattedEmail
        //})
    }

    const handleBusinessName = e => {
        const formattedName = formatBusinessName(e.target.value)
        setContactInfo({
            ...contactInfo
            , businessname: formattedName
        })
        //setQStepData({
        //    ...qstepdata
        //    , legalBusinessName: formattedName
        //})
    }




    function PrequalButton() {
        if (contactInfo.firstname != ""
            && contactInfo.lastname != ""
            && contactInfo.email.includes("@")
            && contactInfo.email.includes(".")
            && contactInfo.businessname != ""
            && contactInfo.phone.length == 14
            && contactInfo.contactconsent == true
            && contactInfo.privacyconsent == true
            && loanUse != 0
            && industry != 0) {
            return (
                <Stack spacing={2}
                    direction="row"
                    alignItems='center'
                    justifyContent='center'
                    mt={3}
                    mb={3}>
                    <Button variant="contained"
                        sx={{
                            fontWeight: 600, color: 'white', backgroundColor: theme.palette.primary.main,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                                opacity:0.70,
                              }
                        }}
                        onClick={() => handleButtonClick()}
                    >Continue to Prequalification
                    </Button>
                </Stack>
            )
        } else {
            return (
                <Stack spacing={2}
                    direction="row"
                    alignItems='center'
                    justifyContent='center'
                    mt={3}
                    mb={3}>
                    <Button variant="contained"
                        disabled
                        sx={{
                            fontWeight: 600, color: 'white', backgroundColor: theme.palette.primary.main,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                                opacity:0.70,
                              }
                        }}
                        onClick={() => handleButtonClick()}
                    >Continue to Prequalification
                    </Button>
                </Stack>
            )
        }
    }

    return (
        <div>
            <Stack mt={4}>
                <Typography variant="h6" sx={{ fontWeight: 700, color: theme.palette.primary.main }}>
                    Step 2:
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                    Provide contact information:
                </Typography>

                <Stack alignItems="center">
                    <br />
                    <InputText label="First Name"
                        type="text"
                        value={contactInfo.firstname}
                        defaultValue={contactInfo.firstname}
                        error={contactInfo.firstNameError}
                        autoComplete="given-name"
                        id="given-name"
                        onChange={e => handleFirstName(e)}
                        onBlur={() => {
                            contactInfo.firstname == '' ? setContactInfo({
                                ...contactInfo,
                                firstNameError: true
                            }) : setContactInfo({
                                ...contactInfo,
                                firstNameError: false
                            })

                        }}
                    />
                    <InputText label="Last Name"
                        type="text"
                        value={contactInfo.lastname}
                        defaultValue={contactInfo.lastname}
                        error={contactInfo.lastNameError}
                        autoComplete="family-name"
                        id="family-name"
                        onChange={e => handleLastName(e)}
                        onBlur={() => {
                            contactInfo.lastname == '' ? setContactInfo({
                                ...contactInfo,
                                lastNameError: true
                            }) : setContactInfo({
                                ...contactInfo,
                                lastNameError: false
                            })

                        }} />
                    <InputText label="Email"
                        type="text"
                        value={contactInfo.email}
                        defaultValue={contactInfo.email}
                        error={contactInfo.emailError}
                        autoComplete="email"
                        id="email"
                        onChange={e => handleEmail(e)}
                        onBlur={() => {
                            if (contactInfo.email.includes("@") == false || contactInfo.email.includes(".") == false) {
                                setContactInfo({
                                    ...contactInfo,
                                    emailError: true
                                })
                            }
                            else {
                                setContactInfo({
                                    ...contactInfo,
                                    emailError: false
                                });
                                fetch('https://ig6lz4fqv2.execute-api.us-east-1.amazonaws.com/v1', {
                                    method: 'POST',
                                    headers: { "Content-Type": "application/json" },
                                    body: JSON.stringify({
                                        apiinputpk: data.apiinputpk,
                                        email: contactInfo.email,
                                        firstName: contactInfo.firstname,
                                        lastName: contactInfo.lastname,
                                        datetime: data.datetimesession
                                    })
                                })
                            }
                        }} />
                    {/*<InputText label="Phone"
                    type="number"
                    value={contactInfo.phone}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setContactInfo({
                            ...contactInfo,
                            phone: event.target.value
                        })
                    }} />
                    
                    
                    
                    
                    
                    */}
                    <InputTextPhone contactInfo={contactInfo} setContactInfo={setContactInfo} 
                                    //qstepdata={qstepdata} setQStepData={setQStepData} 
                                    autoComplete="tel" id="tel" label="Phone"/>
                    <InputText label="Legal Business Name"
                        type="text"
                        value={contactInfo.businessname}
                        defaultValue={contactInfo.businessname}
                        error={contactInfo.businessError}
                        autoComplete="organization"
                        id="organization"
                        onChange={e => handleBusinessName(e)}
                        onBlur={() => {
                            contactInfo.businessname == '' ? setContactInfo({
                                ...contactInfo,
                                businessError: true
                            }) : setContactInfo({
                                ...contactInfo,
                                businessError: false
                            })
                        }} />
                </Stack>
                <br />
                <Stack direction="row" justifyContent={'center'} alignItems="center">
                    <Stack direction="row" justifyContent={'center'} alignItems="center" sx={{ width: 500 }}>
                        <FormGroup>
                            <FormControlLabel
                                value={contactInfo.contactconsent}
                                control={<Checkbox checked={contactInfo.contactconsent}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setContactInfo({
                                            ...contactInfo,
                                            contactconsent: event.target.checked
                                        })
                                    }}
                                    color="default" />}
                                label=""
                            />
                        </FormGroup>
                        <Typography>
                            I consent to future email, phone, and text communications to contact me at the phone number and email provided.
                        </Typography>
                    </Stack>
                </Stack>
                <br />
                <Stack direction="row" justifyContent={'center'} alignItems="center">
                    <Stack direction="row" justifyContent={'center'} alignItems="center" sx={{ width: 500 }}>
                        <FormGroup>
                            <FormControlLabel
                                value={contactInfo.privacyconsent}
                                control={<Checkbox checked={contactInfo.privacyconsent}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setContactInfo({
                                            ...contactInfo,
                                            privacyconsent: event.target.checked
                                        })
                                    }}
                                    color="default" />}
                            />
                        </FormGroup>
                        <Typography>
                            I have read and consent to abide by the <a href='https://captecusa.com/privacy-policy/' target="_blank">CapTec USA Privacy Policy</a>.
                        </Typography>
                    </Stack>
                </Stack>
                <PrequalButton />
                <Stack direction="row" justifyContent={'center'} alignItems="center">
                    <Stack direction="row" justifyContent={'center'} alignItems="center" sx={{ width: 350 }}>
                        <Typography display="block"
                            color="textSecondary"
                            variant="caption"
                            align="center">
                            Takes less than 5 minutes. A soft credit pull will be completed which does not impact your credit score.
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </div>
    )
}

export default ContactForm