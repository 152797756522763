import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography, Stack, Grid } from '@mui/material';
import PersonCard from './PersonCard'
import JerryCampbell from '../images/JerryCampbell2.jpg'
import DanaCluckey from '../images/DanaCluckey2.jpg'
import TomWelsh from '../images/TomWelsh2.jpg'
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async'
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function About() {
    const theme = useTheme();

    return (
        <>
            <Helmet>
                <title>About CapTec USA</title>
                <meta name="description" content="CapTec USA is a leader in delivering innovative banking solutions tailored for small businesses nationwide. Welcome to modern business lending." />
                <link rel="canonical" href="https://thenewsouthbank.com/about" />
            </Helmet>
            <div style={{ marginTop: '40px', marginLeft: '50px' }} className="Expect">
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                >

                    <Box sx={{
                        width: '70vw',
                        height: '5rem',
                        borderBottom: 1,
                        textAlign: 'left',
                        m: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        marginLeft: '-30rem',
                    }}>
                        <Typography fontSize={30} fontWeight={500} color={theme.palette.primary.main}    //blue
                        //style={{ paddingBottom: '1rem' }} {/* Adjust padding to control distance from bottom */}
                        >
                            About Us
                        </Typography>
                    </Box>
                    <br />
                    <Grid container spacing={0} sx={{ width: { xs: '80vw', md: '70vw' } }}>
                        <Grid item xs={12} sm={8} md={6} lg={5}
                            sx={{
                                margin: {
                                    xs: '24px 24px 24px -16px',   // (top right bottom left)
                                    sm: '24px 24px 24px 8px',
                                    md: '24px 24px 24px 24px',
                                },
                            }}>
                            <PersonCard image={JerryCampbell}
                                name='Jerry Campbell'
                                descr={["Chairman,", <br />, "President and CEO"]}
                                bio='Jerry was formerly the Chairman and Chief Executive Officer of HomeBancorp, Inc., a $1 billion in assets bank in Tampa, Florida. He previously served as Chairman of the Board of Republic Bancorp Inc. since the Company’s founding in 1986. Republic Bancorp Inc., with over $6 billion in assets, was the second largest bank holding company headquartered in Michigan. Jerry has a B.S. degree in liberal arts from Central Michigan University, a M.B.A. degree from Wayne State University, and a M.B.A. degree from the University of Michigan.'
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} lg={5}
                            sx={{
                                margin: {
                                    xs: '24px 24px 24px -16px',   // (top right bottom left)
                                    sm: '24px 24px 24px 8px',
                                    md: '24px 24px 24px 24px',
                                },
                            }}>
                            <PersonCard image={DanaCluckey}
                                name='Dana Cluckey'
                                descr={["Vice Chairman"]}
                                bio='Dana was formerly Vice Chairman of the Board, President and Chief Operating Officer of HomeBancorp, Inc., a $1 billion in assets bank in Tampa, Florida. He previously served as President and Chief Operating Officer and a member of the Board of Directors of Citizens Republic Bancorp Inc. Prior to that, he served in various roles, including Chief Executive Officer, and a director, with Republic Bancorp Inc. Dana is a Certified Public Accountant and has a B.B.A. degree from the University of Michigan.'
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} lg={5}
                            sx={{
                                margin: {
                                    xs: '24px 24px 24px -16px',   // (top right bottom left)
                                    sm: '24px 24px 24px 8px',
                                    md: '24px 24px 24px 24px',
                                },
                            }}>
                            <PersonCard image={TomWelsh}
                                name='Tom Welsh'
                                descr={["EVP, CFO"]}
                                icon={<LinkedInIcon />}
                                link='https://www.linkedin.com/in/tomwelsh1/'
                                bio='Tom was formerly Chief Financial Officer for TCM Bank in Tampa, Florida. Prior to that, he was Senior Vice President, Controller and Chief Accounting Officer for HomeBanc, the banking subsidiary of HomeBancorp, Inc. Tom is a Certified Public Accountant and CFA Charter Holder. He has a B.S. degree from Florida State University and a M.S. degree from the University of Notre Dame.'
                            />
                        </Grid>

                    </Grid>
                    <br />
                </Stack>
            </div>
        </>
    );
}

export default About;
