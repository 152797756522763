
import tombanner from '../images/tombanner3.png'
import { Card, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import Menu3 from './Menu3NoDropDown'

function ImgHomePage2(props) {
    const theme = useTheme();

    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 600);

    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth > 790);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div style={{ marginTop: {xs: '80px', sm: '60px'}, }}>
            <Card sx={{ marginTop: {xs: 9, sm: 10}, }}
                style={{
                color: 'white',
                backgroundColor: theme.palette.primary.dark,
                boxShadow: 'none',
                padding: 0,
                //marginBottom: '-25px',
                height: 'auto',
                borderRadius: 0,
                position: 'relative', // Ensure relative positioning for absolute children
                overflow: 'hidden', // Clip overflowing content (like the image)
                display: 'flex', // Use flex to ensure content fills the card
                flexDirection: 'column', // Stack children vertically
            }}>
                <img src={tombanner}
                    alt='Small Business Lending'
                    style={{
                        maxHeight: '380px',
                        minHeight: '250px',
                        objectFit: 'cover',
                        objectPosition: 'center 20%',  // Adjust the vertical position here
                        maskImage: 'linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 60%)', // Gradient mask from transparent on the right to opaque on the left
                        WebkitMaskImage: 'linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 60%)', // For Safari
                        display: 'block'
                    }}
                >
                </img>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '55%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        padding: {
                            xs: '0px 0px 0px 20px', // Extra small screens
                            sm: '0px 15px 0px 40px', // Small screens
                            md: '0px 0px 0px 60px', // Medium screens
                            lg: '0px 0px 0px 60px'  // Large screens
                        },
                        boxSizing: 'border-box',
                        color: 'white',
                        backgroundColor: 'transparent'
                    }}
                >
                    <Typography sx={{
                        fontSize: {
                            xs: '16px', // Small size for extra small screens
                            sm: '22px', // Medium size for small screens
                            md: '24px', // Larger size for medium screens
                            lg: '30px', // Even larger size for large screens
                        },
                        fontWeight: {
                            lg: 700, // Bold weight for large screens
                            md: 700, // Medium weight for medium screens
                            xs: 700, // Medium weight for medium screens
                        }
                    }}>Fast SBA 7(a) Loan{!isWideScreen && <br />} Prequalification<br/>Anytime, Anywhere</Typography>
                    <Typography sx={{
                        fontSize: {
                            xs: '12px', // Small size for extra small screens
                            sm: '16px', // Medium size for small screens
                            md: '18px', // Larger size for medium screens
                            lg: '18px', // Even larger size for large screens
                        },
                        fontWeight: {
                            lg: 500, // Bold weight for large screens
                            md: 400, // Medium weight for medium screens
                            sm: 300,
                            xs: 300
                        },
                        marginRight: { xs: 3, sm: 0}
                    }}>Receive a Personalized Estimate of the Interest Rate and Loan Terms in Minutes.</Typography>
                    <br/>
                    <Menu3 />
                </Box>
            </Card>
        </div>
    )
}

export default ImgHomePage2