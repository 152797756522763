
import { Button, Stack, Grid } from '@mui/material';

function NextButtonSaveExit({ handleClick, data }) {
    return (
        <Grid container
            direction="row"
            justifyContent="center"
        >
                <Stack mt={0} sx={{ backgroundColor: 'white' }} mr={0}>
                    <Stack spacing={2}
                        direction="row"
                        alignItems='center'
                        justifyContent='center'
                        mt={1}
                        mb={3}
                        //sx={{ width: { md: '100%', lg: 'calc(100% - 240px)'}  }}
                        >
                        <Button variant="contained"
                            sx={{
                                fontWeight: 600, color: 'white', backgroundColor: data.colorfont,
                                '&:hover': {
                                    backgroundColor: data.colorfont,
                                    opacity: 0.70,
                                }
                            }}
                            onClick={handleClick}
                        >
                            <span>Save & Exit</span>
                        </Button>
                    </Stack>
                </Stack>
            </Grid>
    )
}

export default NextButtonSaveExit