import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Container, Typography } from '@mui/material/';

export default function FormPropsTextFields(props) {
    return (
        <Container maxWidth={false}>

            <Box
                style={{ width: '100%' }}
                //component="form"
                sx={{
                    width: '100%',
                    '& .MuiTextField-root': { m: 2, width: '100%' },
                }}
                noValidate
                //autoComplete="off"
                alignItems="center"
                display="flex"
            >
                <div style={{ width: '100%' }}> {/* Set width to 100% for the nested div */}
                    <Typography fontSize={16} sx={{ fontWeight: 700, color: props.color, marginLeft: 2, marginTop: 1 }}>
                        {props.headingText}
                        <span style={props.headingStyle}>{props.headingSuffix}</span>
                    </Typography>
                    <Typography fontSize={12} color="gray" sx={{ fontWeight: 500, marginBottom: -2, marginLeft: 2 }}>
                        {props.subheading}
                    </Typography>
                    <TextField
                        variant="filled"
                        InputProps={{ disableUnderline: true, inputProps: {style: { textAlign: 'left', paddingTop: '10px', paddingBottom: '10px' }} }}
                        type={props.type}
                        required={props.required}
                        id={props.id}
                        autoComplete={props.autoComplete}
                        textContentType={props.textContentType}
                        label={props.label}
                        value={props.value}
                        defaultValue={props.defaultValue}
                        onChange={props.onChange}
                        //InputLabelProps={{ shrink: true, style: { color: 'black' } }}
                        sx={{
                            '& .MuiInputLabel-root': {
                                '&.Mui-focused': {
                                    color: props.color, // Change label color when focused
                                },
                            },
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    borderColor: props.color, // Change border color when focused
                                },
                            },
                        }}
                        onBlur={props.onBlur}
                        //error={props.error}
                        style={{ width: '100%' }} // Set width to 100% for the TextField
                    />
                </div>
            </Box>
        </Container>
    )
}