import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Container, Typography } from '@mui/material/';



export default function InputDropdown(props) {

    const dropdown = props.dropdown

    return (
        <Container maxWidth={false}>
            <Box
                style={{ width: '100%' }}
                sx={{
                    width: '100%',
                    '& .MuiTextField-root': { m: 2, width: '100%' },
                }}
                noValidate
                alignItems="center"
                display="flex"
            >
                <div style={{ width: '100%' }}>
                    <Typography fontSize={16} sx={{ fontWeight: 700, color: props.color, marginLeft: 2, marginTop: 1 }}>
                        {props.headingText}
                        <span style={props.headingStyle}>{props.headingSuffix}</span>
                    </Typography>
                    <Typography fontSize={12} color="gray" sx={{ fontWeight: 500, marginBottom: -2, marginLeft: 2 }}>
                        {props.subheading}
                    </Typography>
                    <TextField
                        select
                        variant="filled"
                        InputProps={{
                            disableUnderline: true //!props.error, // Disable underline when no error
                            , inputProps: {
                                style: {
                                    textAlign: 'left',
                                    paddingTop: '10px',
                                    paddingBottom: '10px',
                                    //borderBottom: props.error ? '2px solid red' : 'none' // Show red underline on error
                                }
                            }
                        }}
                        type={props.type}
                        required={props.required}
                        id={props.id}
                        autoComplete={props.autoComplete}
                        textContentType={props.textContentType}
                        label={props.label}
                        value={props.value}
                        defaultValue={props.defaultValue}
                        onChange={props.onChange}
                        //InputLabelProps={{ shrink: true, style: { color: 'black' } }}
                        onBlur={props.onBlur}
                        //error={props.error}
                        style={{ width: '100%', marginTop: props.marginTop }} // Set width to 100% for the TextField
                        SelectProps={{ native: true, }}
                        sx={{
                            '& .MuiInputLabel-root': {
                                '&.Mui-focused': {
                                    color: props.color, // Change label color when focused
                                },
                            },
                            '& .MuiFilledInput-root': {
                                backgroundColor: props.error ? '#F6DDDD' : '', // Change filled background color on error
                            },
                            '& .MuiFilledInput-underline:before': {
                                borderBottomColor: props.color, // Change underline color when focused
                            },
                            '& .MuiFilledInput-underline:after': {
                                borderBottomColor: props.error ? '#F6DDDD' : props.color, // Change underline color on error
                            },
                            '& .MuiTextField-root': { margin: 5, padding: 5 }
                            // MuiFormControl-root css-13y3ubt-MuiFormControl-root-MuiTextField-root" style="width: 100%;"><div class="MuiInputBase-root MuiFilledInput-root MuiInputBase-colorPrimary MuiInputBase-formControl css-1wmpcv1-MuiInputBase-root-MuiFilledInput-root"><input aria-invalid="" id=":r47:" required="" type="text" class="MuiInputBase-input MuiFilledInput-input css-10botns-MuiInputBase-input-MuiFilledInput-input" value="" style="text-align: left; padding-top: 10px; padding-bottom: 10px;"></div></div>
                        }}
                    >
                        {dropdown.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}</TextField>

                </div>
            </Box>
        </Container>
    )
}