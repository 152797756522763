import React, { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';


function DropDownLoanUse({ loanUse, setLoanUse, loanUseError, setLoanUseError, pricingTable
  , realEstate, setRealEstate, realEstateError, setRealEstateError, industry, data, setData, express }) {

  const theme = useTheme();

  console.log('express: ',express)

  function formatAmount(value) {
    if (!value) return value;
    //console.log(value)
    const amountValue = value.toString().replace(/[^\d]/g, '');
    let amountValueTrim = amountValue.length >= 8 ? amountValue.substring(0, 7) : amountValue;
    const amountValueTrimLength = amountValueTrim.length;
    if (amountValueTrimLength < 4) return `$${amountValueTrim}`;
    if (amountValueTrimLength < 7) return `$${amountValueTrim.substring(0, amountValueTrim.length - 3)},${amountValueTrim.substring(amountValueTrim.length - 3, amountValueTrim.length)}`;
    if (amountValueTrimLength < 8) return `$${amountValueTrim.substring(0, amountValueTrim.length - 6)},${amountValueTrim.substring(amountValueTrim.length - 6, amountValueTrim.length - 3)},${amountValueTrim.substring(amountValueTrim.length - 3, amountValueTrim.length)}`;
  }

  const roundToNearest = (number, roundingBase) => {
    return Math.round(number / roundingBase) * roundingBase;
  };

  const findItemByKey = (key) => {
    return pricingTable.find(item => item.ReactKey === key);
  };

  useEffect(() => {
    if (pricingTable.length === 0) {
      return; // Exit early if pricingTable is empty
    }

    const combinedKey = industry + '_' + loanUse + '_' + realEstate;
    console.log('combinedKey : ', combinedKey)
    setData((s) => ({
      ...s,
      reactKey: combinedKey,
      real_estate: combinedKey.endsWith('2') ? true : false
    }))

    //console.log('real_estate : ', data.real_estate)
    //console.log('reactKey : ', data.reactKey)
    //console.log('loanUse : ', loanUse)
    const foundItem = findItemByKey(combinedKey);
    if (!foundItem) {
      console.warn('Item not found');
      return; // Exit early if no item is found
    }


    //console.log('Found Item:', foundItem); // Debug output

    // Calculate values
    const intcalc = (foundItem.Prime + foundItem.Margin) / 12;
    const termcalc = foundItem.Term * 12;
    const calc1 = (1 + intcalc) ** termcalc;
    const amt = data.amtInteract ?
      data.amount < foundItem.MinVal ?
        foundItem.MinVal :
        data.amount > foundItem.MaxVal ?
          foundItem.MaxVal :
          data.amount
      : roundToNearest((foundItem.MinVal + foundItem.MaxVal) / 2, 100000);
    const pmtcalc = amt * (intcalc * calc1) / (calc1 - 1);

    setData((s) => ({
      ...s,
      amount: amt,
      amountformatted: formatAmount(amt),
      interest: foundItem.Prime + foundItem.Margin,
      prime: foundItem.Prime,
      margin: foundItem.Margin,
      term: foundItem.Term,
      payment: pmtcalc,
      minamount: foundItem.MinVal,
      maxamount: foundItem.MaxVal,
      minCredit: foundItem.MinCredit,
    }))
  }, [industry, loanUse, realEstate, pricingTable]);

  const handleLoanUse = (event) => {
    const priorLoanUse = loanUse
    setLoanUse(event.target.value);
    event.target.value == 0 ? setLoanUseError(true) : setLoanUseError(false)
    setRealEstate(event.target.value == 2 
                    ? 2 
                    : priorLoanUse == 2 & event.target.value != 2
                        ? 0
                        : realEstate)
    setRealEstateError(event.target.value == 2 ? false : realEstateError);
  };

  const menuItems = express ? [
    <MenuItem key="0" value={'0'}>&nbsp;</MenuItem>,
    <MenuItem key="4" value={'4'}>Equipment</MenuItem>,
    <MenuItem key="5" value={'5'}>Refinance Business Debt</MenuItem>,
    <MenuItem key="7" value={'7'}>Working Capital</MenuItem>
  ] : [
    <MenuItem key="0" value={'0'}>&nbsp;</MenuItem>,
    <MenuItem key="1" value={'1'}>Business Acquisition</MenuItem>,
    <MenuItem key="2" value={'2'}>Commercial Real Estate</MenuItem>,
    <MenuItem key="3" value={'3'}>Construction</MenuItem>,
    <MenuItem key="4" value={'4'}>Equipment</MenuItem>,
    <MenuItem key="5" value={'5'}>Refinance Business Debt</MenuItem>,
    <MenuItem key="6" value={'6'}>Start a New Business</MenuItem>,
    <MenuItem key="7" value={'7'}>Working Capital</MenuItem>,
    <MenuItem key="8" value={'8'}>Other</MenuItem>
  ];

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }} >
      <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
        <Typography style={{ color: loanUseError ? theme.palette.secondary.main : theme.palette.primary.dark, fontWeight: 600, marginLeft: 5 }} >Primary Loan Use *</Typography>
        <Select
          value={loanUse}
          onChange={handleLoanUse}
          onBlur={() => {
            loanUse == 0 ? setLoanUseError(true) : setLoanUseError(false)
          }}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          error={loanUseError}
          sx={{
            backgroundColor: 'white', // Change this to your desired background color
            width: 'auto', // Allow the dropdown to size based on content
            minWidth: 200,
            maxWidth: '100%',
            margin: '0 auto', // Center the dropdown
            '& .MuiSelect-select': {
              backgroundColor: 'white', // Ensure the selected item background color matches
              color: 'black', // Optional: Set text color
              boxShadow: 1,
              border: 'none'
            },
            '& .MuiMenuItem-root': {
              backgroundColor: 'white', // Optional: Set background color for menu items
              '&:hover': {
                backgroundColor: 'darkgray', // Optional: Set hover color for menu items
              },
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 340, // Adjust this value as needed
                overflow: 'auto',
                backgroundColor: 'white', // Optional: Ensure background color matches
                '& .MuiMenuItem-root': {
                  backgroundColor: 'white', // Optional: Set background color for menu items
                  '&.Mui-selected': {
                    backgroundColor: theme.palette.action.hover, // Highlight color for selected item
                  },
                  '&:hover': {
                    backgroundColor: 'darkgray', // Optional: Set hover color for menu items
                  },
                },
              },
            },
          }}
        >
          {menuItems}
        </Select>
      </FormControl>
    </div>
  );
}

export default DropDownLoanUse;
