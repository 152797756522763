import * as React from 'react';
import { Box, Stack, Grid, Card, CardMedia, CardContent, useMediaQuery, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ByTheNumbersDetail from './ByTheNumbersDetail';
import bridge from '../images/texture1.png'


export default function ByTheNumbers() {
    const theme = useTheme();


    return (
        <Box sx={{ 
            position: 'relative', // Position relative for absolute positioning of child elements
            width: '100%', // Full width of the parent container
        }}>
            {/* Background Image Box */}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%', // Full width
                    height: '100%', // Full height to cover the parent Box
                    backgroundColor: theme.palette.primary.dark, // Dark blue background color
                    zIndex: 0, // Ensure it is behind other content
                    display: 'flex',
                }}
            >
                <img
                    src={bridge}
                    alt='bridge'
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        opacity: 0.05, // Set image transparency to 20%
                    }}
                />
            </Box>

            {/* Content Box */}
            <Box sx={{
                display: 'flex',
                position: 'relative', // Ensure this Box is relative to stack content on top of image
                zIndex: 10, // Ensure content is above the image
                padding: 3, // Add padding if necessary
            }}>
                <Grid container spacing={3} sx={{ ml: { xs: 0, sm: 2, md: 4, lg: 5, xl: 6 } }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <br />
                        <Typography
                            sx={{
                                fontSize: { xs: 50, sm: 54, md: 54, lg: 64 },
                                textAlign: 'left',
                                position: 'relative',
                                zIndex: 10,
                                lineHeight: 1.1
                            }}
                            fontWeight={500}
                            color='white'
                        >
                            <span><b>SBA 7(a)</b><br/>by the numbers</span>
                        </Typography>
                        <br/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ marginTop: { xs: '-30px', sm: 0, md: 0, lg: 0 } }}>
                        <br />
                        <ByTheNumbersDetail
                            title='$25B+'
                            descr='The SBA 7(a) program backed over $25 billion to small businesses last year'
                        />
                        <ByTheNumbersDetail
                            title='50%'
                            descr={<span>Percentage of SBA 7(a) loans that go to <u>established businesses</u></span>}
                        />
                        <ByTheNumbersDetail
                            title='1,000+'
                            descr={<span>Number of <u>eligible industries</u> for the SBA 7(a) program</span>}
                        />
                        <br/>
                    </Grid>
                </Grid>
            </Box>
        </Box>


    );
}
