import React, { useState, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, Box } from '@mui/material';

const TestComponent = () => {
    const [showNavbar, setShowNavbar] = useState(true);
    const navBarRef2 = useRef(null)
    const theme = useTheme()

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    const navbarStyle = {
        position: 'fixed',
        top: 0,
        width: '100%',
        backgroundColor: '#333',
        color: '#fff',
        zIndex: 1000,
        transition: 'transform 0.3s ease',
        transform: showNavbar ? 'translateY(0)' : 'translateY(-100px)',
        WebkitTransform: isSafari ? (showNavbar ? 'translateY(0)' : 'translateY(-100px)') : undefined,
    };

    return (
        <div>
            <div style={navbarStyle}>
                Test Navbar
                <Box className="NavBar" sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Box
                    sx={{
                        //position: 'fixed',
                        top: { xs: '44px', sm: '64px' },
                        width: '100vw',
                        //display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        //height: 40,
                        backgroundColor: theme.palette.primary.main,   //blue
                        zIndex: 1000, // Ensure the menu bar is above other content
                        //overflow: 'hidden',
                    }}
                >
                    <Grid ref={navBarRef2}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            position: 'fixed',
                            top: { xs: '54px', sm: '64px' },
                            left: 0,
                            width: '100vw',
                            height: { xs: 41, md: 40 },
                            backgroundColor: theme.palette.primary.dark,
                            zIndex: 1000,
                            paddingLeft: '0',
                            paddingRight: '10px',
                        }}
                    >
                            Grid Part
                        </Grid>
                    </Box>
                </Box>

            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <button onClick={() => setShowNavbar(!showNavbar)}>Toggle Navbar</button>
        </div>
    );
};

export default TestComponent;