import React, { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import { MenuItem, FormControl, Select, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';


function DropDownRealEstate({ industry, pricingTable, loanUse, data, setData,
                realEstate, setRealEstate, realEstateError, setRealEstateError
 }) {

  const theme = useTheme();

  function formatAmount(value) {
    if (!value) return value;
    //console.log(value)
    const amountValue = value.toString().replace(/[^\d]/g, '');
    let amountValueTrim = amountValue.length >= 8 ? amountValue.substring(0, 7) : amountValue;
    const amountValueTrimLength = amountValueTrim.length;
    if (amountValueTrimLength < 4) return `$${amountValueTrim}`;
    if (amountValueTrimLength < 7) return `$${amountValueTrim.substring(0, amountValueTrim.length - 3)},${amountValueTrim.substring(amountValueTrim.length - 3, amountValueTrim.length)}`;
    if (amountValueTrimLength < 8) return `$${amountValueTrim.substring(0, amountValueTrim.length - 6)},${amountValueTrim.substring(amountValueTrim.length - 6, amountValueTrim.length - 3)},${amountValueTrim.substring(amountValueTrim.length - 3, amountValueTrim.length)}`;
  }

  const roundToNearest = (number, roundingBase) => {
    return Math.round(number / roundingBase) * roundingBase;
  };

  const findItemByKey = (key) => {
    return pricingTable.find(item => item.ReactKey === key);
  };

  useEffect(() => {
    if (pricingTable.length === 0) {
      return; // Exit early if pricingTable is empty
    }

    const combinedKey = industry + '_' + loanUse + '_' + realEstate;
    //console.log('combinedKey : ', combinedKey)
    setData((s) => ({
      ...s,
      reactKey: combinedKey
    }))
    const foundItem = findItemByKey(combinedKey);
    if (!foundItem) {
      console.warn('Item not found');
      return; // Exit early if no item is found
    }


    //console.log('Found Item:', foundItem); // Debug output

    // Calculate values
    const intcalc = (foundItem.Prime + foundItem.Margin) / 12;
    const termcalc = foundItem.Term * 12;
    const calc1 = (1 + intcalc) ** termcalc;
    const amt = data.amtInteract ? 
                  data.amount < foundItem.MinVal ? 
                    foundItem.MinVal :
                    data.amount > foundItem.MaxVal ?
                      foundItem.MaxVal :
                      data.amount
                  : roundToNearest((foundItem.MinVal + foundItem.MaxVal) / 2, 100000);
    const pmtcalc = amt * (intcalc * calc1) / (calc1 - 1);

    setData((s) => ({
      ...s,
      amount: amt,
      amountformatted: formatAmount(amt),
      interest: foundItem.Prime + foundItem.Margin,
      prime: foundItem.Prime,
      margin: foundItem.Margin,
      term: foundItem.Term,
      payment: pmtcalc,
      minamount: foundItem.MinVal,
      maxamount: foundItem.MaxVal,
      minCredit: foundItem.MinCredit,
    }))
  }, [realEstate, industry, loanUse, pricingTable]);

  const handleRealEstate = (event) => {
    setRealEstate(event.target.value);
    event.target.value == 0 ? setRealEstateError(true) : setRealEstateError(false)
    console.log('event.target.value: ', event.target.value)
    console.log('realEstate: ', realEstate)
  };

  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }} >
      <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
        <Typography style={{ color: realEstateError? theme.palette.secondary.main : theme.palette.primary.dark
                        , fontWeight: 600, marginLeft: 5 }} 
        >
            {isMediumScreen ? (
                <>
                    Financing Commercial<br />Real Estate? *
                </>
            ) : (
                "Financing Commercial Real Estate? *"
            )}
            </Typography>
        <Select
          value={realEstate}
          onChange={handleRealEstate}
          onBlur={() => {
            realEstate == 0 ? setRealEstateError(true) : setRealEstateError(false)
            }}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          error={realEstateError}
          disabled={loanUse == 2}
          sx={{
            backgroundColor: 'white', // Change this to your desired background color
            width: 'auto', // Allow the dropdown to size based on content
            minWidth: 200,
            maxWidth: '100%',
            margin: '0 auto', // Center the dropdown
            '& .MuiSelect-select': {
              backgroundColor: 'white', // Ensure the selected item background color matches
              color: 'black', // Optional: Set text color
              boxShadow: 1,
              border: 'none'
            },
            '& .MuiMenuItem-root': {
              backgroundColor: 'white', // Optional: Set background color for menu items
              '&:hover': {
                backgroundColor: 'darkgray', // Optional: Set hover color for menu items
              },
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 340, // Adjust this value as needed
                overflow: 'auto',
                backgroundColor: 'white', // Optional: Ensure background color matches
                '& .MuiMenuItem-root': {
                  backgroundColor: 'white', // Optional: Set background color for menu items
                  '&.Mui-selected': {
                    backgroundColor: theme.palette.action.hover, // Highlight color for selected item
                  },
                  '&:hover': {
                    backgroundColor: 'darkgray', // Optional: Set hover color for menu items
                  },
                },
              },
            },
          }}
        >
          <MenuItem value={'0'}>&nbsp;</MenuItem>
          <MenuItem value={'2'}>Yes</MenuItem>
          <MenuItem value={'1'}>No</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

export default DropDownRealEstate;
