import * as React from 'react';
import { Box, Card, CardContent, CardMedia, CardActions, Typography, Button, Dialog, DialogTitle, Stack } from '@mui/material';
import PropTypes from 'prop-types';



export default function MediaControlCard({ image, name, descr, bio, icon, link }) {


    function SimpleDialog(props) {
        const { onClose, selectedValue, open } = props;

        const handleClose = () => {
            onClose(selectedValue);
        };

        const handleListItemClick = (value) => {
            onClose(value);
        };

        return (
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>
                    <Typography variant="h6" fontWeight={600} color='primary.main' gutterBottom>
                        {name}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" paragraph sx={{ lineHeight: '1.3' }}   >
                        {bio}
                    </Typography>
                </DialogTitle>
            </Dialog>
        );
    }

    SimpleDialog.propTypes = {
        onClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
        selectedValue: PropTypes.string.isRequired,
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };


    return (
        <Card sx={{ display: 'flex', width: '100%', height: '100%' }}>
            <CardMedia
                component="img"
                sx={{ width: '40%' }}
                image={image}
                alt={name}
                title={name}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '60%' }} >
                <CardContent sx={{ flex: '1 0 auto', paddingBottom: 0 }}>
                    <Typography sx={{ fontSize: { xs: 18, sm: 20, md: 20, lg: 20 } }} fontWeight={600} color='primary.main' gutterBottom>
                        {name}
                    </Typography>
                    <Typography sx={{ fontSize: { xs: 15, sm: 16, lg: 18 }, lineHeight: '1.3', marginBottom: 0 }} color="text.secondary" paragraph   >
                        {descr}
                    </Typography>
                </CardContent>
                <CardActions sx={{ paddingLeft: 2, paddingTop: 0, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Button size="small" color="primary" onClick={handleClickOpen} sx={{paddingLeft: 0}}>
                        View Bio
                    </Button>
                    {icon ?
                        <a href={link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', margin: 0 }}>
                            {React.cloneElement(icon, {
                                sx: { color: theme => theme.palette.primary.dark, marginTop: 1 }
                            })}
                        </a>
                        : <></>
                    }
                    <SimpleDialog
                        open={open}
                        onClose={handleClose}
                    />
                </CardActions>
            </Box>

        </Card>
    );
}